import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Truncate from 'react-truncate';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Skeleton from '@material-ui/lab/Skeleton';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';

import Log from 'utils/Log';
import apiService from 'utils/apiService';
import NotificationBar from './NotificationBar';

import { getImageUrl } from '../images/background';
import { getBoardRemainingDays } from 'utils/misc';

export default function BoardCard({ teamId, boardData, onDelete, canDeleteBoard }) {
  const { id, name, theme, backgroundUrl, timestamp, planId } = boardData;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const remainingDays = getBoardRemainingDays(timestamp);

  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(id);

    Log('Board', 'delete');
  };

  const onEmail = () => {
    error && setError();
    success && setSuccess();

    apiService
      .post(`/api/team/${teamId}/board/${id}/send-email`)
      .then(() => {
        handleClose();
        setSuccess(true);
      })
      .catch((e) => {
        if (e) setError(true);
      });

    Log('Click', `free email summary`);
  };

  const getOptimisedUnsplashUrl = (url) => {
    if (url && url.includes('https://images.unsplash.com/') && url.includes('w=1080')) {
      return url.replace('w=1080', 'w=400');
    }
    return url;
  };

  const renderRemainingDate = () => {
    if (planId) return null;

    return (
      <span
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          background: 'rgba(0, 0, 0 , 0.5)',
          color: '#fff',
          padding: '4px 10px',
          borderRadius: '8px'
        }}
      >
        {remainingDays > 0 ? `${remainingDays} ${remainingDays === 1 ? 'day' : 'days'} remaining` : 'Archived'}
      </span>
    );
  };

  return (
    <>
      <div className="boardCard">
        <Card variant="outlined">
          {theme || backgroundUrl ? (
            <Link to={`/board/${id}`}>
              <CardMedia
                className="boardCard-media"
                image={getOptimisedUnsplashUrl(backgroundUrl) || getImageUrl(theme, 's')}
              />
            </Link>
          ) : (
            <Skeleton variant="rect" height={80} />
          )}

          {renderRemainingDate()}

          <div className="boardCard-content">
            {name !== undefined ? (
              <a href={`/board/${id}`} title={name}>
                <Truncate width={230}>{name}</Truncate>
              </a>
            ) : (
              <Skeleton height={52} />
            )}
          </div>

          <CardActions className="boardCard-actions">
            {timestamp ? <p>{format(timestamp, 'PPP')}</p> : <Skeleton width={80} />}
            {canDeleteBoard && (
              <IconButton className="boardCard-actions-btn" aria-label="settings" onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>
            )}
            <Popover
              anchorEl={anchorEl}
              open={open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                {id && (
                  <MenuList id="menu-list-grow">
                    <MenuItem className="action-delete" onClick={onRemoveModal}>
                      Delete
                    </MenuItem>
                    <MenuItem>
                      <Link className="action-link" to={`/team/${teamId}/board/create?fromBoard=${id}`}>
                        Create retro like this
                      </Link>
                    </MenuItem>
                    {!planId && remainingDays <= 0 && (
                      <MenuItem className="action-email" onClick={onEmail}>
                        Export data via email
                      </MenuItem>
                    )}
                  </MenuList>
                )}
              </ClickAwayListener>
            </Popover>
          </CardActions>
        </Card>
      </div>
      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive>
        <div className="modalContainer">
          <h2>
            Delete Retro{' '}
            <span role="img" aria-label="" style={{ fontSize: '26px' }}>
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{name}</p>
          <p>Are you sure you want to delete this retro?</p>
          <p>
            All data associated with this retro will be deleted and <br /> cannot be undone.
          </p>

          {!planId && (
            <p style={{ color: '#f26c7c' }}>
              Please note:&nbsp;&nbsp;Deleted retros still count towards your free retros
            </p>
          )}

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button color="danger" onClick={handleDelete}>
              Delete Retro
            </Button>
          </div>
        </div>
      </Modal>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
      {success && (
        <NotificationBar
          message="💌 This retro has been sent to you"
          autoHideDuration={12000}
          closeCallback={() => setSuccess()}
        />
      )}
    </>
  );
}
