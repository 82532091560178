import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import AppContext from 'AppContext';
import { validateEmails } from 'utils/misc';
import { APP_ENDPOINT } from 'config';

import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import Log from 'utils/Log';

export default function SingleSignOn() {
  const history = useHistory();
  const { isAuthenticated } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  const onSignIn = () => {
    const isValidEmail = validateEmails([email]);

    if (!isValidEmail) {
      setError('Please enter a valid email address');
      return;
    }

    const domain = email.substring(email.indexOf('@') + 1);

    axios
      .get(`/api/sso-connection`, {
        params: {
          domain
        }
      })
      .then(({ data }) => {
        if (data) {
          window.location.assign(`${APP_ENDPOINT}/sso-login?&connection=${data}`);
        }
      })
      .catch((e) => {
        setError("Please contact us to configure your organization's SSO");
        Log('SSO', `Domain not configured - ${domain}`);
      });
  };

  const onChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    error && setError();
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSignIn();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  return (
    <div className="staticPage-container">
      <div className="staticPage-content">
        <div
          className="welcomeModal"
          style={{
            minWidth: 'min(450px, calc(100vw - 152px))'
          }}
        >
          <h3>
            <span role="img" aria-label="">
              🔐
            </span>{' '}
            Sign in with SSO
          </h3>
          <p>Enter your work email address</p>
          <TextField
            style={{ display: 'flex', height: '90px' }}
            label="Email address"
            value={email}
            inputProps={{ maxLength: 160, autoFocus: true }}
            onChange={onChange}
            onKeyUp={handleKeyUp}
            error={Boolean(error)}
            helperText={error}
          />
          <Button onClick={onSignIn}>Continue</Button>
        </div>
      </div>
    </div>
  );
}
