import React from 'react';

import { Reorder, useMotionValue, useDragControls } from 'framer-motion/dist/framer-motion';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

function ReorderIcon({ dragControls }) {
  return <DragIndicatorIcon onPointerDown={(event) => dragControls.start(event)} style={{ cursor: 'grab' }} />;
}
const DragOrder = ({ value, content }) => {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  return (
    <Reorder.Item value={value} id={value} style={{ y }} dragListener={false} dragControls={dragControls}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-24px' }}>
        <div className="reorderIcon">
          <ReorderIcon dragControls={dragControls} />
        </div>
        {content}
      </div>
    </Reorder.Item>
  );
};

export default DragOrder;
