import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/Modal/Modal';

const useStyles = makeStyles((theme) => ({
  intro: {
    margin: '0 0 20px 0'
  },
  paper: {
    height: 200,
    width: 200
  },
  size: {
    width: 60,
    margin: '-3px 20px 0 20px'
  },
  redirectLoading: {
    width: '80%',
    margin: '24px auto'
  }
}));

export default function ManageBilling({ email, customerId, orgId }) {
  const classes = useStyles();

  useEffect(() => {
    const handleBilling = async (email) => {
      const { url } = await axios
        .post(`/payment/org/${orgId}/create_billing_portal`, {
          customerId
        })
        .then(({ data }) => {
          return data;
        });

      window.location.assign(url);
    };

    if (email && customerId) {
      handleBilling(email);
    }
  }, [email, customerId, orgId]);

  return (
    <Modal status={true} maxWidth="sm">
      <div className={classes.redirectLoading}>
        <p>Please wait for a moment, we are redirecting you to Stripe Billing Portal...</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </div>
      </div>
    </Modal>
  );
}
