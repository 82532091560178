import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Reward from 'react-rewards';
import throttle from 'lodash/throttle';
import { toPng } from 'html-to-image';

import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import apiService from 'utils/apiService';
import Modal from 'components/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

import { GithubCounter } from 'react-reactions/src';
import { ReactComponent as DownloadImageIcon } from 'images/icons/downloadImage.svg';
import { ReactComponent as SlackIcon } from 'images/icons/slack.svg';

import RetroStats from 'modules/RetroStats';
import NotificationBar from './NotificationBar';

import Log from 'utils/Log';
import { logoImageUrl } from 'utils/base64ImageUrls';
import { getMoodEmoji } from 'utils/misc';
import useSlack from 'hooks/useSlack';

import { APP_ENDPOINT } from 'config';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: '#fff',
    color: '#000',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    borderRadius: '10px',
    padding: 0
  }
}));

const iconStyle = { width: '22px', paddingRight: '8px', color: '#209eff', textAlign: 'center' };

export default function RetroSummary({ retroStates, id, teamId, actions, isFacilitator, membersCount, planId }) {
  const { postToSlack } = useSlack({ teamId, isFacilitator });
  const [show, setShow] = useState(false);
  const [boardsInsights, setBoardsInsights] = useState();
  const [notification, setNotification] = useState();
  const noPlan = Boolean(!planId);
  const rewardEl = useRef(null);
  const summaryEl = useRef(null);
  const classes = useStyles();

  const downloadImage = async (e) => {
    const { name } = boardsInsights;
    const filter = (node) => {
      const exclusionClasses = ['exportRetroBtn'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };

    const dataUrl = await toPng(summaryEl.current, {
      filter,
      style: {
        margin: '0',
        background: `no-repeat top left/170px url(${logoImageUrl}), no-repeat 100% 100% linear-gradient( 283.22deg, #fcf1f3 8.49%, #f0faf8 26.95%, #eaf7fc 46.69%, #eef8fd 68.57%, #e3f3fa 79.7% )`
      }
    });

    // download image
    const link = document.createElement('a');
    link.download = name ? `Retro Summary - ${name}` : 'Retro Summary';
    link.href = dataUrl;
    link.click();
    Log('Click', 'download summary as image');
  };

  const config = {
    elementCount: 200,
    spread: 180,
    startVelocity: 40,
    colors: ['#97A3FF', '#209EFF', '#F26C7C']
  };

  const rewardMe = useMemo(
    () => throttle(() => rewardEl.current?.rewardMe(), 1000, { leading: true, trailing: false }),
    []
  );

  const onClose = () => {
    setShow(false);
  };

  const renderInsights = () => {
    const { cards, id, name, cardsCount, emojiCount, peopleCount, votesCount, moodData } = boardsInsights;
    const { moodScore } = moodData || {};

    const openActions = [...actions].filter((item) => !item.status).reverse();

    const renderCard = (card) => {
      const { id, text, emojis, votes } = card;

      if (!text) return null;

      return (
        <Paper key={`card-${id}`}>
          <div className="cardOverview">
            <p className="cardContent">{text}</p>

            <div>
              <GithubCounter
                counters={emojis}
                votesCount={votes ? <span className="cardVotesCount">{votes}</span> : null}
              />
            </div>
          </div>
        </Paper>
      );
    };

    const renderAction = (action) => {
      return (
        <p className="retro-summary-actions-card" key={`action-${action.id}`}>
          <span className="column-list-action"></span>
          <span>{action.text}</span>
        </p>
      );
    };

    const renderEmptyActionStates = () => {
      return (
        <p>
          Create actions during your retro and they will be shown here along with any other open actions. Actions from
          previous retros will be visible until you mark them as closed.
        </p>
      );
    };

    const onSlack = async (e) => {
      const feedbackElements = cards
        .filter(({ text }) => text)
        .map(({ text }) => {
          return {
            type: 'rich_text_section',
            elements: [
              {
                type: 'text',
                text
              }
            ]
          };
        });

      const actionsElements = openActions.map(({ text }) => {
        return {
          type: 'rich_text_section',
          elements: [
            {
              type: 'text',
              text
            }
          ]
        };
      });

      const data = {
        blocks: [
          {
            type: 'rich_text',
            elements: [
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: `${name}\n\n`,
                    style: {
                      bold: true
                    }
                  },
                  {
                    type: 'text',
                    text: `Summary\n\n`
                  }
                ]
              }
            ]
          },
          {
            type: 'rich_text',
            elements: [
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: `${getMoodEmoji(moodScore) || '🤔'} Mood: ${moodScore || ' -'}      `
                  },
                  {
                    type: 'text',
                    text: `👤 People: ${peopleCount}      `
                  },
                  {
                    type: 'text',
                    text: `📝 Notes: ${cardsCount}       `
                  },
                  {
                    type: 'text',
                    text: `🗳 Votes: ${votesCount}       `
                  },
                  {
                    type: 'text',
                    text: `👍 Emoji: ${emojiCount}\n\n`
                  }
                ]
              }
            ]
          },
          {
            type: 'divider'
          },
          {
            type: 'rich_text',
            elements: [
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: 'Top feedback\n\n'
                  }
                ]
              },
              {
                type: 'rich_text_list',
                style: 'ordered',
                indent: 0,
                border: 0,
                elements: feedbackElements
              },
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: '\n'
                  }
                ]
              }
            ]
          },
          {
            type: 'divider'
          },
          {
            type: 'rich_text',
            elements: [
              {
                type: 'rich_text_section',
                elements: [
                  {
                    type: 'text',
                    text: 'Open actions\n\n'
                  }
                ]
              },
              {
                type: 'rich_text_list',
                style: 'ordered',
                indent: 0,
                border: 0,
                elements: actionsElements
              }
            ]
          },
          {
            type: 'actions',
            elements: [
              {
                type: 'button',
                text: {
                  type: 'plain_text',
                  text: 'View Board'
                },
                url: `${APP_ENDPOINT}/board/${id}`,
                action_id: 'view_board',
                value: 'View Board'
              }
            ]
          }
        ]
      };

      await postToSlack({ data });
    };

    const onEmail = (e, sendToTeam) => {
      if (noPlan) return;

      apiService
        .post(`/api/team/${teamId}/board/${id}/send-email`, {
          sendToTeam
        })
        .then(() => {
          setNotification(`This retro has been sent to ${sendToTeam ? 'your team' : 'you'}`);
        })
        .catch((e) => {
          if (e) setNotification('Sorry something went wrong');
        });

      Log('Click', `email summary${sendToTeam ? ' team' : ''}`);
    };

    const renderShareMenu = () => {
      return (
        <MenuList onClick={(e) => e.stopPropagation(e)}>
          <MenuItem onClick={(e) => downloadImage(e)}>
            <DownloadImageIcon style={iconStyle} /> Download as Image
          </MenuItem>
          <MenuItem onClick={(e) => onSlack(e)}>
            <SlackIcon fontSize="small" style={iconStyle} /> Send to Slack
          </MenuItem>
          <Tooltip placement="top" title={noPlan ? 'Upgrade your plan to unlock this feature' : ''}>
            <MenuItem onClick={(e) => onEmail(e, true)} disabled={noPlan} style={{ pointerEvents: 'auto' }}>
              <AllInboxIcon style={iconStyle} /> Email to Team
            </MenuItem>
          </Tooltip>
          <Tooltip placement="top" title={noPlan ? 'Upgrade your plan to unlock this feature' : ''}>
            <MenuItem onClick={(e) => onEmail(e)} disabled={noPlan} style={{ pointerEvents: 'auto' }}>
              <MailOutlineIcon style={iconStyle} /> Email to me
            </MenuItem>
          </Tooltip>
        </MenuList>
      );
    };

    return (
      <div id="retro-summary-modal" className="retro-summary">
        <div>
          <div className="clouds" style={{ opacity: 0.5 }}>
            <div
              className="cloud cloud-2 cloud-purple"
              style={{
                left: '-90px',
                top: '5%'
              }}
            ></div>
          </div>

          <div className="welcomeModal retro-summary-header">
            <h2>Summary</h2>
            <hr />
            <p>{name}</p>
            <RetroStats id={id} teamId={teamId} actions={actions} />
            {isFacilitator && (
              <Tooltip
                title={renderShareMenu()}
                placement="top"
                enterTouchDelay={20}
                leaveTouchDelay={5000}
                disableFocusListener
                interactive
                classes={{ tooltip: classes.tooltip }}
              >
                <span
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#209eff',
                    fontWeight: 600,
                    cursor: 'default'
                  }}
                  className="exportRetroBtn"
                >
                  Export this retro <ShareOutlinedIcon style={{ paddingLeft: '8px', color: '#209eff' }} />
                </span>
              </Tooltip>
            )}
          </div>

          {notification && (
            <NotificationBar
              type={notification === 'Sorry something went wrong' ? 'error' : 'info'}
              message={notification}
              closeCallback={() => setNotification()}
              rootId={'retro-summary-modal'}
            />
          )}
        </div>
        <div className="retro-summary-cards">
          <div>
            <h3>Top feedback</h3>
            {!cards || cards.length === 0 ? (
              <p>
                Leave feedback during your retro, and cards with the most votes or emoji will be shown here for review
                with your team.
              </p>
            ) : (
              cards.map(renderCard)
            )}
          </div>
        </div>
        <div className="retro-summary-actions">
          <h3>Open actions</h3>
          {openActions.length === 0 ? renderEmptyActionStates() : openActions.map(renderAction)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (show) {
      apiService
        .get(`/api/team/${teamId}/board-insights/${id}`)
        .then(({ data }) => {
          setBoardsInsights(data);
          rewardMe();
        })
        .catch((e) => e);
    }
  }, [show, id, teamId, rewardMe]);

  useEffect(() => {
    if (retroStates && retroStates.showSummary) {
      setShow(true);
    }
  }, [retroStates]);

  return (
    <Modal status={show} onClose={onClose} maxWidth="lg" nonResponsive keepMounted={false}>
      <div>
        <div ref={summaryEl} style={{ minHeight: '550px', margin: '-40px', padding: '40px' }} onClick={rewardMe}>
          {boardsInsights && renderInsights()}
        </div>
        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              zIndex: 10000,
              display: show ? 'block' : 'none'
            }}
          >
            <Reward ref={rewardEl} type="confetti" config={config}>
              <span id="confetti-container"></span>
            </Reward>
          </div>,
          document.body
        )}
      </div>
    </Modal>
  );
}
