import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button';
import { format } from 'date-fns';

import AppContext from 'AppContext';

import apiService from 'utils/apiService';

import PulseGrids from './PulseGrids';
import PulsePromo from './PulsePromo';
import PulseStats from './PulseStats';
import Log from 'utils/Log';

const TeamPulseOverview = ({ teamId, actions, canCreateBoard }) => {
  const history = useHistory();
  const { org } = useContext(AppContext);
  const [pulsesData, setPulsesData] = useState([]);
  const { enabledModels = [] } = org || {};
  const { id } = enabledModels[0] || {};
  const latestPulseData = pulsesData[0];
  const { pulseId, name, timestamp } = latestPulseData || {};

  const renderOverview = () => {
    if (!latestPulseData) return null;

    return (
      <>
        <div className="prevBoard">
          <h3>Title</h3>
          <p>
            <a href={`/pulse-survey/${pulseId}`}>{name}</a>
          </p>
        </div>
        <div className="prevBoard">
          <h3>Date</h3>
          <p>{timestamp && format(timestamp, 'PPP')}</p>
        </div>
        <PulseStats id={pulseId} pulseData={latestPulseData} actions={actions} noEmoji />
      </>
    );
  };

  useEffect(() => {
    if (teamId && id) {
      apiService
        .get(`/api/team/${teamId}/pulse?modelId=${id}&getLatest=true`)
        .then(({ data }) => {
          setPulsesData(data);
        })
        .catch((e) => e);
    }
  }, [teamId, id]);

  if (!(enabledModels && enabledModels[0])) {
    return <PulsePromo isTeam />;
  }

  return (
    <Paper>
      <div className="teamDashboard-grid teamDashboard-grid-2">
        <h2>Latest Pulse</h2>
        {renderOverview()}

        <div style={{ margin: '24px 0 24px 0' }}>
          {enabledModels && enabledModels[0] && (
            <PulseGrids modelData={enabledModels[0].format} pulsesData={pulsesData} minPulse={1} />
          )}
        </div>

        <div style={{ textAlign: 'center' }}>
          {canCreateBoard ? (
            <Button
              onClick={() => {
                Log('Pulse', 'Run from team dashboard');
                history.push(`/team/${teamId}/pulse-survey/create?modelId=${id}`);
              }}
            >
              Start Pulse Survey
            </Button>
          ) : (
            <Tooltip placement="top" title="To start Pulse Survey, ask your team admin to make you a Facilitator">
              <Button disabled style={{ pointerEvents: 'auto' }}>
                Start Pulse Survey
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default TeamPulseOverview;
