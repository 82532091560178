import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', justifyContent: 'center' },
  image: {
    width: '28px',
    height: '28px',
    border: 'none'
  }
}));

const MembersAvatarGroup = ({ members, max = 3 }) => {
  const classes = useStyles();

  const renderAvatar = (member) => {
    const { id, profile } = member;
    const { picture } = profile;
    const displayName = getDisplayName(profile);

    return (
      <Tooltip title={displayName} placement="top" key={`avatar-${id}`}>
        <Avatar className={classes.image} alt={displayName} src={picture} />
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      {members.length > 0 ? (
        <AvatarGroup max={max} spacing={4}>
          {members.map(renderAvatar)}
        </AvatarGroup>
      ) : (
        '-'
      )}
    </div>
  );
};

export default MembersAvatarGroup;
