import React, { useState, useEffect } from 'react';
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from 'assets/jss/material-kit-react/components/navPillsStyle.js';
import { ReactComponent as EditIcon } from 'images/icons/edit-white.svg';

const useStyles = makeStyles(styles);

export default function NavPills(props) {
  const [active, setActive] = useState(props.active);
  const tabsViewProps = {};

  if (useMediaQuery('(max-width: 640px)')) {
    tabsViewProps.variant = 'scrollable';
    tabsViewProps.scrollButtons = 'auto';
  } else {
    tabsViewProps.centered = true;
  }

  const handleChange = (event, active) => {
    setActive(active);
    onChange(active);
  };
  const handleChangeIndex = (index) => {
    setActive(index);
  };
  const classes = useStyles();
  const { tabs, direction, horizontal, onChange } = props;

  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  const tabButtons = (
    <Tabs value={active} onChange={handleChange} {...tabsViewProps}>
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
        }

        return <Tab label={prop.tabButton} key={key} {...icon} disableRipple={true} disabled={prop.disabled} />;
      })}
      {props.editCallBack && (
        <span className="tabEditBtn" onClick={props.editCallBack}>
          <EditIcon />
        </span>
      )}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews axis={direction === 'rtl' ? 'x-reverse' : 'x'} index={active} onChangeIndex={handleChangeIndex}>
        {tabs.map((prop, key) => {
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary'
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.object,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf(['primary', 'warning', 'danger', 'success', 'info', 'rose']),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  }),
  alignCenter: PropTypes.bool
};
