import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppContext from 'AppContext';
import { getRole, checkPermission } from 'roles/checkPermission';

export default function OrgLinks({ current }) {
  const history = useHistory();
  const { org, userMeta } = useContext(AppContext);
  const { id: orgId } = org || {};
  const { role } = getRole({ orgId, userMeta });
  const canEditOrg = checkPermission(role, 'org:edit');
  const canEditBilling = checkPermission(role, 'billing:edit');
  const tabsViewProps = {};
  const subMenu = [{ title: 'Overview', data: '/dashboard' }];

  //Note: Pulse available for any org role
  subMenu.push({ title: 'Pulse', data: '/pulse' });

  if (useMediaQuery('(max-width: 748px)')) {
    tabsViewProps.variant = 'scrollable';
    tabsViewProps.scrollButtons = 'auto';
  } else {
    tabsViewProps.centered = true;
  }

  if (canEditOrg) {
    subMenu.push({ title: 'Admins', data: '/admins' });
  }

  if (canEditBilling) {
    subMenu.push({ title: 'Plans', data: '/plans' });
  }

  if (canEditOrg) {
    subMenu.push({ title: 'Settings', data: '/settings' });
  }

  if (!role) return null;

  return (
    <Tabs
      onChange={(e, value) => {
        history.push(value);
      }}
      value={current}
      {...tabsViewProps}
    >
      {subMenu.map((item, index) => (
        <Tab
          key={`org-tab-${index}`}
          value={item.data}
          disableRipple={true}
          style={{
            width: 150
          }}
          label={<div>{item.title}</div>}
        />
      ))}
    </Tabs>
  );
}
