import React, { useState, useRef } from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

import Log from 'utils/Log';
import StyledBadge from './StyledBadge';
import OnVotingConfirm from 'modules/OnVotingConfirm';

const Voting = ({ onVote, votingStates, currentVotes = {} }) => {
  const { status, maxVotes } = votingStates;
  const [open, setOpen] = useState(false);
  const [votes, setVotes] = useState(maxVotes || 3);
  const [error, setError] = useState(false);
  const [showVotingOptions, setShowVotingOptions] = useState(false);
  const hasCurrentVotes = Boolean(Object.keys(currentVotes).length > 0);
  const panelEl = useRef(null);
  const isVoting = Boolean(status === 'vote');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setVotes(value);

    if (Number.isInteger(Number(value)) && value > 0 && value < 100) {
      error && setError(false);
    } else {
      setError(true);
    }
  };

  const vote = (options = {}) => {
    if (error) return;

    const { resetVotes, isEnd } = options;

    setOpen(false);
    showVotingOptions && setShowVotingOptions(false);

    if (isEnd) {
      onVote({ allowedVotes: votes });
    } else {
      onVote({ allowedVotes: votes, resetVotes });
      Log('Vote', `votes: ${votes} ${resetVotes ? '- reset' : ''}`);
    }
  };

  const renderVotingPanel = () => {
    const handleVoting = () => {
      if (hasCurrentVotes) {
        setShowVotingOptions(true);
        setOpen(false);
      } else {
        vote();
      }
    };

    return (
      <div>
        <TextField
          id="votes-per-person"
          type="number"
          label="Votes Per Person"
          inputProps={{ min: 1, max: 100 }}
          InputLabelProps={{
            shrink: true
          }}
          value={votes}
          onChange={handleChange}
          error={error}
          helperText={error ? 'Please enter a valid number' : ''}
        />

        <span className="text-button" onClick={handleVoting}>
          Start Voting
        </span>
      </div>
    );
  };

  return (
    <>
      <div onMouseLeave={handleClose}>
        <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
          <StyledBadge color="secondary" variant="dot" invisible={Boolean(!isVoting)} overlap="rectangular">
            Voting
          </StyledBadge>
        </div>
        <div className="actionPanelList">
          <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                <Paper>
                  <div className="actionPanelBoard">
                    <h3 onMouseOver={handleOpen}>Voting</h3>
                    {isVoting ? (
                      <div>
                        <p>After everyone has voted, end the voting session to see the results</p>
                        <span className="text-button" onClick={(e) => vote({ isEnd: true })}>
                          End Voting
                        </span>
                      </div>
                    ) : (
                      renderVotingPanel()
                    )}
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <OnVotingConfirm
        showVotingOptions={showVotingOptions}
        onVote={vote}
        onClose={() => {
          setShowVotingOptions(false);
        }}
      />
    </>
  );
};

export default Voting;
