import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

import { makeStyles } from '@material-ui/core/styles';

import UserNames from 'dashboard/UserNames';

const useStyles = makeStyles((theme) => ({
  group: {
    padding: '0',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    display: 'flex',
    letterSpacing: '0',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: '5px',
    minWidth: '36px' //Important! 36px width = note size 228 = 24 x 2+ 36 x 5 (emojis)
    //flex: '0 0 20%' //5 columns not work quite well for extra groups in popup
  }
}));

export const GithubCounterGroup = ({ emoji, count, onSelect, names, showProfile }) => {
  const userIds = names.filter((userId) => userId && userId.length > 18);
  const classes = useStyles();

  const handleClick = (e) => {
    if (!onSelect) return;

    onSelect(emoji);
    e.stopPropagation();
  };

  const renderEmojiInfo = (emoji, count) => {
    return (
      <span style={{ display: 'flex' }}>
        {emoji}
        <span>{count}</span>
      </span>
    );
  };

  return (
    <div className={onSelect ? `${classes.group} emojiCounter-group` : classes.group} onMouseDown={handleClick}>
      {showProfile && userIds.length > 0 ? (
        <Tooltip title={<UserNames userIds={userIds} />} placement="top" TransitionComponent={Fade}>
          {renderEmojiInfo(emoji, count)}
        </Tooltip>
      ) : (
        renderEmojiInfo(emoji, count)
      )}
    </div>
  );
};

export default GithubCounterGroup;
