import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import * as Sentry from '@sentry/browser';

import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import OpenWithIcon from '@material-ui/icons/OpenWith';

import { isSpotify, toSpotifyEmbed } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: '0',
    bottom: '0',
    zIndex: '10',
    '&:hover': {
      '& $handle': {
        display: 'block'
      }
    }
  },
  handle: {
    position: 'absolute',
    top: '-11px',
    right: '0',
    cursor: 'move',
    color: '#fff',
    zIndex: '1',
    display: 'none'
  }
}));

//16x9
const dimensions = {
  compact: {
    width: '320px',
    height: '120px'
  },
  standard: {
    width: '380px',
    height: '214px'
  }
};

function Media({ mediaStates }) {
  const classes = useStyles();
  const { source, volume } = mediaStates;
  //const [ended, setEnded] = useState();
  const playerEl = useRef(null);
  // Youtube need min 350px width to show volumn control, vimeo need 380px min
  const dimension =
    source.includes('soundcloud') || source.includes('mixcloud') ? dimensions['compact'] : dimensions['standard'];
  const spotifyPlayer = isSpotify(source);

  const onReady = () => {};
  const onEnded = () => {
    //playerEl.current && playerEl.current.seekTo(0);
    //setEnded(true);
  };
  const onError = (e) => {
    Sentry.captureException(e);
  };

  return (
    <Draggable bounds="parent" handle=".media-handle">
      <div className={classes.root}>
        <div className={`media-handle ${classes.handle}`}>
          <OpenWithIcon />
        </div>
        {spotifyPlayer ? (
          <iframe
            src={toSpotifyEmbed(source)}
            title="Spotify player"
            width="300"
            height="80"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        ) : (
          <ReactPlayer
            ref={playerEl}
            width={dimension.width}
            height={dimension.height}
            playing={true}
            controls={true}
            url={source}
            volume={volume / 200}
            onReady={onReady}
            onEnded={onEnded}
            onError={onError}
          />
        )}
      </div>
    </Draggable>
  );
}

export default Media;
