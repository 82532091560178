import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import Truncate from 'react-truncate';

import Tooltip from '@material-ui/core/Tooltip';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Paper from '@material-ui/core/Paper';

import Button from 'components/CustomButtons/Button';
import NotificationBar from 'modules/NotificationBar';
import { MoodSelector } from 'react-reactions/src';
import { getMoodColour } from 'utils/misc';
import EmojiPicker from './EmojiPicker';

export default function SurveyView({
  meta = {},
  topics = [],

  focusedId,
  onChangeMeta,
  onChangeTopic,

  onSubmit,
  submittedData
}) {
  const [data, setData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = topics.length;
  const [stage, setStage] = useState('intro');
  const [error, setError] = useState();

  const editableAreaClassName = `poll-placeholder ${onChangeMeta ? 'pulseArea-editable' : ''}`;

  const handleFocus = (e) => {
    e.target.classList.add('pulseCard-topic--editing');
  };

  const handleBlur = (e) => {
    e.target.classList.remove('pulseCard-topic--editing');
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.target.blur();
    }
  };

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setStage('end');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setStage('intro');
    } else {
      if (stage !== 'end') {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
      setStage('topics');
    }
  };

  const onSelect = (id, value) => {
    const newData = { ...data };
    newData[id] = {
      ...newData[id],
      value,
      timestamp: Date.now()
    };
    setData(newData);
  };

  const onFeedback = (e, id) => {
    const feedback = e.target.value;

    const newData = { ...data };
    newData[id] = {
      ...newData[id],
      feedback,
      timestamp: Date.now()
    };
    setData(newData);
  };

  const renderPreview = (topicData, index) => {
    const { id, title, icon } = topicData;
    const { value, feedback } = data[id] || {};

    return (
      <tr key={`survey-data-${id}`}>
        <th style={{ width: '90%', fontSize: '18px', fontWeight: '500' }}>
          <div className="surveyTopic">
            <span className="surveyTopic--icon">{icon}</span> <Truncate width={160}>{title}</Truncate>
          </div>
        </th>
        <td key={`preview-${id}-value`}>
          <div className="surveyValue" style={{ backgroundColor: getMoodColour(value) }}>
            {value}
          </div>
        </td>
        <td key={`preview-${id}-feedback`}>
          {feedback && (
            <Tooltip
              title={feedback}
              placement="top"
              enterTouchDelay={20}
              leaveTouchDelay={5000}
              disableFocusListener
              interactive
              disabled={!feedback}
            >
              <div className="surveyValue surveyValue--icon">💬</div>
            </Tooltip>
          )}
        </td>
      </tr>
    );
  };

  const renderStart = () => {
    return (
      <>
        <h2>
          {onChangeMeta ? (
            <EmojiPicker
              icon={meta.intro?.icon}
              onEmojiSelect={(e) => {
                onChangeMeta(e, 'intro', 'icon');
              }}
            />
          ) : (
            meta.intro?.icon
          )}
          <ContentEditable
            html={meta.intro?.title || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              onChangeMeta(e, 'intro', 'title');
            }}
            className={editableAreaClassName}
            placeholder="Welcome"
            disabled={!onChangeMeta}
          />
        </h2>
        <ContentEditable
          html={meta.intro?.description || ''}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            onChangeMeta(e, 'intro', 'description');
          }}
          className={editableAreaClassName}
          placeholder="Description"
          tagName="p"
          disabled={!onChangeMeta}
        />

        <p style={{ margin: '28px 0 0' }}>
          <Button
            onClick={() => {
              setStage('topics');
              setActiveStep(0);
            }}
            disabled={maxSteps === 0}
          >
            Start
          </Button>
        </p>
      </>
    );
  };

  const renderEnd = () => {
    return (
      <>
        <h2>
          {onChangeMeta ? (
            <EmojiPicker
              icon={meta.end?.icon}
              onEmojiSelect={(e) => {
                onChangeMeta(e, 'end', 'icon');
              }}
            />
          ) : (
            meta.end?.icon
          )}
          <ContentEditable
            html={meta.end?.title || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              onChangeMeta(e, 'end', 'title');
            }}
            className={editableAreaClassName}
            placeholder="Review"
            disabled={!onChangeMeta}
          />
        </h2>
        <ContentEditable
          html={meta.end?.description || ''}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            onChangeMeta(e, 'end', 'description');
          }}
          className={editableAreaClassName}
          placeholder="Description"
          tagName="p"
          disabled={!onChangeMeta}
        />

        <div className="pulseTable" style={{ maxHeight: '300px', overflow: 'auto', marginBottom: '24px' }}>
          <table style={{ width: '80%' }}>{<tbody>{topics.map(renderPreview)}</tbody>}</table>
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className="text-button text-button-secondary" onClick={handleBack}>
              Back
            </span>

            <Button disabled={!onSubmit} onClick={() => onSubmit(data)}>
              Submit
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderTopic = (topic) => {
    const { id, title, description, icon } = topic || {};

    return (
      <>
        <div key={`polling-view-${id}`}>
          <h2>
            {onChangeTopic ? (
              <EmojiPicker
                icon={icon}
                onEmojiSelect={(e) => {
                  onChangeTopic(e, 'icon', id);
                }}
              />
            ) : (
              icon
            )}
            <ContentEditable
              html={title || ''}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) => onChangeTopic(e, 'title', id)}
              className={editableAreaClassName}
              placeholder="Question"
              disabled={!onChangeTopic}
            />
          </h2>

          <ContentEditable
            html={description || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeTopic(e, 'description', id)}
            className={editableAreaClassName}
            placeholder={`${onChangeTopic ? 'Description' : ''}`}
            tagName="p"
            disabled={!onChangeTopic}
          />

          <div className="moodPollGrid">
            <MoodSelector onSelect={(value) => onSelect(id, value)} selectedValue={data[id]?.value} />
          </div>
          <div className="pulseArea-comments">
            <textarea
              rows="3"
              placeholder="Optional feedback"
              onChange={(e) => onFeedback(e, id)}
              value={data[id]?.feedback}
            ></textarea>
          </div>
        </div>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button size="sm" onClick={handleNext} disabled={onSubmit ? Boolean(!data[id]?.value) : false}>
              {activeStep === maxSteps - 1 ? 'Review' : 'Next'}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="sm" onClick={handleBack}>
              <KeyboardArrowLeft />
              {activeStep === 0 ? 'Start' : 'Back'}
            </Button>
          }
        />
      </>
    );
  };

  const renderHelpText = () => {
    if (onSubmit && (!onChangeMeta || !onChangeTopic)) return null;

    let helpText;

    if (onChangeMeta && onChangeTopic) {
      if (stage === 'intro') {
        helpText =
          'You’ve arrived at the Pulse Survey editor! Easily edit your Pulse Survey using the side panel and preview below.';
      }
      if (stage === 'topics') {
        helpText = 'This is a preview of what participants will see';
      }

      if (stage === 'end') {
        helpText = 'Participants can review their answers before submitting';
      }
    } else {
      if (stage === 'intro') {
        helpText = 'This is a preview of what participants will see';
      }
      if (stage === 'topics') {
        helpText = 'This is a preview of what participants will see';
      }

      if (stage === 'end') {
        helpText = 'This is a preview of what participants will see';
      }
    }

    return <p className="info-help-text">💡 {helpText}</p>;
  };

  useEffect(() => {
    if (focusedId === 'intro') {
      setStage('intro');
      return;
    }

    if (focusedId === 'end') {
      setStage('end');
      return;
    }

    if (focusedId) {
      const currentIndex = topics.findIndex(({ id }) => id === focusedId);
      setStage('topics');
      setActiveStep(currentIndex);
    }
  }, [focusedId, topics]);

  useEffect(() => {
    if (submittedData) {
      setData(submittedData);
      setStage('topics');
    }
  }, [submittedData]);

  return (
    <div className="surveyContainer">
      {renderHelpText()}
      <Paper>
        <div className="surveyView" style={{ justifyContent: `${stage === undefined ? 'center' : 'space-between'}` }}>
          {stage === 'intro' && renderStart()}
          {stage === 'topics' && renderTopic(topics[activeStep])}
          {stage === 'end' && renderEnd()}
        </div>
        {error && (
          <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />
        )}
      </Paper>
    </div>
  );
}
