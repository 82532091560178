import React from 'react';

import Paper from '@material-ui/core/Paper';
import Page from './Page';
import orgIntroImg from 'images/img/org-intro.jpg';

export default function EnterpriseWelcomeView() {
  return (
    <Page>
      <div className="teamsContainer">
        <div className="team-container-header">
          <h2>Your organization</h2>
          <p>
            Welcome! You have successfully signed into TeleRetro{' '}
            <span role="img" aria-label="">
              🎉
            </span>
          </p>
          <hr />
        </div>
        <div className="teamDashboard">
          <Paper>
            <div className="teamDashboard-grid" style={{ textAlign: 'center' }}>
              <h2>Getting started</h2>
              <p>You are not yet a member of any team. Ask your colleagues send you an invite link to join a team.</p>
              <p>Already have an invite link? Now that you’re signed in, visit the link to accept the invite.</p>
              <p>
                <img src={orgIntroImg} alt="Organization Preview" width="900" />
              </p>
            </div>
          </Paper>
        </div>
      </div>
    </Page>
  );
}
