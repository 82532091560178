import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppContext from 'AppContext';
import getMonth from 'date-fns/getMonth';

import { ReactComponent as HomeIcon } from 'images/icons/home.svg';

import OrgMenu from './OrgMenu';

import Profile from 'controls/Profile';
import { getTeamLink } from 'utils/misc';
import useSsoConnection from 'hooks/useSsoConnection';

export default function HomeMenu({ teamId, boardId }) {
  const location = useLocation();
  const { isAuthenticated, profile } = useContext(AppContext);

  const dashboardLink = getTeamLink({ teamId });
  const { loginUrl } = useSsoConnection({ teamId, boardId, isDeepLink: true });
  const noLoginButton = !isAuthenticated && location.pathname.includes('/invite');

  const isDecember = Boolean(getMonth(new Date()) === 11); //Month start with 0

  const renderHomeButton = () => {
    if (noLoginButton) return null;

    return (
      <>
        {isAuthenticated ? (
          <Profile profile={profile} dashboardLink={dashboardLink} />
        ) : (
          <a className="loginBtn" href={loginUrl}>
            Sign in
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <Link to={dashboardLink} className={`home ${isDecember ? 'home-christmas' : ''}`}>
        <HomeIcon />
      </Link>
      <OrgMenu />
      {renderHomeButton()}
    </>
  );
}
