import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Truncate from 'react-truncate';

import NotificationBar from 'modules/NotificationBar';

import apiService from 'utils/apiService';
import { getMoodColour } from 'utils/misc';
import PulseGridsMenu from './PulseGridsMenu';
import AppContext from 'AppContext';
import Log from 'utils/Log';

//Note: can show comments on hover
//import Tooltip from '@material-ui/core/Tooltip';
//import PulseComments from './PulseComments';

const PulseGrids = ({ orgId, teamId, modelData, pulsesData, getPulsesData, minPulse = 1, isCompact, isBoardView }) => {
  const history = useHistory();
  const { org } = useContext(AppContext);
  const [displayPulse, setDisplayPulse] = useState(pulsesData);
  const { teamsSettings } = org || {};
  const { id, topics = [] } = modelData || {};
  const [error, setError] = useState();

  const onDeletePulse = (pulseId) => {
    apiService
      .delete(`/api/team/${teamId}/pulse/${pulseId}`)
      .then(() => {
        getPulsesData();
      })
      .catch((e) => {
        setError(e);
      });

    Log('Pulse', 'Delete Survey');
  };

  const renderDistribution = ({ pulseId, distribution }) => {
    const max = Math.max(...Object.values(distribution));
    return (
      <div className="surveyPercent">
        {[1, 2, 3, 4, 5].map((d, i) => {
          const value = distribution[d];
          const height = Math.round((value / max) * 100) + '%';
          return (
            <span
              key={`${pulseId}-survey-percent-${i}`}
              style={{ backgroundColor: getMoodColour(d) || 'transparent', height }}
            ></span>
          );
        })}
      </div>
    );
  };

  const renderOverview = () => {
    const renderTotalDistribution = (pulseData, index) => {
      if (!pulseData)
        return (
          <td key={`pulse-distribution-${index}`}>
            <div className="surveyValue"></div>
          </td>
        );
      if (!pulseData.pulseId)
        return (
          <td key={`pulse-distribution-${index}`}>
            <div className="surveyValue surveyValue--noValue"></div>
          </td>
        );

      const { pulseId, distribution } = pulseData;

      return <td key={`${pulseId}-distribution`}>{renderDistribution({ pulseId, distribution })}</td>;
    };

    const renderAverage = (pulseData, index) => {
      if (!pulseData)
        return (
          <td key={`pulse-average-${index}`}>
            <div className="surveyValueWrapper">
              <div className="surveyValue"></div>
            </div>
          </td>
        );
      if (!pulseData.pulseId)
        return (
          <td key={`pulse-average-${index}`}>
            <div className="surveyValueWrapper">
              <div className="surveyValue surveyValue--noValue"></div>
            </div>
          </td>
        );

      const { pulseId, distribution, averageValue } = pulseData;

      return (
        <td key={`${pulseId}-average`} className={`${pulsesData.length === index + 1 ? 'surveyValueLastContent' : ''}`}>
          <div className="surveyValueWrapper">
            {isBoardView && renderDistribution({ pulseId, distribution })}
            <div className="surveyValue" style={{ backgroundColor: getMoodColour(averageValue) }}>
              {averageValue}
            </div>
          </div>
        </td>
      );
    };
    return (
      <>
        {!isBoardView && (
          <tr key={'survey-data-distribution'}>
            <th>
              <div className="surveyTopic">
                <span className="surveyTopic--icon">📊</span> Distribution
              </div>
            </th>
            {displayPulse.map(renderTotalDistribution)}
          </tr>
        )}

        <tr key={'survey-data-comments'}>
          <th>
            <div className="surveyTopic">
              <span className="surveyTopic--icon">💬</span> Comments
            </div>
          </th>
          {displayPulse.map(renderFeedbackOverview)}
        </tr>
        <tr key={'survey-data-average'}>
          <th>
            <div className="surveyTopic">
              <span className="surveyTopic--icon">⚖️</span> Average
            </div>
          </th>
          {displayPulse.map(renderAverage)}
        </tr>
      </>
    );
  };

  const renderTopic = ({ id, title, icon }) => {
    const renderData = (pulseData, index) => {
      if (!pulseData)
        return (
          <td key={`topic-${id}-${index}`}>
            <div className="surveyValueWrapper">
              <div className="surveyValue"></div>
            </div>
          </td>
        );

      const { pulseId, averageByTopic, distributionByTopic } = pulseData;

      if (!averageByTopic)
        return (
          <td key={`topic-${id}-${index}`}>
            <div className="surveyValueWrapper">
              <div className="surveyValue surveyValue--noValue"></div>
            </div>
          </td>
        );

      const averageScore = averageByTopic[id];

      return (
        <td
          key={`${pulseId}-${id}-value`}
          className={`${pulsesData.length === index + 1 ? 'surveyValueLastContent' : ''}`} //css class for rounded borders
        >
          <div className="surveyValueWrapper">
            {isBoardView && renderDistribution({ pulseId, distribution: distributionByTopic[id] })}
            <div className="surveyValue" style={{ backgroundColor: getMoodColour(averageScore) }}>
              {averageScore}
            </div>
          </div>
        </td>
      );
    };

    return (
      <tr key={`survey-data-${id}`} className="surveyDate-content">
        <th>
          <div className="surveyTopic">
            <span className="surveyTopic--icon">{icon}</span> <Truncate width={160}>{title}</Truncate>
          </div>
        </th>
        {displayPulse.map(renderData)}
      </tr>
    );
  };

  const renderHeading = (pulseData, index) => {
    if (!pulseData) return <td className="pulseTable-header" key={`pulseHeader-${index}`}></td>;

    const { pulseId, name } = pulseData || {};
    const displayLink = orgId ? `/dashboard/team/${pulseData.teamId}/pulse` : `/pulse-survey/${pulseId}`;
    const displayName = orgId ? teamsSettings[pulseData.teamId]?.name : name;

    return (
      <td key={`${pulseId}-${id}-name-${index}`} className={'pulseTable-header'}>
        <span className="text-button" title={displayName} onClick={() => history.push(displayLink)}>
          <Truncate width={orgId ? 120 : 140}>{displayName}</Truncate>
        </span>
        {teamId && <PulseGridsMenu pulseData={pulseData} onDeletePulse={onDeletePulse} />}
      </td>
    );
  };

  const renderFeedbackOverview = (pulseData, index) => {
    if (!pulseData)
      return (
        <td key={`pulseHeader-${index}`}>
          <div className="surveyValueWrapper">
            <div className="surveyValue"></div>
          </div>
        </td>
      );
    if (!pulseData.pulseId)
      return (
        <td key={`pulseHeader-${index}`}>
          <div className="surveyValueWrapper">
            <div className="surveyValue surveyValue--noValue"></div>
          </div>
        </td>
      );

    const { pulseId, feedbackByTopic } = pulseData || {};
    const total = feedbackByTopic && Object.values(feedbackByTopic).flat().length;

    return (
      <td key={`${pulseId}--feedback`}>
        <div className="surveyValueWrapper">
          {total > 0 ? (
            // <Tooltip
            //   title={
            //     <div className={'pulseTable-tooltip'}>
            //       <PulseComments pulseData={pulseData} topics={topics} />
            //     </div>
            //   }
            //   placement="top"
            //   enterTouchDelay={20}
            //   leaveTouchDelay={5000}
            //   disableFocusListener
            //   interactive
            // >
            //</Tooltip>
            <div className="surveyValue">{total}</div>
          ) : (
            <div className="surveyValue surveyValue--noValue"></div>
          )}
        </div>
      </td>
    );
  };

  const renderDate = (pulseData, index) => {
    if (!pulseData)
      return (
        <td key={`pulse-date-${index}`}>
          <div className="surveyDate"></div>
        </td>
      );

    const { pulseId, timestamp } = pulseData || {};
    const date = timestamp;

    return (
      <td key={`${pulseId}-${id}-date-${index}`}>
        <div className="surveyDate">
          {date ? (
            <>
              <span>{format(date, 'MMM')}</span> <span>{format(date, 'd')}</span>
            </>
          ) : (
            '-'
          )}
        </div>
      </td>
    );
  };

  useEffect(() => {
    if (pulsesData && pulsesData.length < minPulse) {
      const emptyPulses = Array(minPulse - pulsesData.length).fill(null);
      const extendedPulses = [...pulsesData, ...emptyPulses];
      setDisplayPulse(extendedPulses);
    } else {
      setDisplayPulse(pulsesData);
    }
  }, [pulsesData, minPulse]);

  if (!displayPulse) return null;

  return (
    <div className={`pulseTable ${orgId ? 'pulseTable--org' : 'pulseTable--team'}`}>
      <table>
        <thead>
          {!(isBoardView || minPulse === 1) && (
            <>
              <tr>
                <th></th>
                {displayPulse.map(renderHeading)}
              </tr>
              <tr>
                <th>
                  <div className="surveyTopic">
                    <span className="surveyTopic--icon">🗓</span> {`${orgId ? 'Last update' : 'Date'}`}
                  </div>
                </th>
                {displayPulse.map(renderDate)}
              </tr>
            </>
          )}
          {renderOverview()}
        </thead>
        <tbody>{!isCompact && topics.map(renderTopic)}</tbody>
      </table>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
};

export default PulseGrids;
