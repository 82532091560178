import React, { useState, useEffect, useCallback, useMemo } from 'react';
//import * as Sentry from '@sentry/browser';

import RGL from '../react-grid-layout';
import isEqual from 'lodash/isEqual';
import { nanoid } from 'nanoid';
import throttle from 'lodash/throttle';
import groupBy from 'lodash/groupBy';

import OnVotingProgress from 'modules/OnVotingProgress';
import Typing from './Typing';
import CardSettings from './CardSettings';
import logger from 'utils/logger';
import { groupSign, defaultSize } from 'utils/constant';
import { mergeUsers } from 'utils/misc';
import { unGroupItems, sortItems, getExistingVotesCount } from './helpers/cards';

import addImg from 'images/img/add-note.png';

import './Board.css';

const ReactGridLayout = RGL; // WidthProvider(RGL);

let isDragging = false;
let isEditing = false;
let lastGuid;
let deletedIds = [];
let addedIds = [];
let localNote;
let isPickingEmoji;

const SPACE_X = 15;
const SPACE_Y = 14;

const hash = nanoid(); //unique session user identifier

function Board({
  boardId,
  user,
  remoteNotes,
  remoteType,
  remoteStatus,
  onSave,
  boardSettings,
  votes,
  updateRemainingVotes,
  votingStates,
  setSortBy
}) {
  const userId = user?.id;
  const { status, contentId, existingVotes = {} } = votingStates;
  const userExistingVotes = existingVotes[userId]?.data;
  const { editingStatus, maskComments } = remoteStatus;
  const maxVotes = parseInt(votingStates.maxVotes, 10);

  const isVoting = Boolean(status === 'vote');

  const { compact, columns, isAnonymous, sortBy } = boardSettings;

  const colsCount = compact ? columns?.length : 5;
  const maxViewport = ((1200 - SPACE_X * 4) / 5) * colsCount + SPACE_X * (colsCount - 1); //  // (1200 - SPACE_X * 4)/5 228 card width

  const scale = Math.min(document.querySelector('html').clientWidth - 40, maxViewport) / maxViewport; // 40 is the padding on board-columns

  const defaultGrid = useMemo(
    () => ({
      className: 'layout',
      cols: defaultSize.w * colsCount,
      rowHeight: 10,
      margin: [SPACE_X, SPACE_Y], // horizontal, vertical spacing
      containerPadding: [0, 0]
    }),
    [colsCount]
  );

  const [gridProps, setGridProps] = useState(defaultGrid);
  const [notes, setNotes] = useState(remoteNotes || []);
  const [remainingVotes, setRemainingVotes] = useState(maxVotes);
  const [editType, setEditType] = useState();
  const [localVotes, setLocalVotes] = useState({});
  const [votingWarn, setVotingWarn] = useState(false);

  const boardColumnsAdd = Array.from(Array(colsCount).keys()).map((item, i) => (
    <div key={`board-column-${i}`} className="board-column" onClick={() => onAddClick({ x: i, y: 0 })}>
      <img src={addImg} alt="Add note" />
    </div>
  ));

  const onCloseWarn = () => {
    setVotingWarn(false);
  };

  const onLayoutChange = (layout, type) => {
    onLayoutUpdate(layout, type, notes, remoteNotes, userId);
  };

  const onLayoutUpdate = useMemo(
    () =>
      throttle(
        (layout, type, notes, remoteNotes, userId) => {
          if (type === 'remote') return;

          const newNotes = notes.map((note) => {
            const { id } = note;
            const grids = layout.filter((item) => item.i === id);

            if (grids[0]) {
              return {
                ...note,
                grid: {
                  ...note.grid,
                  x: grids[0].x,
                  y: grids[0].y
                }
              };
            } else {
              return note;
            }
          });

          if (!isEqual(newNotes, remoteNotes)) {
            logger('send layout change', type, newNotes);
            setNotes(newNotes);
            setEditType('local');
            onSave({ boardId, notes: newNotes, type });
          }
        },
        1000,
        { leading: true, trailing: true }
      ),
    [boardId, onSave]
  );

  const onSaveStatus = useMemo(
    () =>
      throttle(
        ({ boardId, status }) => {
          onSave({
            boardId,
            status
          });
        },
        1000,
        { leading: true, trailing: false } // Important: trailing: false so sending typing not delayed causing typing infinitely
      ),
    [onSave]
  );

  const cancelDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const setFocus = (e, id, showTyping, maskComment) => {
    if (!isDragging || isVoting || showTyping || maskComment) return;

    if (!e.target?.parentElement?.classList?.contains('note-body')) return;

    setBlur();

    sharedFocusActions(e, id);

    lastGuid = id;

    setGridProps({ ...defaultGrid, isDraggable: false });

    const container = e.target?.closest('.react-grid-item');
    const textarea = container && container.querySelector('.note-body textarea');

    setTimeout(() => {
      textarea && textarea.focus();
      // This cause copy and paste not working
      //textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }, 0);

    cancelDefault(e);
  };

  const setBlur = () => {
    const container = document.querySelector('article.note-editing');

    if (container) {
      container.classList.remove('note-editing');
      lastGuid = null;
      setGridProps({ ...defaultGrid });
    }
  };

  const startEditing = (e, currentNote) => {
    sharedFocusActions(e);
  };

  const sharedFocusActions = (e, id) => {
    let container;

    container = e.target?.closest('.react-grid-item');

    if (!container && id) {
      container = document.querySelector(`[data-id="${id}"].react-grid-item`);
    }

    const wrapper = container && container.querySelector('article');

    wrapper && wrapper.classList.add('note-editing');
  };

  const onEditComplete = (e, currentNote) => {
    if (isPickingEmoji) return;

    const container = e.target.closest('.react-grid-item').querySelector('article');
    const textArea = e.target;
    const newValue = textArea.value;

    container && container.classList.remove('note-editing');

    saveEdited(newValue);

    localNote = null;
    lastGuid = null;
    isEditing = false;

    if (isVoting) {
      setGridProps({ ...defaultGrid, isDraggable: false });
    } else {
      setGridProps({ ...defaultGrid });
    }
  };

  const saveEdited = useCallback(
    (newValue) => {
      if (!localNote) return;
      const date = new Date();
      notes.forEach((note) => {
        if (note.id === localNote.id) {
          note.text = newValue === undefined ? localNote.text : newValue;
          note.timeStamp = date.getTime();
          note.lastEditedBy = userId;
        }
      });

      const updatedNotesStatus = editingStatus.filter(
        (noteStatus) => noteStatus.id !== localNote.id && noteStatus.isEditingBy !== userId
      );

      // This is only for fix typing flashing on the end of typing, update local remoteStatus and not change object reference
      // editingStatus.forEach((noteStatus) => {
      //   if (noteStatus.id === localNote.id && noteStatus.isEditingBy === userId) {
      //     delete noteStatus.isEditingBy;
      //   }
      // });

      // Note: To save this and not conflict with add/reomve, using throttle
      onSave({
        boardId,
        notes,
        type: 'edit',
        status: updatedNotesStatus
      });

      logger('send edited');
    },
    [boardId, notes, onSave, userId, editingStatus]
  );

  const setEditMode = (currentNote) => {
    if (!localNote) return;

    if (!isEditing) {
      isEditing = true;
    }

    let updatedNotesStatus = [...editingStatus];
    const notesInEditing = editingStatus.filter(
      (noteStatus) => noteStatus.id === localNote.id && noteStatus.isEditingBy === userId
    )[0];

    if (notesInEditing) {
      return;
    } else {
      updatedNotesStatus = editingStatus.filter(
        (noteStatus) => noteStatus.id !== localNote.id && noteStatus.isEditingBy !== userId
      );

      updatedNotesStatus.push({
        id: localNote.id,
        isEditingBy: userId,
        hash
      });
    }

    if (!isEqual(editingStatus, updatedNotesStatus)) {
      onSaveStatus({
        boardId,
        status: updatedNotesStatus
      });
    }
  };

  const setEmojiReaction = (note, emojis) => {
    //Fix emoji sometimes not get selected
    //if (!isDragging) return;

    const newNotes = notes.map((noteItem) => {
      if (noteItem.id === note.id) {
        const updatedNote = { ...noteItem };
        if ((!noteItem.emojis || noteItem.emojis.length === 0) && emojis.length > 0) {
          updatedNote.grid.h = updatedNote.grid.h + 1;
        }

        if (
          noteItem.emojis &&
          noteItem.emojis.length > 0 &&
          emojis.length === 0 &&
          updatedNote.grid.h > defaultSize.h
        ) {
          updatedNote.grid.h = updatedNote.grid.h - 1;
        }

        updatedNote.emojis = emojis;

        return updatedNote;
      } else {
        return noteItem;
      }
    });

    logger('send emoji reaction', newNotes);

    setNotes(newNotes);
    setEditType('emoji');
    onSave({ boardId, notes: newNotes, type: 'edit' });

    return emojis;
  };

  const setComments = (note, comments) => {
    const newNotes = notes.map((noteItem) => {
      if (noteItem.id === note.id) {
        const updatedNote = { ...noteItem };

        updatedNote.comments = comments;

        return updatedNote;
      } else {
        return noteItem;
      }
    });

    logger('send comments', newNotes);

    setNotes(newNotes);
    setEditType('comment');
    onSave({ boardId, notes: newNotes, type: 'edit' });

    return comments;
  };

  const updateNoteHeight = useCallback(
    (textArea, currentNote, notes) => {
      if (!textArea) return;

      let h;
      textArea.style.height = '1px';

      //Container height: Math.round(rowHeight * h + Math.max(0, h - 1) * SPACE_Y)

      //Current contaier height: 10 * 3 + 2 * 14 = 58

      h = Math.round(textArea.scrollHeight / 24) + 2; // 24 textarea line height

      if (currentNote.emojis && currentNote.emojis.length > 0) {
        const groups = groupBy(currentNote.emojis, 'emoji');
        const emojis = Object.keys(groups);
        const extraLines = Math.ceil(emojis.length / 5); //Make flex css is display 5 columns of emojis cross browsers
        h += extraLines;
      }

      if (h >= defaultSize.h && h !== currentNote.grid.h) {
        currentNote.grid.h = h;

        notes.forEach((note) => {
          if (currentNote.id === note.id) {
            const date = new Date();
            note.grid.h = h;
            note.timeStamp = date.getTime();
            note.lastEditedBy = userId;
          }
        });

        setNotes([...notes]);
        setEditType('editing');
      }

      textArea.style.height = '100%';
    },
    [userId]
  );

  const onChange = (e, currentNote) => {
    const textArea = e.target;

    if (!localNote) {
      localNote = { ...currentNote };
    } else {
      localNote.text = textArea.value;
    }

    setEditMode(currentNote);
    updateNoteHeight(textArea, currentNote, notes);
  };

  const updateTextChange = (target, selectedEmoji, currentNote) => {
    const textArea = target.closest('.react-grid-item').querySelector('textarea');

    if (!textArea) return;

    //Todo: add test
    const cursorPosition = textArea.selectionEnd;
    const start = textArea.value.substring(0, textArea.selectionStart);
    const end = textArea.value.substring(textArea.selectionStart);
    const selectionPoint = cursorPosition + selectedEmoji.length;

    textArea.value = start + selectedEmoji + end;

    textArea.setSelectionRange(selectionPoint, selectionPoint);

    localNote = { ...currentNote, text: textArea.value };

    setEditMode(currentNote);
    updateNoteHeight(textArea, currentNote, notes);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.target.blur();
    }
  };

  const onDragStart = (layout, l1, l2, empty, e) => {
    isDragging = true;
  };

  const onDragEnd = (layout, l1, l2, empty, e) => {
    isDragging = false;
    setBlur();
  };

  const getNewNote = ({ x, y }) => {
    const uid = nanoid();
    const date = new Date();

    const X = Number.isInteger(x) ? x : (notes.length * defaultSize.w) % defaultGrid.cols; // first number is width  last number is col;
    const Y = Number.isInteger(y) ? y : 100; // puts it at the bottom

    const item = {
      grid: {
        i: `${uid}`,
        x: X,
        y: Y,
        w: defaultSize.w,
        h: defaultSize.h
      },
      id: uid,
      text: '',
      timeStamp: date.getTime(),
      createdBy: userId
    };

    return item;
  };

  const addNote = ({ x, y }) => {
    const newNote = getNewNote({ x, y });
    const newNotes = [newNote].concat(notes); //notes.concat(newNote);
    setNotes(newNotes);
    setEditType('add');
    lastGuid = newNote.id;
    addedIds.push(newNote.id);
  };

  const deleteNote = (e, currentNote) => {
    const [currentNotes] = deleteNoteFromNotes(currentNote.id);

    setNotes(currentNotes);
    setEditType('delete');

    e.stopPropagation();
    e.preventDefault();
  };

  const onAddVote = (e, currentNote) => {
    // !important id is board id, do not mix with local id
    if (isVoting && contentId !== boardId) {
      setVotingWarn(true);
      return;
    }

    if (remainingVotes >= 1) {
      const id = currentNote.id;
      const newVotes = { ...localVotes };
      if (newVotes[id] !== undefined) {
        newVotes[id] += 1;
      } else {
        newVotes[id] = 1;
      }
      setLocalVotes(newVotes);
      setRemainingVotes(remainingVotes - 1);
      submitVoting(remainingVotes - 1, newVotes);
    }
    cancelDefault(e);
  };

  const onRemoveVote = (e, currentNote) => {
    if (isVoting && contentId !== boardId) {
      setVotingWarn(true);
      return;
    }

    const id = currentNote.id;
    const newVotes = { ...localVotes };

    if (newVotes[id] >= 1) {
      newVotes[id] -= 1;
      setLocalVotes(newVotes);
      setRemainingVotes(remainingVotes + 1);
      submitVoting(remainingVotes + 1, newVotes);
    }

    cancelDefault(e);
  };

  const onSendAction = (newAction) => {
    onSave({ boardId: 'action', newAction });
  };

  const onDdClick = ({ x, y }) => {
    if (isVoting) return;

    addNote({ x, y });
  };

  const onAddClick = ({ x, y }) => {
    if (isVoting) {
      setVotingWarn(true);
      return;
    }
    addNote({ x, y });
  };

  const submitVoting = useCallback(
    (leftOver, latestVotes) => {
      onSave({
        boardId,
        votes: { id: userId, data: latestVotes, completed: leftOver === 0 ? true : false }
      });
    },
    [onSave, boardId, userId]
  );

  const getLayout = (notes) => {
    return notes.map((note) => note.grid);
  };

  const onOverlap = (id, isGroup) => {
    if (!validGroup(id)) return;

    const groupClass = 'note-grouping';
    const existGroups = document.getElementsByClassName(groupClass);

    [].forEach.call(existGroups, function (group) {
      group && group.classList.remove(groupClass);
    });

    if (isGroup) {
      const groupCandidate = document.querySelector(`[data-id="${id}"] article.note`);
      groupCandidate && groupCandidate.classList.add(groupClass);
    }
  };

  const deleteNoteFromNotes = (id) => {
    let deletedNote;
    const currentNotes = [...notes];

    currentNotes.forEach((note, index) => {
      if (note.id === id) {
        deletedNote = currentNotes.splice(index, 1);
        deletedIds.push(id);
        addedIds = addedIds.filter((i) => i !== id);
      }
    });

    return [currentNotes, deletedNote];
  };

  const validGroup = (id) => {
    let isValid = true;

    const noteInEditing =
      Array.isArray(editingStatus) &&
      editingStatus.filter((noteStatus) => noteStatus.id === id && noteStatus.isEditingBy);

    if (noteInEditing.length > 0) {
      isValid = false;
    }

    if (maskComments) {
      const isOwnNote = notes.filter((item) => item.id === id && item.createdBy === userId);
      if (!isOwnNote.length) {
        isValid = false;
      }
    }

    return isValid;
  };

  const onGroup = ({ a, b }) => {
    if (!validGroup(a.i) || !validGroup(b.i)) return;

    const [currentNotes, deletedNote] = deleteNoteFromNotes(b.i);

    currentNotes.forEach((note, index) => {
      if (note.id === a.i) {
        const date = new Date();
        const removedNote = deletedNote[0];

        note.grid.h = note.grid.h + b.h - 1; // remove extra space

        if (note.emojis && note.emojis.length > 0 && removedNote.emojis && removedNote.emojis.length > 0) {
          note.grid.h = note.grid.h - 1;
        }

        note.emojis = [...(note.emojis || []), ...(removedNote.emojis || [])];
        // Update votes
        if (votes[b.i]) {
          votes[a.i] = (votes[a.i] || 0) + (votes[b.i] || 0);
        }

        note.text = `${note.text}${groupSign}${removedNote.text}`;

        note.groupedBy = mergeUsers(
          note.groupedBy || [note.createdBy],
          removedNote.groupedBy || [removedNote.createdBy]
        );

        note.comments = [...(note.comments || []), ...(removedNote.comments || [])];

        note.timeStamp = date.getTime();
      }
    });

    setNotes(currentNotes);
    setEditType('group');

    logger('send deleting', currentNotes);

    if (votes[b.i]) {
      // Send out updated votes if votes are grouped
      onSave({ boardId, notes: currentNotes, votes, type: 'delete' });
    } else {
      onSave({ boardId, notes: currentNotes, type: 'delete' });
    }
  };

  const unGroup = (groupedNote) => {
    const currentNotes = [...notes];
    const insertIndex = currentNotes.findIndex((note) => note.id === groupedNote.id);
    const newGroup = unGroupItems(groupedNote);

    // Insert to start of grouped notes, 1 is for delete the existing grouped notes
    currentNotes.splice(insertIndex, 1, ...newGroup);

    setNotes(currentNotes);
    //lastEditedBy and editType 'ungroup' are important for update notes height
    setEditType('ungroup');
  };

  const renderNote = (newNote) => {
    const isLocalNote = Boolean(localNote && localNote.id === newNote.id);
    let note = isLocalNote ? localNote : newNote;
    const { id, grid, createdBy } = note;
    const displayVote = isVoting ? localVotes[id] || 0 : votes[id] || 0;
    const text = isLocalNote ? localNote.text : newNote.text;
    const noteInEditing =
      (Array.isArray(editingStatus) && editingStatus.filter((noteStatus) => noteStatus.id === id)[0]) || {};

    // First check is for same user opening a seperate window
    const showTyping = !isLocalNote && noteInEditing.isEditingBy && noteInEditing.isEditingBy !== userId;
    const canEditNote = Boolean(isLocalNote || id === lastGuid);
    const maskComment = maskComments === null || (maskComments && Boolean(createdBy !== userId));

    return (
      <div
        key={id}
        data-id={id}
        data-grid={grid}
        className="noteContainer"
        onDoubleClick={cancelDefault}
        onClick={(e) => setFocus(e, id, showTyping, maskComment)}
        onTouchStart={(e) => setFocus(e, id, showTyping, maskComment)}
      >
        <article className="note-wrap note">
          <div className="note-body">
            {!showTyping &&
              (canEditNote ? (
                <textarea
                  onFocus={(e) => startEditing(e, note)}
                  onBlur={(e) => onEditComplete(e, note)}
                  onChange={(e) => onChange(e, note)}
                  onKeyDown={keyPress}
                  autoFocus={id === lastGuid}
                  defaultValue={text}
                />
              ) : (
                <textarea
                  value={maskComment ? newNote.text.replace(/[^\s]/g, '*') : newNote.text}
                  readOnly
                  className={`${maskComment ? 'note-blur' : ''}`}
                />
              ))}
            {showTyping && <Typing />}
          </div>
        </article>

        <CardSettings
          showTyping={showTyping}
          userId={userId}
          note={note}
          setEmojiReaction={setEmojiReaction}
          setComments={setComments}
          deleteNote={deleteNote}
          displayVote={displayVote}
          onAddVote={onAddVote}
          onRemoveVote={onRemoveVote}
          onSendAction={onSendAction}
          isAnonymous={isAnonymous}
          isVoting={isVoting}
          maskComment={maskComment}
          onEmojiSelect={(target, emoji) => updateTextChange(target, emoji, note)}
          onEmojiPicker={(e) => {
            setFocus(e, id, showTyping, maskComment);
            isPickingEmoji = true;
          }}
          closeEmojiPicker={() => (isPickingEmoji = false)}
          unGroup={unGroup}
        />
      </div>
    );
  };

  useEffect(() => {
    // !import to keep !localNote check for add missing notes
    if (isEqual(remoteNotes, notes) && !localNote) {
      return;
    }

    let missingIds = addedIds;
    const newNotes = remoteNotes
      .filter((remoteNote) => {
        //preserve deleted note
        if (deletedIds.includes(remoteNote.id)) {
          logger('remove from remote', remoteNote.id);
        }

        //update missing Ids
        if (addedIds.includes(remoteNote.id)) {
          missingIds = missingIds.filter((id) => id !== remoteNote.id);
        }
        return !deletedIds.includes(remoteNote.id);
      })
      .map((remoteNote) => {
        const editedNote = notes.filter((item) => item.id === remoteNote.id)[0];

        if (editedNote && editedNote.timeStamp > remoteNote.timeStamp) {
          //text could be different so this is still nessasary
          logger('local remote diff', editedNote, 'remote', remoteNote);
          return editedNote;
        }

        return remoteNote;
      });

    missingIds.forEach((id) => {
      const missingNote = notes.filter((item) => item.id === id)[0];
      if (missingNote) {
        if (remoteType === 'delete') {
          logger('Should remove missing from local before', 'addedIds', addedIds);
          addedIds = addedIds.filter((i) => i !== id);
          logger('Should remove missing from local after', 'addedIds', addedIds);
        } else {
          logger('Should remove missing', missingNote, newNotes, addedIds);
          missingNote && newNotes.push(missingNote);
        }
      }
    });

    setNotes(newNotes);
    setEditType('remote');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteNotes, remoteType]);

  useEffect(() => {
    if (localNote) return;

    //only send own update identified by hash
    const updatedNotesStatus = editingStatus.filter(
      (noteStatus) => !(noteStatus.isEditingBy === userId && noteStatus.hash === hash)
    );

    if (!isEqual(editingStatus, updatedNotesStatus)) {
      onSaveStatus({
        boardId,
        status: updatedNotesStatus
      });

      //Sentry.captureMessage(`Fix Board Typing by ${userId} / ${hash}: ${boardId}`);
    }
  }, [editingStatus, boardId, onSaveStatus, userId]);

  useEffect(() => {
    if (!isVoting) return;

    if (userExistingVotes) {
      const existingCount = getExistingVotesCount(userExistingVotes, notes);
      const remainingVotes = Math.max(maxVotes - existingCount, 0);
      setLocalVotes(userExistingVotes);
      setRemainingVotes(remainingVotes);
    } else {
      setRemainingVotes(maxVotes);
    }
  }, [isVoting, userExistingVotes, maxVotes, notes]);

  useEffect(() => {
    updateRemainingVotes(remainingVotes);
  }, [remainingVotes, updateRemainingVotes]);

  useEffect(() => {
    if (status === 'endVote') {
      setRemainingVotes(maxVotes);
      setLocalVotes({});
      setGridProps({ ...defaultGrid });
    }
  }, [status, defaultGrid, maxVotes]);

  useEffect(() => {
    if (isVoting) {
      saveEdited();
      setGridProps({ ...defaultGrid, isDraggable: false });
    }
  }, [isVoting, defaultGrid, saveEdited]);

  useEffect(() => {
    setGridProps({ ...defaultGrid });
  }, [defaultGrid]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      saveEdited();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [saveEdited]);

  useEffect(() => {
    if (editType === 'ungroup') {
      const ungroupedNotes = notes.filter((note) => note.lastEditedBy === 'ungroup');

      ungroupedNotes.forEach((note) => {
        const { id } = note;
        const textArea = document.querySelector(`[data-id="${id}"].react-grid-item textarea`);

        updateNoteHeight(textArea, note, notes);
      });
    }
  }, [editType, notes, updateNoteHeight]);

  useEffect(() => {
    if (editType === 'emoji') {
      const notesWithEmoji = notes.filter((note) => note.emojis?.length > 0);

      notesWithEmoji.forEach((note) => {
        const { id } = note;
        const textArea = document.querySelector(`[data-id="${id}"].react-grid-item textarea`);

        updateNoteHeight(textArea, note, notes);
      });
    }
  }, [editType, notes, updateNoteHeight]);

  useEffect(() => {
    if (sortBy === 'votes' && Object.keys(votes).length === 0) return;

    if (sortBy) {
      const currentNotes = [...notes];
      const newNotes = sortItems({ columns, currentNotes, sortBy, votes });

      setNotes(newNotes);
      setEditType('sort'); // editType: sort is important for forcing layout update
      setGridProps({ ...defaultGrid });

      setSortBy();
    }
  }, [columns, sortBy, setSortBy, defaultGrid, notes, votes]);

  return (
    <>
      <div className="board-columns-add-wrapper">
        <div className="board-columns">{boardColumnsAdd}</div>
      </div>
      <div id={`board-${boardId}`} className="boardContainer" style={{ transform: `scale(${scale})` }}>
        <div
          className={`react-stickies-wrapper react-stickies-wrapper-${boardId} clearfix`}
          style={{ width: `${maxViewport}px` }}
        >
          <ReactGridLayout
            {...gridProps}
            width={maxViewport}
            layout={getLayout(notes)}
            onLayoutChange={onLayoutChange}
            editType={editType}
            onDdClick={onDdClick}
            onDragStart={onDragStart}
            onDragStop={onDragEnd}
            onGroup={onGroup}
            onOverlap={onOverlap}
            bottomSpace={60}
            useCSSTransforms={true}
            preventCollision={false}
            isResizable={false}
            transformScale={scale}
          >
            {notes.map(renderNote)}
          </ReactGridLayout>
        </div>
      </div>

      <OnVotingProgress
        votingWarn={votingWarn}
        onCloseWarn={onCloseWarn}
        votingStates={votingStates}
        boardColumns={boardSettings}
      />
    </>
  );
}

export default React.memo(Board, (prev, next) => {
  return (
    isEqual(prev.remoteNotes, next.remoteNotes) &&
    isEqual(prev.votes, next.votes) &&
    isEqual(prev.votingStates, next.votingStates) &&
    isEqual(prev.user, next.user) &&
    isEqual(prev.boardSettings, next.boardSettings) &&
    isEqual(prev.remoteStatus, next.remoteStatus)
  );
});
