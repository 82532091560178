import React, { useState } from 'react';
import Button from 'components/CustomButtons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Modal from 'components/Modal/Modal';

import apiService from 'utils/apiService';

export default function Welcome() {
  const [welcomeModal, setWelcomeModal] = useState(true);
  const [checked, setChecked] = useState(true);

  const closeWelcomeModal = () => {
    setWelcomeModal(false);

    if (checked) {
      apiService.post(`/api/subscribing`).catch((e) => e);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Modal
      status={welcomeModal}
      maxWidth="sm"
      onClose={closeWelcomeModal}
      nonResponsive
      PaperProps={{
        style: {
          maxWidth: '646px'
        }
      }}
    >
      <div className="welcomeModal" style={{ position: 'relative' }}>
        <div className="clouds" style={{ opacity: 0.5 }}>
          <div
            className="cloud cloud-small cloud-pink"
            style={{
              right: '-66px',
              top: '118px'
            }}
          ></div>
        </div>
        <div className="clouds" style={{ opacity: 0.5 }}>
          <div
            className="cloud cloud-1 cloud-pink"
            style={{
              left: '-70px',
              bottom: '48px'
            }}
          ></div>
        </div>
        <h2>
          <span role="img" aria-label="Hi">
            👋
          </span>{' '}
          Welcome aboard!
        </h2>
        <p style={{ margin: '32px 0 12px 0' }}>We’re delighted to have you here! Your team space is up and running.</p>
        <p style={{ margin: '0 0 12px 0' }}> Here are a few things you might like to try:</p>
        <ul className="welcomeModal--list">
          <li>
            <span role="img" aria-label="">
              🚀
            </span>{' '}
            Start your first retro with your team
          </li>
          <li>
            <span role="img" aria-label="">
              🎵
            </span>{' '}
            Play music during your retro with Icebreakers
          </li>
          <li>
            <span role="img" aria-label="">
              🤖
            </span>{' '}
            Create your own format with Retro Bot
          </li>
          <li>
            <span role="img" aria-label="">
              🏞
            </span>{' '}
            Add a custom background to your retro board
          </li>
          <li>
            <span role="img" aria-label="">
              🔮
            </span>{' '}
            Discover trends with retro insights
          </li>
          <li>
            <span role="img" aria-label="">
              🌟
            </span>{' '}
            Invite team members or assign facilitators
          </li>
        </ul>

        <FormControlLabel
          value="start"
          control={<Checkbox color="primary" checked={checked} onChange={handleChange} />}
          label="Keep me informed about future product updates"
          labelPlacement="start"
        />

        <div style={{ margin: '12px 0 0 0' }}>
          <Button onClick={closeWelcomeModal}>Start exploring</Button>
        </div>
      </div>
    </Modal>
  );
}
