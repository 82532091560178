import { useLocation } from 'react-router-dom';

const useURLSearchParams = (search) => {
  try {
    return new URLSearchParams(search);
  } catch (e) {
    return {
      get: () => null
    };
  }
};

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return useURLSearchParams(useLocation().search);
};

export default useQuery;
