import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import Log from 'utils/Log';
import apiService from 'utils/apiService';
import Features from './Features';

import { GithubCounter } from 'react-reactions/src';
import { getMoodColour } from 'utils/misc';

import { ReactComponent as ExternalIcon } from 'images/icons/external.svg';

import MoodBar from 'modules/MoodBar';

const getRelativeValue = (value, max) => {
  if (!max) {
    return 0;
  }
  return Number.parseFloat(value / max).toFixed(2) * 100;
};

const labelToDataMap = {
  moodScore: 'Mood',
  peopleCount: 'People',
  cardsCount: 'Notes',
  votesCount: 'Votes',
  emojiCount: 'Emoji'
};

const DEFAULT_LIMIT = '12';

export default function Insights({ teamId, canCreateBoard }) {
  const [currentKey, setCurrentKey] = useState('moodScore');
  const [formattedData, setFormattedData] = useState();
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [loading, setLoading] = useState();
  //const [error, setError] = useState();
  let data = (formattedData && formattedData[currentKey]) || [];

  const handleChange = (e, key) => {
    setCurrentKey(key);
    Log('Click', `insights ${key}`);
  };

  const onChangeLimit = (e) => {
    const newLimit = e.target?.value;
    setLimit(newLimit);
    Log('Click', `insights limit ${newLimit}`);
  };

  const getTooltip = (value, index) => {
    const renderTooltipContent = () => {
      if (currentKey === 'cardsCount') {
        const { cardsByColumns } = formattedData.retro[index];
        return (
          <div className="graphContainer-item--content graphContainer-item--content--columns">
            <h3>
              {value} {labelToDataMap[currentKey]}
            </h3>
            {cardsByColumns.map(({ name, count }, index) =>
              name || count ? (
                <span key={`cards-by-column-notes-${index}`}>
                  {name?.trim()}: <b> {count}</b>
                </span>
              ) : null
            )}
          </div>
        );
      }

      if (currentKey === 'peopleCount') {
        const { contribution } = formattedData.retro[index];
        const renderLabel = (index) => {
          if (index === 0) {
            return '3 or more notes';
          }

          if (index === 1) {
            return '2 notes';
          }

          if (index === 2) {
            return '1 note';
          }
        };
        return (
          <div className="graphContainer-item--content">
            <h3 style={{ marginBottom: '24px' }}>
              {value} {labelToDataMap[currentKey]} <span>Number of people who contributed</span>
            </h3>
            <h3 style={{ marginBottom: '4px' }}>People who added:</h3>
            {Array.isArray(contribution) &&
              contribution.reverse().map((item, index) =>
                item ? (
                  <span key={`cards-by-column-people-${index}`}>
                    {renderLabel(index)}: <b>{`${item.percentage}%`}</b>
                    {item.count ? ` ${item.count}` : null}
                  </span>
                ) : null
              )}
          </div>
        );
      }

      if (currentKey === 'votesCount') {
        const { topVotedCards } = formattedData.retro[index];
        return (
          <div className="graphContainer-item--content">
            <h3>
              {value} {labelToDataMap[currentKey]}
            </h3>
            {value && <h4>Top feedback by votes</h4>}

            {topVotedCards.map(({ id, text, votes }, index) => {
              return votes ? (
                <div className="cardOverview" key={`cards-by-column-votes-${index}`}>
                  <p className="cardContent">{text}</p>
                  <div>
                    <GithubCounter votesCount={<span className="cardVotesCount">{votes}</span>} />
                  </div>
                </div>
              ) : null;
            })}
          </div>
        );
      }

      if (currentKey === 'emojiCount') {
        const { topReactedCards } = formattedData.retro[index];
        return (
          <div className="graphContainer-item--content">
            <h3>
              {value} {labelToDataMap[currentKey]}
            </h3>
            {value && <h4>Top feedback by emoji</h4>}
            {topReactedCards.map(({ text, emojis }, index) => {
              return emojis && emojis.length > 0 ? (
                <div className="cardOverview" key={`cards-by-column-emoji-${index}`}>
                  <p className="cardContent">{text}</p>
                  <div>
                    <GithubCounter counters={emojis} />
                  </div>
                </div>
              ) : null;
            })}
          </div>
        );
      }

      if (currentKey === 'moodScore') {
        const { id } = formattedData.retro[index];

        return (
          <div className="graphContainer-item--content graphContainer-item--content--columns">
            <h3>{labelToDataMap[currentKey]}</h3>
            <div style={{ height: '236px' }}>
              <MoodBar id={id} teamId={teamId} />
            </div>
          </div>
        );
      }

      return (
        <div className="graphContainer-item--content">
          {value} {labelToDataMap[currentKey]}
        </div>
      );
    };

    return <div className="graphContainer-item--tooltip">{renderTooltipContent()}</div>;
  };

  const getRetroTooltip = (retro) => {
    const { id, name, timestamp } = retro;
    return (
      <div className="graphContainer-item--tooltip">
        <h3>{name}</h3>
        <div className="graphContainer-item--content">
          <span>{timestamp && format(timestamp, 'PPP')}</span>

          <a href={`/board/${id}`} target="_blank" rel="noopener noreferrer">
            Open this Retro <ExternalIcon />
          </a>
        </div>
      </div>
    );
  };

  const renderGraph = (value, index) => {
    const relativeValue = getRelativeValue(value, Math.max(...data));
    const retro = formattedData.retro[index];
    const color = currentKey === 'moodScore' && value === 0 ? 'transparent' : 'inherit';
    let backgroundColor;

    if (currentKey === 'moodScore') {
      backgroundColor = getMoodColour(value);
    }

    return (
      <div
        key={`graph-${index}`}
        style={{ height: `calc(${relativeValue}% + 40px + 20px)` }}
        className="graphContainer-item"
      >
        {value === null ? (
          <span className="graphContainer-item--placeholder"></span>
        ) : value ? (
          <Tooltip
            title={getTooltip(value, index)}
            placement="top"
            enterTouchDelay={20}
            leaveTouchDelay={5000}
            disableFocusListener
            interactive
          >
            <span className=" graphContainer-item--interactive">
              <span className="graphContainer-item--dot" style={{ backgroundColor }}></span>
            </span>
          </Tooltip>
        ) : (
          <span className=" graphContainer-item--noninteractive">
            <span className="graphContainer-item--dot"></span>
          </span>
        )}

        <span className="graphContainer-item" style={{ color }}>
          {value}{' '}
          {retro ? (
            <Tooltip
              title={getRetroTooltip(retro)}
              placement="top"
              enterTouchDelay={20}
              leaveTouchDelay={5000}
              disableFocusListener
              interactive
            >
              <span className="graphContainer-item--interactive">
                <span className="graphContainer-item--info"></span>
              </span>
            </Tooltip>
          ) : (
            <span className="graphContainer-item--noninteractive">
              <span className="graphContainer-item--info"></span>
            </span>
          )}
        </span>
      </div>
    );
  };

  const renderLabel = (key) => {
    return (
      <button
        key={`graph-key-${key}`}
        className={`${currentKey === key ? 'graphGroups-group-selected' : ''}`}
        onClick={(e) => handleChange(e, key)}
      >
        {labelToDataMap[key]}
      </button>
    );
  };

  const renderResults = () => {
    const validData = data.filter((data) => data !== null);
    if (validData.length > 0) {
      return (
        <div style={{}}>
          <FormControl variant="outlined" style={{ position: 'absolute', top: '54px', left: '20px' }}>
            <Select style={{ fontSize: '14px' }} value={limit} onChange={onChangeLimit}>
              <MenuItem style={{ fontSize: '14px' }} value="12">
                Last 12 Retros
              </MenuItem>
              <MenuItem style={{ fontSize: '14px' }} value="24">
                Last 24 Retros
              </MenuItem>
              <MenuItem style={{ fontSize: '14px' }} value={false}>
                All Time
              </MenuItem>
            </Select>
          </FormControl>
          <div
            className="graphContainer"
            style={{ justifyContent: limit === DEFAULT_LIMIT || loading ? 'space-around' : 'flex-start' }}
          >
            {data.map(renderGraph)}
          </div>

          <div className="graphGroups">
            {Object.keys(labelToDataMap).map((key) => {
              return renderLabel(key);
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '112px' }}>
          <p>
            Discover insights and explore trends over time{' '}
            <span role="img" aria-label="">
              📈
            </span>
          </p>
          {
            <Button href={`/team/${teamId}/board/create`} disabled={!canCreateBoard}>
              <div>Start a retro</div>
            </Button>
          }
        </div>
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    apiService
      .get(`/api/team/${teamId}/insights`, {
        params: {
          limit
        }
      })
      .then(({ data }) => {
        const insightsData = {};
        Object.keys(labelToDataMap).forEach((key) => {
          insightsData[key] = data.map((d) => d && d[key]);
        });

        insightsData.retro = data.map((d) => d && d['retro']);

        setFormattedData(insightsData);
        setLoading(false);
      })
      .catch((e) => {
        //setError(true);
        setLoading(false);
      });
  }, [teamId, limit]);

  return (
    <Grid container spacing={3}>
      <Grid item md={8} sm={12} xs={12}>
        <Paper>
          <div className="teamDashboard-grid">
            <h2>Retro Insights</h2>

            {formattedData ? (
              renderResults()
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginTop: '120px',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <CircularProgress size={20} />
              </div>
            )}
          </div>
        </Paper>
      </Grid>

      <Grid item md={4} sm={12} xs={12}>
        <Paper>
          <div className="teamDashboard-grid">
            <h2>Next Retro</h2>
            <Features canCreateBoard={canCreateBoard} teamId={teamId} />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
