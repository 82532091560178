import React from 'react';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import pick from 'lodash/pick';

import GithubCounterGroup from './GithubCounterGroup';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const MAX_DISPLAY_COUNT = 40; //max 8 rows of emojis (5 per column)

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '8px',
    color: '#000',
    boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2)'
  },
  moreIcon: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    cursor: 'default',
    margin: '6px 0',
    padding: '2px 4px',
    alignSelf: 'center',
    fontSize: '14px',
    fontWeight: '600',
    '&:hover': {
      background: 'rgba(220, 220, 220, 0.6)'
    }
  }
}));

export const GithubCounter = ({ counters, user, onSelect, votesCount, showProfile }) => {
  const classes = useStyles();
  const groups = groupBy(counters, 'emoji');
  const emojis = Object.keys(groups);
  const mainGroup = pick(groups, emojis.slice(0, MAX_DISPLAY_COUNT));
  const extraGroup = pick(groups, emojis.slice(MAX_DISPLAY_COUNT, emojis.length));
  const extraGroupLength = Object.keys(extraGroup).length;

  const renderEmojiCounterGroups = (groups) => {
    return map(groups, (c, emoji) => {
      const names = map(c, 'by');
      return (
        <GithubCounterGroup
          key={emoji}
          emoji={emoji}
          count={c.length}
          names={names}
          active={includes(names, user)}
          onSelect={onSelect}
          showProfile={showProfile}
        />
      );
    });
  };

  if (!counters && !votesCount) return null;

  //Important! 24px margin = note size 228 = 24 x 2+ 36 x 5 (emojis)
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: '0 24px' }}>
      {votesCount}
      {renderEmojiCounterGroups(mainGroup)}

      {extraGroupLength > 0 && (
        <Tooltip
          title={<>{renderEmojiCounterGroups(extraGroup)}</>}
          classes={{ tooltip: classes.tooltip }}
          placement="top"
          interactive
        >
          <span className={classes.moreIcon}>...</span>
        </Tooltip>
      )}
    </div>
  );
};

export default GithubCounter;
