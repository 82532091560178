import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    if (
      error.name === 'NotFoundError' &&
      error.message &&
      error.message.includes('The node to be removed is not a child of this node')
    ) {
      Sentry.captureMessage('Force reload unhandled error');
      window.location.reload();
      return {};
    }

    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          Oops, something went wrong. Please refresh the page.
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
