import React, { useEffect, useRef, useState } from 'react';
import Truncate from 'react-truncate';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import { getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '30px',
    height: '30px',
    fontSize: '0.8rem'
  }
}));

export default function Users({ users }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userBadge, setUserBadge] = useState(null);
  const panelEl = useRef(null);
  const usersCount = Object.keys(users).length;

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderUsers = () => {
    let anonymousUsersCount = 0;
    const logedInUsers = Object.keys(users).map((id) => {
      const user = users[id].user;
      const { picture, anonymous } = user || {};
      const displayName = getDisplayName(user);

      if (anonymous) {
        anonymousUsersCount += 1;
        return null;
      }

      return (
        <li key={`user-list-${id}`}>
          <div className="userList">
            <div className="userList-info">
              <span>
                <Truncate width={266}>{displayName}</Truncate>
              </span>
            </div>
            <Avatar title={displayName} alt={displayName} src={picture} className={classes.small} />
          </div>
        </li>
      );
    });

    const anonymousUsers =
      anonymousUsersCount > 0 ? (
        <li key="user-list-anonymous">
          <div className="userList">
            <div className="userList-info">
              <span>anonymous</span>
              <span></span>
            </div>
            <Avatar alt="anonymous" className={classes.small}>
              +{anonymousUsersCount}
            </Avatar>
          </div>
        </li>
      ) : null;

    return (
      <>
        {logedInUsers}
        {anonymousUsers}
      </>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setUserBadge(usersCount);
    }, 300);
    return () => clearTimeout(timer);
  }, [usersCount]);

  return (
    <div className="users" onMouseOver={handleOpen} onMouseLeave={handleClose}>
      <div ref={panelEl} className="actionPanel">
        <Badge badgeContent={userBadge} color="primary" overlap="rectangular">
          Users
        </Badge>
      </div>

      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-users">
                  <h3>{`${usersCount} ${usersCount > 1 ? 'users' : 'user'} online`}</h3>
                  <ul>{renderUsers()}</ul>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
