const rules = {
  member: ['board:read'],
  facilitator: ['board:read', 'board:edit', 'board:create', 'board:delete'],
  admin: ['board:read', 'board:edit', 'board:create', 'board:delete', 'member:edit', 'team:edit'],
  'org-admin': [
    'board:read',
    'board:edit',
    'board:create',
    'board:delete',
    'member:edit',
    'team:edit',
    'org-team:edit'
  ],
  owner: [
    'board:read',
    'board:edit',
    'board:create',
    'board:delete',
    'member:edit',
    'team:edit',
    'org-team:edit',
    'billing:edit',
    'org:edit'
  ]
};

export const getRole = ({ orgId, teamId, userMeta = {} }) => {
  const { role: teamRole } =
    (Array.isArray(userMeta.teams) && userMeta.teams.filter((team) => team.id === teamId)[0]) || {};
  const { role: orgRole } =
    (Array.isArray(userMeta.orgs) && userMeta.orgs.filter((organization) => organization.id === orgId)[0]) || {};

  return {
    role: orgRole || teamRole,
    isOrgRole: Boolean(orgRole)
  };
};

export const checkPermission = (role, action, isOrgRole) => {
  let roleName = role;

  if (role === 'admin' && isOrgRole) {
    roleName = 'org-admin';
  }
  const permissions = rules[roleName];
  if (!permissions) return false;

  if (permissions && permissions.includes(action)) {
    return true;
  }
  return false;
};

export const findRole = (id, members = []) => {
  const user = members.filter((member) => member.id === id)[0] || {};
  return user.role;
};
