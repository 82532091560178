import React, { useState, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import add from 'date-fns/add';
import * as Sentry from '@sentry/browser';

import Helper from 'dashboard/Helper';

const Countdown = ({ expiryTimestamp, setIsOnTimer }) => {
  const [valid, setValid] = useState(true);
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => setValid(false)
  });

  let formattedMinutes;

  try {
    formattedMinutes = seconds.toString().padStart(2, '0');
  } catch (e) {
    Sentry.captureException(e);
  }

  useEffect(() => {
    if (!valid) {
      setIsOnTimer(false);
    }
  }, [valid, setIsOnTimer]);

  if (!valid) {
    return null;
  }

  return (
    <p>
      <span className="timeElement timeElement-mins">{minutes}</span>:
      <span className="timeElement">{formattedMinutes}</span>
      {minutes < 1 && seconds < 15 && <Helper message="⌛️ Time is almost up!" autoHideDuration={12000} />}
    </p>
  );
};

const Time = ({ timerStates, isOnTimer, setIsOnTimer }) => {
  const { startTime, duration } = timerStates;
  const renderTimerView = () => {
    const currentTime = new Date();
    const expiryTime = add(new Date(startTime), {
      minutes: duration
    });

    if (currentTime > expiryTime) {
      return null;
    }

    return (
      <div className="timerProgressBar">
        <Countdown expiryTimestamp={expiryTime} setIsOnTimer={setIsOnTimer} />
      </div>
    );
  };

  useEffect(() => {
    if (startTime && duration) {
      const currentTime = new Date();
      const expiryTime = add(new Date(startTime), {
        minutes: duration
      });
      setIsOnTimer(Boolean(currentTime < expiryTime));
    } else {
      setIsOnTimer(false);
    }
  }, [startTime, duration, setIsOnTimer]);

  return <>{isOnTimer && startTime && duration && renderTimerView()}</>;
};

export default Time;
