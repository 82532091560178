import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';

import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import apiService from 'utils/apiService';
import NotificationBar from 'modules/NotificationBar';
import { getRoleDisplayName } from 'utils/misc';
import Log from 'utils/Log';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '30px',
    height: '30px',
    fontSize: '0.8rem',
    marginLeft: '80%'
  },
  role: {
    minWidth: '100px',
    display: 'inline-block'
  }
}));

export default function InvitedMembers({ orgId, invite, getOrgMembers, canEditMember }) {
  const classes = useStyles();
  const { email, role, inviteToken } = invite;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [removeModal, setRemoveModal] = useState(false);
  const [copied, setCopied] = useState();
  const [error, setError] = useState();

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const onCopy = (v) => {
    setCopied(true);
    Log('Copy', 'org invite');
  };

  const getInviteLink = () => {
    return `https://${window.location.hostname}/invite/org/${orgId}/${inviteToken}`;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    apiService
      .delete(`/api/org/${orgId}/invite`, {
        data: { email }
      })
      .then(() => {
        getOrgMembers();
        onRemoveModal();
      })
      .catch((e) => {
        setError(true);
      });
  };
  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Avatar alt={email} src={null} className={classes.small} />
        </TableCell>
        <TableCell>
          <div style={{}}>
            <span
              style={{
                fontSize: '14px',
                color: 'white',
                backgroundColor: '#97A3FF',
                borderRadius: '8px',
                padding: '8px'
              }}
            >
              pending
            </span>
          </div>
        </TableCell>
        <TableCell>
          <div style={{ opacity: '0.7' }}>{email}</div>
        </TableCell>
        <TableCell>
          <span className={classes.role} style={{ opacity: '0.7' }}>
            {getRoleDisplayName({ role, isOrgRole: true })}
          </span>{' '}
          {canEditMember && (
            <>
              <IconButton aria-label="settings" onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow">
                    <MenuItem>
                      <CopyToClipboard text={getInviteLink()} onCopy={onCopy}>
                        <span>Copy invite link</span>
                      </CopyToClipboard>
                    </MenuItem>
                    <MenuItem onClick={onRemoveModal}>Cancel invite</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Popover>
            </>
          )}
        </TableCell>
      </TableRow>

      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive keepMounted={false}>
        <div id="org-user-modal" className="modalContainer">
          <h2>
            Cancel invite{' '}
            <span role="img" aria-label="">
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{`(${email})`}</p>
          <p>Are you sure you want to cancel this invite?</p>
          {/* <p>They will no longer be able to join your organization.</p> */}

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button onClick={onDelete}>Confirm</Button>
          </div>
        </div>
      </Modal>
      {error && (
        <NotificationBar
          message="Sorry something went wrong."
          type="error"
          closeCallback={() => setError()}
          rootId="org-user-modal"
        />
      )}
      {copied && <NotificationBar message="📋 Invite link copied" type="info" closeCallback={() => setCopied()} />}
    </>
  );
}
