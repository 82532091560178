import React, { useState } from 'react';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import NotificationBar from './NotificationBar';

import { MoodSelector } from '../react-reactions/src';

import apiService from 'utils/apiService';

export default function OnPolling({
  id,
  teamId,
  uid,
  editPolling,
  isPolling,
  selectedValue,
  setSelectedValue,
  onClose
}) {
  const [updatedValue, setUpdatedValue] = useState(selectedValue);
  const [error, setError] = useState();

  const onSelect = (value) => {
    setUpdatedValue(value);
  };

  const onSubmit = () => {
    error && setError();

    apiService
      .post(`/api/team/${teamId}/board/${id}/polls`, {
        mood: {
          uid,
          value: updatedValue
        }
      })
      .then(() => {
        setSelectedValue(updatedValue);
        onClose();
      })
      .catch((e) => {
        setError(true);
        onClose();
      });
  };

  const handleClose = () => {
    onClose();
    setUpdatedValue(selectedValue);
  };

  return (
    <Modal status={isPolling} maxWidth="sm" nonResponsive onClose={editPolling ? handleClose : null}>
      <div className="welcomeModal">
        <h2>
          {editPolling ? (
            'Change your mood'
          ) : (
            <div style={{ marginTop: '18px' }}>
              Welcome{' '}
              <span role="img" aria-label="">
                👋
              </span>
            </div>
          )}
        </h2>

        <p>How have you been feeling about work lately?</p>
        <div className="moodPollGrid">
          <MoodSelector onSelect={onSelect} selectedValue={updatedValue} />
        </div>
        <p style={{ margin: '28px 0 0' }}>
          <Button onClick={onSubmit} disabled={!updatedValue}>
            Confirm
          </Button>
        </p>
      </div>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </Modal>
  );
}
