import React, { useState, useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import Truncate from 'react-truncate';
import * as Sentry from '@sentry/browser';

// We don't want to use apiService to handle unsplash unauthorized errors
import axios from 'axios';

import Log from 'utils/Log';
import BackgroundSelector from 'dashboard/BackgroundSelector';

import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { validateURL } from 'utils/misc';

const Loader = () => {
  const size = useMediaQuery('(max-width: 480px)') ? 100 : 150;

  return (
    <div className="image-grid">
      {Array.from(Array(9).keys()).map((i) => (
        <Skeleton key={`image-loader-${i}`} variant="circle" width={size} height={size} />
      ))}
    </div>
  );
};

export default function ImageSearch({ updateBackground, setBackgroundUrl }) {
  const [query, setQuery] = useState('');
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getImages = useCallback(
    (query, options = {}) => {
      const { next } = options;
      const currentPage = next ? page + 1 : 1;

      if (!query || (page === 0 && next)) return;

      axios
        .get(
          `/unsplash/search/photos?&query=${query}` +
            `&page=${currentPage}` +
            `&per_page=24&content_filter=high&orientation=landscape`
        )
        .then(({ data }) => {
          const { results } = data;
          if (results.length === 0) {
            setHasMore(false);
          } else {
            setImages([...images, ...results]);
            setHasMore(true);
          }

          setPage(currentPage);
        })
        .catch((e) => {
          Sentry.captureException(e);
          return e;
        });
    },
    [images, page]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (value) => {
          getImages(value);

          Log('Search', `image - ${value}`);
        },
        500,
        { leading: false }
      ),
    [getImages]
  );

  const handleOptionChange = (url, downloadLocation) => {
    setBackgroundUrl(url);

    // trigger a download event
    if (validateURL(downloadLocation)) {
      axios.get(downloadLocation.replace('https://api.unsplash.com/', '/unsplash')).catch((e) => e);
    }
  };

  const renderResults = () => {
    return (
      <InfiniteScroll
        dataLength={images.length}
        next={() => {
          getImages(query, { next: true });
        }}
        hasMore={hasMore}
        loader={<Loader />}
        height={380}
      >
        <div className="image-grid image-grid--withLink">
          {images.map(({ id, urls, links, user }) => {
            const splashUrl = `${user?.links?.html}?utm_source=teleretro&utm_medium=referral`;

            return (
              <div key={`theme-tile-${id}`} className="image-grid--col">
                <div className="image-grid--image-wrapper">
                  <img
                    onClick={(e) => handleOptionChange(urls?.regular, links?.download_location)}
                    src={urls.thumb}
                    alt={`theme ${id}`}
                  />
                </div>

                <a
                  href={validateURL(splashUrl) ? splashUrl : 'https://unsplash.com'}
                  title={user?.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Truncate width={150}>{user?.name}</Truncate>
                </a>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    setImages([]);
    setPage(0);

    if (value) {
      debouncedSearch(value);
    }
  };

  return (
    <>
      <div className="actions-container-input" style={{ display: 'flex', alignItems: 'center', margin: '12px auto 0' }}>
        <TextField
          value={query}
          variant="outlined"
          onChange={onSearch}
          placeholder="Enter a search term"
          FormHelperTextProps={{
            className: 'search-field-helper'
          }}
        />
      </div>
      {query ? renderResults() : <BackgroundSelector updateBackground={updateBackground} />}
    </>
  );
}
