import React, { useState, useRef } from 'react';
import Log from 'utils/Log';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/CustomButtons/Button';
import Badge from '@material-ui/core/Badge';

const Sorting = ({ setSortBy, votingStates }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);
  const { status } = votingStates;
  const isVoting = Boolean(status === 'vote');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleOnSortBy = (type) => {
    setSortBy(type);
    Log('Sort', type);
  };

  return (
    <div className="sortingPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <Badge badgeContent={null} color="primary" overlap="rectangular">
          Sorting
        </Badge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Sorting</h3>
                  <p>Move notes with most votes or emoji to the top of each column</p>
                  <div>
                    <Tooltip placement="top" title={isVoting ? 'Sorting available when voting ends' : ''}>
                      <div style={{ margin: '4px', display: 'flex', justifyContent: 'center' }}>
                        <Button link onClick={() => handleOnSortBy('votes')} disabled={isVoting}>
                          Sort by Votes
                        </Button>
                      </div>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip placement="top" title={isVoting ? 'Sorting available when voting ends' : ''}>
                      <div style={{ margin: '4px', display: 'flex', justifyContent: 'center' }}>
                        <Button link onClick={() => handleOnSortBy('emoji')} disabled={isVoting}>
                          Sort by Emoji
                        </Button>
                      </div>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip placement="top" title={isVoting ? 'Sorting available when voting ends' : ''}>
                      <div style={{ margin: '4px', display: 'flex', justifyContent: 'center' }}>
                        <Button link onClick={() => handleOnSortBy('comments')} disabled={isVoting}>
                          Sort by Comments
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Sorting;
