import React from 'react';

import Button from 'components/CustomButtons/Button';

import Modal from 'components/Modal/Modal';
import votingImg from 'images/img/voting.png';

export default function OnVotingProgress({ votingWarn, onCloseWarn, votingStates, boardColumns }) {
  return (
    <Modal status={votingWarn} onClose={onCloseWarn} maxWidth="sm" nonResponsive>
      <div className="welcomeModal">
        <h2>
          <span role="img" aria-label="">
            🗳
          </span>{' '}
          Voting in Progress
        </h2>
        {!boardColumns.compact && boardColumns.columns && (
          <p>
            Please go to the <strong>‘{boardColumns.columns[votingStates.contentId]}’</strong> tab where a voting
            session is under way.
          </p>
        )}

        <p>
          <img className="modal-voting-img" src={votingImg} alt="" />
        </p>
        <p>
          <strong>Click</strong> on a note to add or remove votes
        </p>

        <p style={{ margin: '0' }}>
          <Button onClick={onCloseWarn}>Ok</Button>
        </p>
      </div>
    </Modal>
  );
}
