import * as Sentry from '@sentry/browser';

import startCase from 'lodash/startCase';
import add from 'date-fns/add';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { MOODS } from './constant';

const validateURL = (url) => {
  const parsed = new URL(url);
  return ['https:', 'http:'].includes(parsed.protocol);
};

const trimRight = (a) => {
  return a.reduceRight((result, next) => {
    return [...(!result.length && !next ? [] : [next]), ...result];
  }, []);
};

const isTouchScreen = () => {
  return Boolean('ontouchstart' in window || navigator.maxTouchPoints);
};

const moveCursor = ({ targetElem, cursor }) => {
  if (!targetElem || !cursor) return;

  const diff = 6;
  const { offsetWidth, offsetHeight } = targetElem;
  const style = window.getComputedStyle(targetElem);

  let eX;
  let eY;

  try {
    // not quit browser compatable
    var matrix = new DOMMatrix(style.transform);

    eX = matrix.m41;
    eY = matrix.m42;
  } catch (e) {
    const transform = style.transform || style.webkitTransform;
    const mat = transform.match(/^matrix\((.+)\)$/);

    eX = parseInt(mat[1].split(', ')[4], 10);
    eY = parseInt(mat[1].split(', ')[5], 10);

    Sentry.captureException(e);
  }

  cursor.style.left = eX - diff + 'px';
  cursor.style.top = eY - diff + 'px';
  cursor.style.width = offsetWidth + diff * 2 + 'px';
  cursor.style.height = offsetHeight + diff * 2 + 'px';
  cursor.style.borderRadius = '10px';
  cursor.style.opacity = '1';
};

const flatArray = (arr, depth = 1) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten);
  }, []);
};

const mergeUsers = (groupA, groupB) => {
  return [...new Set([...groupA.filter((userId) => userId), ...groupB.filter((userId) => userId)])];
};

const isSpotify = (src) => src.includes('https://open.spotify.com');
const toSpotifyEmbed = (src) => src.replace('open.spotify.com', 'open.spotify.com/embed');

const getArrayByKey = (arr, key) => {
  return Array.isArray(arr) ? arr.reduce((result, obj) => [...result, obj[key]], []) : [];
};

const getMoodEmoji = (score) => {
  const index = Math.round(score) - 1;
  return MOODS.reactions[index];
};

const getMoodColour = (score) => {
  const index = Math.round(score) - 1;
  return MOODS.colours[index];
};

const getIfMoodPollStarted = (retroStates, timestamp) => {
  const { moodPoll } = retroStates || {};
  let moodPollStarted = Boolean(moodPoll);

  const cutOffTime = new Date('2022-03-26T00:00:00').getTime();

  if (retroStates && moodPoll === undefined && timestamp > cutOffTime) {
    moodPollStarted = true;
  }

  return moodPollStarted;
};

const getTeamLink = ({ teamId, menu }) => {
  if (!teamId) {
    return '/';
  }

  if (!menu) {
    return `/dashboard/team/${teamId}`;
  }

  if (menu.toLowerCase() === 'dashboard') {
    return `/dashboard/team/${teamId}`;
  }

  return `/dashboard/team/${teamId}/${menu.toLowerCase()}`;
};

const getRoleDisplayName = ({ role, isOrgRole }) => {
  if (role === 'admin') {
    return startCase(isOrgRole ? 'org admin' : 'team admin');
  }

  return startCase(role);
};

const validateEmails = (emails) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (let i = 0; i < emails.length; i++) {
    const email = emails[i];

    if (!re.test(String(email).toLowerCase())) {
      return false;
    }
  }

  return true;
};

const sortRole = (a, b) => {
  const order = ['owner', 'admin', 'facilitator', 'member'];

  if (order.indexOf(a.role) < order.indexOf(b.role)) {
    return -1;
  }

  if (order.indexOf(a.role) > order.indexOf(b.role)) {
    return 1;
  }

  if (a.isOrgRole && !b.isOrgRole) {
    return -1;
  }
  if (!a.isOrgRole && b.isOrgRole) {
    return 1;
  }

  return 0;
};

const getDisplayName = (profile = {}) => {
  const { name, nickname, given_name, family_name } = profile;
  const fullName = given_name && family_name ? `${given_name} ${family_name}` : undefined;

  if (name && name === fullName) {
    return name;
  }

  if (name?.includes('@') && fullName) {
    return fullName;
  }

  if (fullName) {
    return fullName;
  }

  return name || nickname;
};

const getBoardRemainingDays = (timestamp) => {
  const currentDate = new Date();

  const expiryDate = add(new Date(timestamp), {
    days: 14
  });

  const remainingDays = differenceInCalendarDays(expiryDate, currentDate);

  return remainingDays;
};

const getFormatUrl = (name = '') => {
  return name
    .replace(/ /g, '-')
    .replace('-&-', '-')
    .replace('Santa’s', 'Santa')
    .replace('Fool’s', 'Fools')
    .toLowerCase();
};

const sortByAverageValue = ({ data, range }) => {
  // Note: very important to use [...] here as sort update array in place

  return [
    ...(data || []).sort((a, b) => {
      if (range === 0) {
        //Sort by time
        return b?.timestamp - a?.timestamp;
      }

      // Note: this is more like highest value 5 + 1 and lowest value - 1
      const defaultValue = range > 0 ? 6 : 0;
      return ((a?.averageValue || defaultValue) - (b?.averageValue || defaultValue)) * range;
    })
  ];
};

export {
  validateURL,
  trimRight,
  isTouchScreen,
  moveCursor,
  flatArray,
  mergeUsers,
  isSpotify,
  toSpotifyEmbed,
  getArrayByKey,
  getMoodEmoji,
  getMoodColour,
  getIfMoodPollStarted,
  getTeamLink,
  getRoleDisplayName,
  validateEmails,
  sortRole,
  getDisplayName,
  getBoardRemainingDays,
  getFormatUrl,
  sortByAverageValue
};
