import React, { useRef, useState } from 'react';
import Truncate from 'react-truncate';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Avatar from '@material-ui/core/Avatar';

import { APP_ENDPOINT } from 'config';
import { getDisplayName } from 'utils/misc';

export default function Profile({ profile, dashboardLink }) {
  const { picture, email } = profile || {};
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);

  const displayName = getDisplayName(profile);

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!profile) {
    return null;
  }

  return (
    <div className="profile" onMouseOver={handleOpen} onMouseLeave={handleClose}>
      <Avatar ref={panelEl} className="profileImg" alt={displayName} src={picture} />
      <div className="actionPanelList">
        {/** Important: this popper has no disablePortal, as it causing overflow in safari **/}
        <Popper style={{ zIndex: 1 }} anchorEl={panelEl.current} open={open} placement="bottom" transition>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-profile">
                  <h3 onMouseOver={handleOpen}>
                    <Truncate width={300}>{displayName === email ? 'Profile' : displayName}</Truncate>
                  </h3>
                  <p>
                    <Truncate width={300}>{email}</Truncate>
                  </p>
                  <a className="text-button" href={dashboardLink || '/'}>
                    Dashboard
                  </a>
                  <a className="text-button text-button-logout" href={`${APP_ENDPOINT}/logout`}>
                    Sign out
                  </a>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
