import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';

import Log from 'utils/Log';
import AppContext from 'AppContext';
import { getRole, checkPermission } from 'roles/checkPermission';

const PulseCta = ({ isTeam, callback, isSimple }) => {
  const history = useHistory();
  const { org, paymentMeta, userMeta } = useContext(AppContext);

  const { id: orgId } = org || {};
  const { role } = getRole({ orgId, userMeta });
  const canEditOrgTeam = checkPermission(role, 'org-team:edit', true);

  const goToPulse = () => {
    if (callback) {
      callback();
      Log('Pulse', 'Setup');
    } else {
      history.push('/pulse');
      Log('Pulse', 'View setup');
    }
  };

  const renderCta = () => {
    if (paymentMeta === undefined && userMeta === undefined) return null;

    if (canEditOrgTeam) {
      return (
        <>
          {isSimple ? (
            <span className="text-button" onClick={goToPulse}>
              + Create a Pulse Survey
            </span>
          ) : (
            <Button color="dark" onClick={goToPulse}>
              {`Setup Pulse Survey${isTeam ? ' in Org Dashboard' : ''}`}
            </Button>
          )}
        </>
      );
    } else {
      return (
        <Button color="transparent" disabled>
          Ask an Org Admin to setup Pulse
        </Button>
      );
    }
  };

  return <> {renderCta()}</>;
};

export default PulseCta;
