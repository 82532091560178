import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import AppContext from 'AppContext';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import CustomButton from 'components/CustomButtons/Button';
import Skeleton from '@material-ui/lab/Skeleton';

import apiService from 'utils/apiService';

import Page from './Page';
import Team from './Team';

import { checkPermission, getRole } from 'roles/checkPermission';

import { ReactComponent as DropdownIcon } from 'images/icons/dropdown.svg';
import { ReactComponent as BackIcon } from 'images/icons/back.svg';

import useSsoConnection from 'hooks/useSsoConnection';
import ReviewPromoMsg from './ReviewPromoMsg';

const FREE_RETROS_COUNT = 3;

export default function TeamView() {
  const { teamId } = useParams();
  const history = useHistory();
  const { isAuthenticated, org, userMeta, paymentMeta, switchOrg, updateOrg } = useContext(AppContext);
  const { id: orgId, customerId, teams, teamsSettings } = org || {};
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [teamData, setTeamData] = useState();
  const [remainingCount, setRemainingCount] = useState(0);
  const { role } = getRole({ orgId, teamId, userMeta });
  const canEditBilling = checkPermission(role, 'billing:edit');
  const { loginUrl } = useSsoConnection({ teamId, isDeepLink: true });

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const switchTeam = ({ id }) => {
    history.push(`/dashboard/team/${id}`);
    handleClose();
  };

  const setTeamOrg = useCallback(
    ({ teamId, onError }) => {
      return apiService
        .get(`/api/team/${teamId}`)
        .then(({ data }) => {
          const { orgId, name } = data;
          setTeamData(data);
          if (orgId && name) {
            switchOrg({ orgId });
          }
        })
        .catch((e) => {
          Boolean(typeof onError === 'function') && onError();
          return e;
        });
    },
    [switchOrg]
  );

  const updateTeamSettings = ({ id, name, isPrivate, isAnonymous }) => {
    updateOrg({
      orgId: org?.id,
      teamSettings: {
        teamId: id,
        name
      }
    });
  };

  const renderTeamList = () => {
    const renderList = (id) => {
      const { name } = teamsSettings[id];
      const selectedTeamId = teamId || teams[0];
      return (
        <MenuItem key={`team-list-${id}`} selected={id === selectedTeamId} onClick={() => switchTeam({ id })}>
          {name}
        </MenuItem>
      );
    };
    return (
      <>
        <Popover
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <div className="teamsDropdown">
              <MenuList>{teams.map(renderList)} </MenuList>
            </div>
          </ClickAwayListener>
        </Popover>
      </>
    );
  };

  const renderTeam = () => {
    if (!teamData) {
      return (
        <div className="teamsContainer">
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Skeleton width="30%" height={140} />
            <Skeleton width="60%" height={140} style={{ marginTop: '18px', marginBottom: '5px' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Skeleton variant="rect" width="30%" height={300} />
            <Skeleton variant="rect" width="30%" height={300} />
            <Skeleton variant="rect" width="30%" height={300} />
          </div>
        </div>
      );
    }

    const { id, name } = teamData;
    const multiTeams = Boolean(teams.length > 1);

    return (
      <div className="teamsContainer">
        <div className="team-container-header">
          <div className="org-dashboard-button">
            <CustomButton color="dark" onClick={() => history.push('/dashboard')}>
              <BackIcon style={{ paddingRight: '8px' }} /> Organization
            </CustomButton>
          </div>

          {multiTeams ? (
            <div>
              <Button onClick={handleToggle} disableRipple>
                <h2>
                  {name}
                  <DropdownIcon />
                </h2>
              </Button>
              {renderTeamList()}
            </div>
          ) : (
            <h2>{name}</h2>
          )}
          <p>View and manage your team’s content and settings</p>
          <hr />
        </div>

        <Team key={`team-${id}`} teamId={id} teamData={teamData} updateTeamSettings={updateTeamSettings} />
      </div>
    );
  };

  useEffect(() => {
    if (isAuthenticated === false && loginUrl) {
      window.location.replace(loginUrl);
    }
  }, [isAuthenticated, loginUrl]);

  useEffect(() => {
    if (!teams) return;

    const defaultTeamId = teams[0];

    if (teamId) {
      setTeamOrg({
        teamId,
        onError: () => {
          setTeamOrg({
            teamId: defaultTeamId
          });
          history.replace('/dashboard');
        }
      });
    } else {
      setTeamOrg({
        teamId: defaultTeamId
      });
    }
  }, [teamId, teams, history, setTeamOrg]);

  useEffect(() => {
    if (teamData) {
      const { credits } = teamData;
      setRemainingCount(Math.min(Math.max(credits, 0), 3));
    }
  }, [teamData]);

  return (
    <>
      <Page>
        {(customerId === null || paymentMeta === '' || (teamData && !teamData.active)) && (
          <span className="promoLink">
            <div className="retros-count">
              <div className="retros-count-numbers">Free retros: {remainingCount} of 3</div>

              <div className="retros-count-container">
                <div
                  className="retros-count-bar"
                  style={{ width: `${Math.round((remainingCount / FREE_RETROS_COUNT) * 100)}%` }}
                ></div>
              </div>
            </div>

            {canEditBilling && (
              <>
                <span className="text-button " onClick={() => history.push('/plans')}>
                  Get unlimited retros
                </span>

                <span role="img" aria-label="">
                  🚀
                </span>
              </>
            )}
          </span>
        )}

        {renderTeam()}
      </Page>

      {teamData && !teamData.active && remainingCount === 0 && <ReviewPromoMsg />}
    </>
  );
}
