import React, { useRef, useState, useEffect } from 'react';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
import apiService from 'utils/apiService';
import NotificationBar from 'modules/NotificationBar';
import { ReactComponent as ExternalIcon } from 'images/icons/external.svg';

export default function Feedback({ id, uid }) {
  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState();

  const panelEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const sendFeedback = () => {
    setLoading(true);

    apiService
      .post(`/api/board/${id}/feedback`, {
        feedback: text,
        uid
      })
      .then(() => {
        setLoading();
        setSubmitted(true);
      })
      .catch((e) => {
        setLoading();
        setError(true);
      });
  };

  const renderForm = () => {
    return (
      <>
        {submitted ? (
          <div>
            <p style={{ margin: '24px 0 0', fontSize: '64px' }}>
              <span role="img" aria-label="">
                💌
              </span>
            </p>
            <p style={{ marginBottom: '42px' }}>Thanks for your feedback!</p>
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
              <TextField
                value={text}
                variant="outlined"
                multiline
                minRows={4}
                onChange={onChange}
                placeholder="Enjoying TeleRetro? What could we do better?"
                inputProps={{
                  style: { fontSize: '16px' }
                }}
              />
            </div>

            <Button onClick={sendFeedback} disabled={!text || loading ? true : false} loading={loading}>
              Send Feedback
            </Button>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!open && submitted) {
      setText('');
    }
  }, [open, submitted]);

  useEffect(() => {
    if (open) {
      setSubmitted(false);
    }
  }, [open]);

  return (
    <div className="feedbackBox" onMouseLeave={handleClose}>
      <div ref={panelEl} className="actionPanel" onMouseOver={handleOpen}>
        <Badge badgeContent={null} color="primary" overlap="rectangular">
          Help
        </Badge>
      </div>

      <div className={'actionPanelList'}>
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-feedback">
                  <h3 onMouseOver={handleOpen}>Resources</h3>
                  <div className="actionPanelBoard-feedback--resources">
                    <a
                      className="text-button"
                      href="https://www.teleretro.com/collecting-feedback"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to use Voting, Emoji & GIFs <ExternalIcon />
                    </a>
                    <a
                      className="text-button"
                      href="https://www.teleretro.com/running-a-good-retro"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Running a good retro <ExternalIcon />
                    </a>
                    <a
                      className="text-button"
                      href="https://www.teleretro.com/retro-formats"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Retro formats ideas <ExternalIcon />
                    </a>
                    <a
                      className="text-button"
                      href="https://www.teleretro.com/pulse"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pulse Surveys <ExternalIcon />
                    </a>
                    <a
                      className="text-button"
                      href="https://www.teleretro.com/faqs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Frequently asked questions <ExternalIcon />
                    </a>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-12px' }}>
                      <a
                        className="text-button"
                        href="https://www.capterra.co.uk/reviews/214521/teleretro"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Capterra
                        <ExternalIcon />
                      </a>
                      <span style={{ padding: '0 12px', color: '#ccc' }}>|</span>
                      <a
                        className="text-button"
                        href="https://www.g2.com/products/teleretro/reviews"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        G2
                        <ExternalIcon />
                      </a>
                    </div>
                  </div>

                  <h3 style={{ borderTop: '1px solid #dcdcdc', margin: '0 -12px', padding: '24px 0 6px' }}>
                    Get in touch
                  </h3>

                  {renderForm()}
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
}
