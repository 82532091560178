import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import apiService from 'utils/apiService';
import { getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: '-4px',
    left: '-8px'
  },
  image: {
    width: '24px',
    height: '24px',
    border: 'none'
  },
  imageL: {
    width: '35px',
    height: '35px',
    border: 'none'
  }
}));

const cachedProfiles = {};

const UserAvatar = ({ userIds, isComment, isLarge }) => {
  const classes = useStyles();
  const [userProfiles, setUserProfiles] = useState({});

  const renderAvatar = (userId, i) => {
    if (isComment && !userProfiles[userId] && !isLarge) {
      return <Avatar key={`comment-avatar-${i}`} className={classes.image} />;
    }

    if (!userProfiles[userId]) return null;

    const { picture } = userProfiles[userId];
    const displayName = getDisplayName(userProfiles[userId]);

    if (picture === undefined || displayName === undefined) return null;

    return (
      <Tooltip title={displayName} placement="top" key={`avatar-${userId}`}>
        <Avatar className={isLarge ? classes.imageL : classes.image} alt={displayName} src={picture} />
      </Tooltip>
    );
  };

  useEffect(() => {
    userIds.forEach((userId) => {
      // annoymous id is 12
      if (userId?.length > 18) {
        if (cachedProfiles[userId]) {
          setUserProfiles({ ...cachedProfiles });
          return;
        }

        apiService
          .get(`/api/${userId}/profile`)
          .then(({ data }) => {
            cachedProfiles[userId] = data;
            setUserProfiles({ ...cachedProfiles });
          })
          .catch((e) => e);
      }
    });
  }, [userIds]);

  return (
    <div className={isComment ? '' : classes.root}>
      <AvatarGroup max={8} spacing={4}>
        {userIds.map(renderAvatar)}
      </AvatarGroup>
    </div>
  );
};

export default React.memo(UserAvatar, (prev, next) => {
  return isEqual(prev.userIds, next.userIds);
});
