import React, { useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CustomButtons from 'components/CustomButtons/Button';

import Log from 'utils/Log';

import iceBreakerImg from 'images/img/promo-ice-breaker.png';
import facilitatorImg from 'images/img/promo-facilitator.png';
import zapierImg from 'images/img/promo-zapier.png';
import spotlightImg from 'images/img/promo-spotlight.png';
import authorImg from 'images/img/promo-author.png';
import giphyImg from 'images/img/promo-giphy.png';
import emailImg from 'images/img/promo-email.png';
import IceBreakerBotImg from 'images/img/promo-ice-breaker-bot.png';
import backgroundImg from 'images/img/promo-background.png';
import moodImg from 'images/img/promo-mood.png';
import RetroBotImg from 'images/img/promo-retro-bot.png';

import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  nav: {
    padding: '8px !important',
    minWidth: 'auto !important'
  }
});

const features = [
  {
    label1: (
      <>
        Generate Retros with <b>Retro Bot</b>
      </>
    ),
    label2: 'Start a retro ›  Retro Bot',
    imgPath: RetroBotImg
  },
  {
    label1: (
      <>
        <b>Search</b> for background images
      </>
    ),
    label2: 'Start a retro ›  Background ›  Search',
    imgPath: backgroundImg
  },
  {
    label1: (
      <>
        Track your <b>Team Mood</b> over time
      </>
    ),
    label2: 'Retro  ›  Mood  ›  Start Mood Poll',
    imgPath: moodImg
  },
  {
    label1: (
      <>
        Play music or videos with <b>Icebreakers</b>
      </>
    ),
    label2: 'Retro  ›  Facilitator menu  ›  Icebreaker',
    imgPath: iceBreakerImg
  },
  {
    label1: (
      <>
        Create questions with <b>Icebreaker Bot</b>
      </>
    ),
    label2: 'Retro  ›  Facilitator menu  ›  Icebreaker',
    imgPath: IceBreakerBotImg,
    link: 'icebreaker-questions'
  },
  {
    label1: (
      <>
        Express yourself with <b>GIFs</b>
      </>
    ),
    label2: 'Retro   ›  Giphy button',
    imgPath: giphyImg
  },
  {
    label1: (
      <>
        See the <b>author</b> of each note
      </>
    ),
    label2: 'Retro  › Facilitator menu ›  Settings',
    imgPath: authorImg
  },
  {
    label1: (
      <>
        <b>Highlight</b> notes for discussion
      </>
    ),
    label2: 'Retro  ›  Facilitator menu ›  Spotlight',
    imgPath: spotlightImg
  },
  {
    label1: (
      <>
        Share your retro summary via <b>email</b>
      </>
    ),
    label2: 'Retro  ›  Faciliator menu  ›  Sharing',
    imgPath: emailImg
  },
  {
    label1: (
      <>
        <b>Export actions</b> to your choice of tools
      </>
    ),
    label2: 'Dashboard  ›  Integrations',
    imgPath: zapierImg
  },
  {
    label1: (
      <>
        Share <b>Facilitator</b> control
      </>
    ),
    label2: 'Dashboard  ›  Members  ›  Change Role',
    imgPath: facilitatorImg
  }
];

const DEFAULT_FEATURE_ID = 0; // Math.floor(Math.random() * features.length);

export default function Features({ canCreateBoard, teamId }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(canCreateBoard ? DEFAULT_FEATURE_ID : DEFAULT_FEATURE_ID); //features.length - 1
  const totalFeatures = features.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    Log('Click', `next ${activeStep}`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    Log('Click', `prev ${activeStep}`);
  };

  return (
    <div className="nextRetro">
      <p style={{ textAlign: 'center' }}>
        Get started with your next retro!{' '}
        <span role="img" aria-label="">
          🚀
        </span>
      </p>
      <div style={{ textAlign: 'center' }}>
        {canCreateBoard ? (
          <CustomButtons href={`/team/${teamId}/board/create`} disabled={!canCreateBoard}>
            <div>Start a retro</div>
          </CustomButtons>
        ) : (
          <Tooltip placement="top" title="To start a retro, ask your team admin to make you a Facilitator">
            <div>
              <CustomButtons href={`/team/${teamId}/board/create`} disabled={!canCreateBoard}>
                <div>Start a retro</div>
              </CustomButtons>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="featuresStepper">
        <p>{features[activeStep].label1}</p>
        <div className="featuresStepper-feature">
          {canCreateBoard && features[activeStep].link ? (
            <a href={`/team/${teamId}/${features[activeStep].link}`} target="_blank" rel="noopener noreferrer">
              <img src={features[activeStep].imgPath} alt="" />
            </a>
          ) : (
            <img src={features[activeStep].imgPath} alt="" />
          )}
        </div>
        <p>{features[activeStep].label2}</p>
      </div>
      <MobileStepper
        variant="dots"
        steps={totalFeatures}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === totalFeatures - 1} className={classes.nav}>
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.nav}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </Button>
        }
      />
    </div>
  );
}
