const getPlanName = (level) => {
  if (level.includes('level1')) {
    return 'Team';
  }

  if (level.includes('level2')) {
    return 'Business';
  }

  if (level.includes('level3')) {
    return 'Enterprise';
  }
  return '';
};

const currencySymbols = {
  gbp: '£',
  usd: '$',
  eur: '€'
};

const groupSign = '\n ~ \n';

const defaultSize = {
  w: 1,
  h: 3
};

const MOODS = {
  reactions: ['😔', '😕', '😐', '🙂', '😀'],
  colours: ['#F26C7C', '#FFA033', '#FFD233', '#CBEA71', '#6AE19A']
};

const DEFAULT_ASSIGNEE = 'none';

export { getPlanName, currencySymbols, groupSign, defaultSize, MOODS, DEFAULT_ASSIGNEE };
