import React, { useRef, useState, useEffect } from 'react';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';

import apiService from 'utils/apiService';
import OnPolling from 'modules/OnPolling';
import MoodBar from 'modules/MoodBar';

export default function Stats({ id, teamId, retroStates, uid }) {
  const [open, setOpen] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [editPolling, setEditPolling] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const panelEl = useRef(null);

  const { moodPoll } = retroStates || {};

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openMoodOptions = () => {
    setIsPolling(true);
    setEditPolling(true);
  };

  const onClose = () => {
    setIsPolling(false);
  };

  const renderSelectedValue = () => {
    if (!selectedValue) return null;

    return (
      <>
        {/* <h3 style={{ borderTop: '1px solid #dcdcdc', margin: '0 -12px', padding: '24px 0 16px' }}>Your Mood</h3>
        <p className="actionPanelBoard-stats--value">
          <span>{`${getMoodEmoji(selectedValue)}`}</span> {`${selectedValue}`}
        </p> */}
        <p>
          {moodPoll && (
            <span className="text-button" onClick={openMoodOptions}>
              Change your mood
            </span>
          )}
        </p>
      </>
    );
  };

  useEffect(() => {
    if (open) {
      setEditPolling(false);
    }
  }, [open]);

  useEffect(() => {
    if (!uid) return;

    apiService
      .get(`/api/team/${teamId}/board/${id}/polls`, {
        params: {
          type: 'mood',
          uid
        }
      })
      .then(({ data }) => {
        const { submittedValue } = data;

        if (submittedValue) {
          setSelectedValue(submittedValue);
        } else {
          setSelectedValue(null);
        }
      })
      .catch((e) => e);
  }, [id, teamId, uid]);

  useEffect(() => {
    if (moodPoll && selectedValue === null) {
      setIsPolling(true);
    }

    if (moodPoll === false) {
      setIsPolling(false);
    }
  }, [moodPoll, selectedValue]);

  if (!retroStates) return null;

  return (
    <>
      <div className="stats" onMouseOver={handleOpen} onMouseLeave={handleClose}>
        <div ref={panelEl} className="actionPanel">
          <Badge badgeContent={'🌤'} color="primary" overlap="rectangular">
            Mood
          </Badge>
        </div>

        <div className="actionPanelList">
          <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                <Paper>
                  <div className="actionPanelBoard actionPanelBoard-stats">
                    <h3>Team Mood</h3>
                    {moodPoll === undefined && <p>Mood Poll hasn't started yet</p>}
                    {moodPoll ? <p>Mood Poll currently in progress</p> : <MoodBar id={id} teamId={teamId} />}

                    {renderSelectedValue()}
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <OnPolling
        id={id}
        teamId={teamId}
        uid={uid}
        editPolling={editPolling}
        isPolling={isPolling}
        setIsPolling={setIsPolling}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onClose={onClose}
      />
    </>
  );
}
