import React, { useEffect, useState } from 'react';

import apiService from 'utils/apiService';
import MoodBar from 'modules/MoodBar';

export default function RetroStats({ id, teamId, actions }) {
  const [boardsInsights, setBoardsInsights] = useState({});

  const renderStats = () => {
    const { cardsCount, emojiCount, id, peopleCount, votesCount } = boardsInsights;
    const actionsCount = [...actions].filter(({ addedIn }) => addedIn === id).length;

    return (
      <div className="teamDashboard-overview">
        <span>
          <span className="teamDashboard-overview-icon" role="img" aria-label="">
            👤
          </span>
          <span>People</span>
          {peopleCount}
        </span>

        <span>
          <span className="teamDashboard-overview-icon" role="img" aria-label="">
            📝
          </span>
          <span>Notes</span>
          {cardsCount}
        </span>

        <span>
          <span className="teamDashboard-overview-icon" role="img" aria-label="">
            🗳
          </span>
          <span>Votes</span>
          {votesCount}
        </span>

        <span>
          <span className="teamDashboard-overview-icon" role="img" aria-label="">
            👍
          </span>
          <span>Emoji</span>
          {emojiCount}
        </span>

        <span>
          <span className="teamDashboard-overview-icon" role="img" aria-label="">
            🚀
          </span>
          <span>Actions</span>
          {actionsCount}
        </span>
      </div>
    );
  };

  useEffect(() => {
    apiService
      .get(`/api/team/${teamId}/board-insights/${id}`)
      .then(({ data }) => {
        setBoardsInsights(data);
      })
      .catch((e) => e);
  }, [id, teamId]);

  return (
    <div style={{ margin: '0 0 24px 0' }}>
      <MoodBar id={id} teamId={teamId} showTitle />
      {renderStats()}
    </div>
  );
}
