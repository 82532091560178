import React, { useCallback, useEffect, useRef, useState } from 'react';

import Log from 'utils/Log';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

import StyledBadge from './StyledBadge';
import useMood from 'hooks/useMood';

const MoodProgress = ({ id, teamId }) => {
  const { total } = useMood({ id, teamId });

  return (
    <p>
      {total > 0 ? (
        <>
          <b>{total} </b>
          {total > 1 ? 'people' : 'person'} shared their mood
        </>
      ) : null}
    </p>
  );
};

const CheckIn = ({ id, onMoodPoll, retroStates, teamId, startMoodPoll }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);
  const { moodPoll } = retroStates || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const endPoll = () => {
    onMoodPoll({
      moodPoll: false,
      showMood: true
    });

    handleClose();
    Log('Mood Poll', 'end');
  };

  const startPoll = useCallback(() => {
    onMoodPoll({
      moodPoll: true
    });

    handleClose();
    Log('Mood Poll', 'start');
  }, [onMoodPoll]);

  const renderEndPoll = () => {
    return (
      <>
        <p>Mood Poll currently in progress</p>

        <MoodProgress id={id} teamId={teamId} />

        {open && (
          <Tooltip placement="top" title={'End mood poll and view results'}>
            <span className="text-button" onClick={endPoll}>
              End Mood Poll
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  const renderStartPoll = () => {
    return (
      <>
        {moodPoll === undefined ? (
          <>
            <p>Ask people to rate how they feel from 1-5 when they join the retro</p>
            <span className="text-button" onClick={startPoll}>
              Start Mood Poll
            </span>
          </>
        ) : (
          <>
            <p>Mood Poll has ended</p>
            <p>
              View results in <b>Mood 🌤</b> menu
            </p>
            {open && (
              <Tooltip placement="top" title={'Allow users to update their answers'}>
                <span className="text-button" onClick={startPoll}>
                  Resume Mood Poll
                </span>
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (retroStates && moodPoll === undefined && startMoodPoll) {
      startPoll();
    }
  }, [retroStates, moodPoll, startPoll, startMoodPoll]);

  return (
    <div className="retroControlPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={!moodPoll} overlap="rectangular">
          Mood
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Mood</h3>

                  {moodPoll ? renderEndPoll() : renderStartPoll()}
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default CheckIn;
