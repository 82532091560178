import React, { useState, useContext, useEffect } from 'react';

import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/browser';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';

import { STRIPE_PK, APP_ENDPOINT } from 'config';
import AppContext from 'AppContext';

import Button from 'components/CustomButtons/Button';
import { getRole, checkPermission } from 'roles/checkPermission';
import ManageBilling from './ManageBilling';
import NotificationBar from './NotificationBar';

import { getPlanName, currencySymbols } from 'utils/constant';
import OrgLinks from './OrgLinks';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);

const LEVELS = ['monthly-level1', 'monthly-level2', 'yearly-level1', 'yearly-level2'];

const TYPES = ['monthly', 'yearly'];

const PlansContent = () => {
  const stripe = useStripe();
  const { org, paymentMeta, isAuthenticated, profile, userMeta } = useContext(AppContext);
  const { customerId, id: orgId, name } = org || {};

  const [planType, setPlanType] = useState(TYPES[0]);
  const [planLevel, setPlanLevel] = useState();
  const [prices, setPrices] = useState({});
  const [currentPrice, setCurrentPrice] = useState({});
  const [teamSize, setTeamSize] = useState(1);
  const [loading, setLoading] = useState(false);
  const [redirectToStrip, setRedirectToStrip] = useState(false);
  const [hasError, setHasError] = useState();
  const currentLevel = paymentMeta && paymentMeta.plan?.nickname;
  const currentId = paymentMeta && paymentMeta.plan?.id;
  const currentQuantity = paymentMeta && paymentMeta.quantity;
  const currentType = paymentMeta && paymentMeta.plan?.interval;

  const { role } = getRole({ orgId, userMeta });
  const canEditBilling = checkPermission(role, 'billing:edit');

  const getPrice = (level) => {
    const price =
      currentId && currentLevel.includes(level) && currentLevel.includes(planType)
        ? currentPrice[currentId]
        : prices[`${planType}-${level}`];

    const { unit_amount } = price || {};

    if (!unit_amount) {
      return '  ';
    }

    return (unit_amount * (level === 'level2' ? teamSize : 1)) / 100;
  };

  const getMonthlyPrice = (level) => {
    if (planType === TYPES[0]) {
      return getPrice(level);
    } else {
      return Math.round(getPrice(level) / 12);
    }
  };

  const getCurrencySymbol = (level) => {
    const { currency } = prices[`${planType}-${level}`] || {};
    return currencySymbols[currency] || '£';
  };

  const handlePlanTypeChange = (e, value) => {
    if (value) {
      setPlanType(value);
    }
  };

  const handleCheckout = async (level) => {
    setPlanLevel(level);
    setLoading(true);
    try {
      let currentCustomerId = customerId;

      if (!currentCustomerId) {
        const { id } = await axios.post(`/payment/org/${orgId}/create-customer`).then(({ data }) => data);

        currentCustomerId = id;
      }

      const { sessionId } = await axios
        .post(`/payment/org/${orgId}/create-checkout-session`, {
          customerId: currentCustomerId,
          level: `${planType}-${level}`,
          quantity: level === 'level2' ? teamSize : 1
        })
        .then(({ data }) => {
          setLoading(false);
          return data;
        });

      // When the customer clicks on the button, redirect them to Checkout.
      const { error } = await stripe.redirectToCheckout({
        sessionId
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        setHasError(true);
        Sentry.captureException(error);
      }
    } catch (e) {
      setLoading(false);
      setHasError(true);
      Sentry.captureException(e);
    }
  };

  const renderCta = (level) => {
    const isSelected = loading && level === planLevel;
    const onSelect = async () => handleCheckout(level);
    const onUpdate = () => setRedirectToStrip(true);

    if (customerId === null || paymentMeta === '') {
      return canEditBilling ? (
        <Button onClick={onSelect} disabled={isSelected} loading={isSelected} style={{ minWidth: '112px' }}>
          Select
        </Button>
      ) : (
        <Tooltip title="Please contact your org owner to upgrade" placement="top">
          <Button disabled style={{ minWidth: '80px', pointerEvents: 'auto' }}>
            Select
          </Button>
        </Tooltip>
      );
    }

    if (!canEditBilling || !currentLevel) {
      return (
        <Tooltip title="Please contact your org owner to upgrade" placement="top">
          <Button disabled style={{ minWidth: '80px', pointerEvents: 'auto' }}>
            Select
          </Button>
        </Tooltip>
      );
    }

    if (level === 'level1' && currentLevel.includes('level2')) {
      return (
        <span className="text-button text-button-secondary" onClick={onUpdate} style={{ minWidth: '80px' }}>
          Select
        </span>
      );
    }

    if (currentLevel.includes(level)) {
      return (
        <Button onClick={onUpdate} disabled={isSelected} loading={isSelected} style={{ minWidth: '112px' }}>
          Manage Plan
        </Button>
      );
    }

    return (
      <Button onClick={onUpdate} disabled={isSelected} loading={isSelected} style={{ minWidth: '112px' }}>
        {currentLevel.includes('level1') ? 'Upgrade' : 'Manage Plan'}
      </Button>
    );
  };

  const onTeamSizeChange = (e) => {
    const size = e.target?.value;
    if (size) {
      setTeamSize(size);
    }
  };

  const renderPriceTable = () => {
    return (
      <div id="pricing-data">
        <table>
          <colgroup>
            <col className="pricing-column pricing-column-labels" />
            <col className="pricing-column pricing-column-team" />
            <col className="pricing-column pricing-column-business" />
            <col className="pricing-column pricing-column-enterprise" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>{getPlanName('level1')}</th>
              <th>{getPlanName('level2')}</th>
              <th>{getPlanName('level3')}</th>
            </tr>

            <tr className="pricing-summary">
              <td></td>
              <td>{renderCta('level1')}</td>
              <td>{renderCta('level2')}</td>
              <td>
                <a className="text-button" href="mailto:sales@teleretro.com">
                  Contact us
                </a>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="pricing-price-row">
              <th>
                <ToggleButtonGroup value={planType} exclusive onChange={handlePlanTypeChange} aria-label="Plan type">
                  <ToggleButton value={TYPES[0]} aria-label="Monthly">
                    Monthly
                  </ToggleButton>
                  <ToggleButton value={TYPES[1]} aria-label="Yearly">
                    Annual{' '}
                    <span className="priceDiscount" title="This discount applies to self service purchases only">
                      save 20%*
                    </span>
                  </ToggleButton>
                </ToggleButtonGroup>
              </th>
              <td>
                <div className="pricing-price">
                  <span className="pricing-currency-symbol">{getCurrencySymbol('level1')}</span>
                  <span className="pricing-currency-amount">{getMonthlyPrice('level1')}</span>
                  <span className="pricing-period">
                    <span>/month</span>
                  </span>
                </div>
                {planType === TYPES[1] && (
                  <span className="pricing-annual">
                    Billed {getCurrencySymbol('level1')}
                    {getPrice('level1')} annually
                  </span>
                )}
              </td>
              <td>
                <div className="pricing-price">
                  <span className="pricing-currency-symbol">{getCurrencySymbol('level2')}</span>
                  <span className="pricing-currency-amount">{getMonthlyPrice('level2')}</span>
                  <span className="pricing-period">
                    <span>/month</span>
                  </span>
                </div>
                {planType === TYPES[1] && (
                  <span className="pricing-annual">
                    Billed {getCurrencySymbol('level2')}
                    {getPrice('level2')} annually
                  </span>
                )}
              </td>
              <td>
                <div className="pricing-price">Contact us</div>
              </td>
            </tr>
            <tr>
              <th>Teams</th>
              <td>1</td>
              <td>
                <FormControl variant="outlined">
                  <Select
                    value={teamSize}
                    onChange={onTeamSizeChange}
                    name="teamSize"
                    inputProps={{
                      name: 'Number of Teams',
                      id: 'team-size-select'
                    }}
                  >
                    <MenuItem value="1">3</MenuItem>
                    <MenuItem value="2">6</MenuItem>
                    <MenuItem value="3">9</MenuItem>
                    <MenuItem value="4">12</MenuItem>
                    <MenuItem value="5">15</MenuItem>
                    <MenuItem value="6">18</MenuItem>
                    <MenuItem value="7">21</MenuItem>
                    <MenuItem value="8">24</MenuItem>
                  </Select>
                </FormControl>
              </td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th>Retros</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th>Pulse Surveys</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th>Members</th>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th>Private Retros</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Team Management</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Icebreakers</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Facilitator Controls</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Retro Analytics</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Action Tracker</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Integrations</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Export Retros</th>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Cross-team Dashboard</th>
              <td className="not-included">
                <span className="visually-hidden">no</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Single Sign On</th>
              <td className="not-included">
                <span className="visually-hidden">no</span>
              </td>
              <td className="not-included">
                <span className="visually-hidden">no</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr>
              <th>Priority Support</th>
              <td className="not-included">
                <span className="visually-hidden">no</span>
              </td>
              <td className="not-included">
                <span className="visually-hidden">no</span>
              </td>
              <td className="included">
                <span className="visually-hidden">yes</span>
              </td>
            </tr>
            <tr className="pricing-summary">
              <td></td>
              <td>
                <p className="pricing-summary-title">{getPlanName('level1')}</p>
                <p className="pricing-summary-text">Unlimited retros and insights for single teams</p>
                {renderCta('level1')}
              </td>
              <td>
                <p className="pricing-summary-title">{getPlanName('level2')}</p>
                <p className="pricing-summary-text">Scale your retros to multiple teams</p>
                {renderCta('level2')}
              </td>
              <td>
                <p className="pricing-summary-title">{getPlanName('level3')}</p>
                <p className="pricing-summary-text">The complete solution for your organisation</p>

                <a className="text-button" href="mailto:sales@teleretro.com">
                  Contact us
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderEnterprisePlan = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Your plan</h2>
        <div style={{ padding: '24px 0 64px' }}>
          <p>
            Your organization is currently on the <b>Enterprise</b> plan.
          </p>
          <p>If you have any questions about your plan, or would like to make any changes, </p>
          <p>
            please reach out to us at:{' '}
            <a className="text-button" href="mailto:support@teleretro.com">
              support@teleretro.com
            </a>
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const prices = {};
    Promise.all(LEVELS.map((id) => axios.get(`/payment/price/${id}`)))
      .then((data) => {
        data.forEach((item) => {
          const { status, data } = item;
          if (status === 200) {
            prices[data.id] = data.data;
          } else {
            prices[data.id] = {};
          }
        });
        setPrices(prices);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, []);

  useEffect(() => {
    if (currentId) {
      axios
        .get(`/payment/price/${currentId}`)
        .then(({ data }) => {
          const price = {};
          price[data.id] = data.data;
          setCurrentPrice(price);
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  }, [currentId]);

  useEffect(() => {
    if (currentQuantity) {
      setTeamSize(currentQuantity);
    }
  }, [currentQuantity]);

  useEffect(() => {
    if (currentType && currentType.includes('year')) {
      setPlanType(TYPES[1]);
    }
  }, [currentType]);

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="clouds" style={{ opacity: 0.5 }}>
        <div
          className="cloud cloud-small cloud-orange"
          style={{
            right: '1%',
            top: '150px'
          }}
        ></div>

        <div
          className="cloud cloud-small cloud-orange"
          style={{
            left: '-8px',
            top: '380px'
          }}
        ></div>
      </div>
      <div className="team-container-header">
        <h2>
          {name ? (
            name
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton width={'180px'} height={44} />
            </div>
          )}
        </h2>
        {/* <p>Manage your organization’s plan</p> */}
        {currentLevel ? (
          <p>
            You are on the <b>{getPlanName(currentLevel)}</b> plan
          </p>
        ) : (
          canEditBilling && <p>You are on a free plan</p>
        )}

        <hr />
        <OrgLinks current={'/plans'} />
      </div>
      <Paper className="plans">{currentLevel?.includes('level3') ? renderEnterprisePlan() : renderPriceTable()}</Paper>
      {redirectToStrip && <ManageBilling email={profile?.email} customerId={customerId} orgId={orgId} />}
      {hasError && (
        <NotificationBar
          message="Sorry something went wrong. Please contact your org owner to check if you have the right permissions."
          type="error"
          autoHideDuration={5000}
          closeCallback={() => setHasError()}
        />
      )}
    </div>
  );
};

export default function Plans({ getPaymentData }) {
  return (
    <Elements stripe={stripePromise}>
      <PlansContent />
    </Elements>
  );
}
