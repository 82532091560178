import React, { useState, useRef } from 'react';
import Log from 'utils/Log';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import StyledBadge from './StyledBadge';

const Highlight = ({ onHighlight, showHighlight, user }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);
  const { id } = user || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const toggleHighlight = ({ showHighlight }) => {
    onHighlight({
      showHighlight
    });
    setOpen(false);

    Log('Spotlight', showHighlight ? 'on' : 'off');
  };

  return (
    <div className="highlightsPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={Boolean(!showHighlight)} overlap="rectangular">
          Spotlight
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Spotlight</h3>
                  <p style={{ padding: '0 7px' }}>Hover over any note to highlight it for discussion</p>

                  <div>
                    {showHighlight ? (
                      <span
                        className="text-button"
                        onClick={() =>
                          toggleHighlight({
                            showHighlight: false
                          })
                        }
                      >
                        Turn off Spotlight
                      </span>
                    ) : (
                      <span
                        className="text-button"
                        onClick={() =>
                          toggleHighlight({
                            showHighlight: id
                          })
                        }
                      >
                        Turn on Spotlight
                      </span>
                    )}
                  </div>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Highlight;
