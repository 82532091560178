import React from 'react';

import Paper from '@material-ui/core/Paper';
import { ReactComponent as SlackIcon } from 'images/icons/slack.svg';
import { ReactComponent as AddedIcon } from 'images/icons/done.svg';

import { ENABLE_ZAP_MANAGER } from 'config';
import useSlack from 'hooks/useSlack';

const isZapManagerEnabled = Boolean(ENABLE_ZAP_MANAGER === 'true');

export default function Integrations({ teamId }) {
  const { connectUrl, configurationUrl } = useSlack({ teamId, isFacilitator: true });

  const renderSlackBtn = () => {
    const getButton = () => {
      if (configurationUrl) {
        return (
          <>
            <SlackIcon fontSize="small" />
            <span style={{ padding: '0 0 0 12px' }}>Slack Configuration</span>
            <AddedIcon
              className="integration-added-icon"
              style={{ width: '20px', margin: '0 0 0 12px' }}
              fontSize="small"
            />
          </>
        );
      }

      if (connectUrl) {
        return (
          <>
            <SlackIcon fontSize="small" />
            <span style={{ padding: '0 0 0 12px' }}>Add to Slack</span>
          </>
        );
      }

      return null;
    };

    return (
      <div style={{ textAlign: 'center', margin: '36px 0 60px 0' }}>
        <a
          href={configurationUrl || connectUrl}
          target="_blank"
          rel="noreferrer"
          style={{
            alignItems: 'center',
            color: '#fff',
            backgroundColor: '#4A154B',
            border: '0',
            borderRadius: '10px',
            display: 'inline-flex',
            fontFamily: 'Lato, sans-serif',
            fontSize: '16px',
            fontWeight: 600,
            height: '48px',
            justifyContent: 'center',
            textDecoration: 'none',
            width: '236px'
          }}
        >
          {getButton()}
        </a>
      </div>
    );
  };

  return (
    <div>
      <Paper className="integrations-zapier-wrapper">
        <div className="integrations-zapier-container">
          <h2>Slack</h2>
          <p>
            {configurationUrl
              ? '🎉 Your TeleRetro integration with Slack is now up and running! You’re all set to streamline your team’s collaboration directly within Slack. You can now send invites, action items, and retrospective summaries directly into the chosen channel during your meetings. To manage your Slack app preferences, select "Slack Configuration".'
              : 'TeleRetro’s Slack integration makes it easy to send meeting invites and tasks right to the Slack channel of your choice. By using the incoming-webhook permission, TeleRetro can post messages directly to your team’s channels. Simply click the "Add to Slack" button, and follow the steps to pick the Slack channel you want to integrate.'}
          </p>

          {renderSlackBtn()}

          <hr />
          <h2>Zapier</h2>

          <p>
            TeleRetro uses Zapier to let you automatically raise tickets in other tools the moment an action is raised
            in your retro. Once you have setup your integration (called a Zap), you won’t need to manually copy and
            raise tickets in those other tools. To learn more about how to use Zapier to export actions automatically
            visit our <a href="https://www.teleretro.com/integrations">Integrations</a> page.
          </p>

          {isZapManagerEnabled ? (
            <div style={{ margin: '48px 0 24px' }}>
              <zapier-zap-manager
                client-id="q9ZitRKjl1QH6Mx5AV5JiCPUTfIjUIsDOdfXCaTB"
                link-target="new-tab"
                theme="light"
              />
            </div>
          ) : (
            <>
              <h3>Getting Started with Zapier</h3>
              <p>
                To help you get started, here are some popular pre-made Zaps (you will need to sign up for a free Zapier
                account):
              </p>
            </>
          )}
        </div>
        {!isZapManagerEnabled && (
          <>
            <zapier-zap-templates apps="teleretro" limit={20} link-target="new-tab" presentation="row" theme="light" />
            <div>
              <h3>How do I connect TeleRetro to other apps through Zapier?</h3>
              <ol>
                <li>1. Log in to your Zapier account or create a new account.</li>
                <li>2. Navigate to "My Apps" from the top menu bar.</li>
                <li>3. Click on "Connect a new account..." and search for "TeleRetro"</li>
                <li>4. Use your credentials to connect your TeleRetro account to Zapier.</li>
                <li>
                  5. Once that's done you can start creating an automation! Use a pre-made Zap or create your own with
                  the Zap Editor.
                </li>
              </ol>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
}
