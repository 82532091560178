import React from 'react';
import map from 'lodash/map';
import MoodSelectorEmoji from './MoodSelectorEmoji';
import { MOODS } from 'utils/constant';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selector: {
    borderRadius: '4px',
    padding: '2px'
  },
  emoji: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const MoodSelector = ({ reactions, onSelect, selectedValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.selector}>
      <div className={classes.emoji}>
        {map(reactions, (reaction, i) => {
          return (
            <MoodSelectorEmoji
              key={i}
              shortcode={reaction}
              value={i + 1}
              onSelect={onSelect}
              selectedValue={selectedValue}
            />
          );
        })}
      </div>
    </div>
  );
};

MoodSelector.defaultProps = {
  reactions: MOODS.reactions
};

export default MoodSelector;
