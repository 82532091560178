import React from 'react';

export default function PulseStats({ id, pulseData, actions = [], noEmoji }) {
  const renderStats = () => {
    const { people, feedbackByTopic } = pulseData || {};
    const total = feedbackByTopic && Object.values(feedbackByTopic).flat().length;

    const actionsCount = [...actions].filter(({ addedIn }) => addedIn === id).length;

    return (
      <div className="teamDashboard-overview" style={{ marginBottom: '24px' }}>
        <span>
          {!noEmoji && (
            <span className="teamDashboard-overview-icon" role="img" aria-label="">
              👤
            </span>
          )}
          <span>People</span>
          {people?.length}
        </span>

        <span>
          {!noEmoji && (
            <span className="teamDashboard-overview-icon" role="img" aria-label="">
              💬
            </span>
          )}
          <span>Comments</span>
          {total}
        </span>

        <span>
          {!noEmoji && (
            <span className="teamDashboard-overview-icon" role="img" aria-label="">
              🚀
            </span>
          )}
          <span>Actions</span>
          {actionsCount}
        </span>
      </div>
    );
  };

  return <>{renderStats()}</>;
}
