import React from 'react';
import map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';

import GithubSelectorEmoji from './GithubSelectorEmoji';

const useStyles = makeStyles((theme) => ({
  selector: {
    borderRadius: '4px',
    padding: '2px'
  },
  emoji: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const GithubSelector = ({ reactions, onSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.selector}>
      <div className={classes.emoji}>
        {map(reactions, (reaction, i) => {
          return <GithubSelectorEmoji key={i} shortcode={reaction} onSelect={onSelect} />;
        })}
      </div>
    </div>
  );
};

GithubSelector.defaultProps = {
  reactions: ['🚀', '😎', '🎉', '❤️', '👍']
};

export default GithubSelector;
