import React from 'react';

import { ReactComponent as LoaderIcon } from 'images/icons/loader.svg';

export default function Loader() {
  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '60px', height: '60px', animation: 'pulse 1.5s ease-in-out 0.5s infinite' }}>
        <LoaderIcon width={60} height={60} />
      </div>
    </div>
  );
}
