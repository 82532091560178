import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { APP_ENDPOINT } from 'config';

const apiService = axios.create();

apiService.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error

    Sentry.captureException(error);

    if (error?.response?.status === 401) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }

    return Promise.reject(error);
  }
);

export default apiService;
