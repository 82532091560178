import React from 'react';

import { MOODS } from 'utils/constant';
import { getMoodEmoji } from 'utils/misc';

import useMood from 'hooks/useMood';

export default function MoodBar({ id, teamId, showTitle }) {
  const { moodScore, moodValues, total } = useMood({ id, teamId });

  const percentageGroup = MOODS.reactions.map((v, index) => Math.round((moodValues[index + 1] / total) * 100) || 0);
  //const totalPercentage = percentageGroup.reduce((sum, value) => sum + value, 0);

  const renderBar = (v, index) => {
    const key = index + 1;
    let percentage = percentageGroup[index];

    // if (totalPercentage > 100 && index === 0) {
    //   percentage = percentage - (totalPercentage - 100);
    // }

    // if (totalPercentage === 99 && index === 0) {
    //   percentage = percentage + 1;
    // }

    return (
      <span className="moodGraph-mood" key={`mood-key-${key}`}>
        <span className="moodGraph-value">{key}</span>
        <span className="moodGraph-emoji">{v}</span>
        <span className={`moodGraph-bar moodGraph-bar-${key}`}>
          <span className="moodGraph-bar--container">
            <span className="moodGraph-bar--element" style={{ width: `${percentage}%` }} title={moodValues[key]}></span>
          </span>
        </span>

        <span className="moodGraph-percentage">{`${percentage}%`} </span>
      </span>
    );
  };

  return (
    <div className="moodGraph">
      {total > 0 && !showTitle && (
        <p style={{ fontSize: '15px', margin: '10px 0' }}>
          {total} {total > 1 ? 'responses' : 'response'}
        </p>
      )}
      {MOODS.reactions.map(renderBar)}

      {moodScore > 0 && (
        <>
          <p className="actionPanelBoard-stats--value">
            <span>{`${getMoodEmoji(moodScore)}`}</span> {`${moodScore}`}
          </p>

          {showTitle ? (
            <div style={{ fontSize: '14px', fontWeight: '600', margin: '0 0 12px 0' }}>Team Mood</div>
          ) : null}
        </>
      )}
    </div>
  );
}
