import React from 'react';

import HomeMenu from './HomeMenu';
import Footer from './Footer';

import ErrorBoundary from './ErrorBoundary';

export default function Page({ children, boardId }) {
  return (
    <div className="page">
      <div className="menu">
        <HomeMenu boardId={boardId} />
      </div>
      {/* <div className="pageNav">
        <SecondaryMenu />
      </div> */}
      <div className="pageMain">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      <Footer />
    </div>
  );
}
