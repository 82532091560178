import React, { useState, useEffect, useRef } from 'react';
import findIndex from 'lodash/findIndex';
import { nanoid } from 'nanoid';
import Log from 'utils/Log';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';

import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import { ReactComponent as ReactionIcon } from 'images/icons/reaction.svg';
import { ReactComponent as CommentIcon } from 'images/icons/comment-add.svg';
import { GithubCounter, GithubSelector } from '../react-reactions/src';
import { groupSign } from 'utils/constant';

import './Emoji.css';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    margin: '2px 2px 8px !important',
    padding: '8px 10px'
  },
  tooltipEmoji: {
    position: 'relative',
    top: '-4px',
    left: '70px',
    margin: '2px !important',
    padding: '8px 10px',
    background: '#fff',
    boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.2)'
  },
  moreIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    '&:hover': {
      background: 'rgba(220, 220, 220, 0.6)'
    },
    '& svg': {
      fill: 'grey'
    }
  },
  staticIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    borderRadius: '5px',
    cursor: 'default',
    width: '24px',
    height: '24px',
    '& svg': {
      fill: 'lightGrey'
    }
  }
}));

const Emoji = ({
  note,
  userId,
  setEmojiReaction,
  deleteNote,
  showProfile,
  onSendAction,
  unGroup,
  showTyping,
  showComments,
  setShowComments
}) => {
  const user = userId;
  const classes = useStyles();
  const emojiPanelEl = useRef(null); // For pop up unique anchor

  const { text } = note;
  const isGrouped = typeof unGroup === 'function' && text.includes(groupSign) && note.groupedBy?.length > 0;

  const [counters, setCounters] = useState(note.emojis || []);
  const [showSelector, setShowSelector] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const openMoreOptionsMenu = (e) => {
    setShowMoreOptions(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const closeMoreOptionsMenu = (e) => {
    setShowMoreOptions(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDelete = (e) => {
    deleteNote(e, note);
    e.stopPropagation();
    e.preventDefault();
  };

  const onShowSelector = (e) => {
    const targetVisibility = getComputedStyle(e.target).visibility;
    //prevent selector showing when pick is showing or selector just called hide
    if (!showPicker && targetVisibility === 'visible') {
      setShowSelector(true);
    }
    e && e.stopPropagation();
  };

  const onCloseSelector = (e) => {
    setShowSelector(false);
    e && e.stopPropagation();
  };

  const handleSelect = (emoji) => {
    const index = findIndex(counters, {
      emoji,
      by: user
    });
    let newCounters;
    if (index > -1) {
      newCounters = counters.filter((item, i) => i !== index);
    } else {
      newCounters = [...counters, { emoji, by: user }];
    }

    const emojiReaction = setEmojiReaction(note, newCounters);

    if (emojiReaction) {
      setCounters(newCounters);
    }

    setShowSelector(false);
  };

  const sendAction = (e) => {
    const uid = nanoid();
    const date = new Date();
    const { text, createdBy } = note;

    onSendAction({
      id: uid,
      text,
      status: 0,
      timeStamp: date.getTime(),
      createdBy
    });

    closeMoreOptionsMenu(e);
    Log('Card settings', 'Send action');
  };

  const handleUnGroup = (e) => {
    unGroup(note);

    closeMoreOptionsMenu(e);

    Log('Card settings', 'Ungroup');
  };

  const showMoreEmojiPicker = () => {
    setShowPicker(true);
    setShowSelector(false);
  };

  const selectFromMoreEmojiPick = (e) => {
    const emoji = e.native;
    handleSelect(emoji);
    setShowPicker(false);
  };

  const onShowComments = (e) => {
    setShowComments(true);
    setShowPicker(false);
    setShowSelector(false);
  };

  useEffect(() => {
    if (note.emojis) {
      setCounters(note.emojis);
    }
  }, [note.emojis]);

  useEffect(() => {
    if (showSelector && (showComments || showPicker || showMoreOptions)) {
      // Close selector if any other menu is open
      setShowSelector(false);
    }
  }, [showSelector, showComments, showPicker, showMoreOptions]);

  return (
    <>
      <div ref={emojiPanelEl} className="emojiCounter" onClick={(e) => e.stopPropagation()}>
        <GithubCounter counters={counters} user={user} onSelect={handleSelect} showProfile={showProfile} />
      </div>
      <div className="noteOptions">
        <Tooltip
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GithubSelector onSelect={handleSelect} />
              <Tooltip title="More reactions" placement="top" classes={{ tooltip: classes.tooltip }}>
                <div className={classes.moreIcon} onClick={showMoreEmojiPicker}>
                  <ReactionIcon style={{ marginLeft: '-2px' }} />
                </div>
              </Tooltip>
              <Tooltip title="Comments" placement="top" classes={{ tooltip: classes.tooltip }}>
                <div className={classes.moreIcon} onClick={onShowComments}>
                  <CommentIcon fontSize="medium" style={{ marginLeft: '-2px' }} />
                </div>
              </Tooltip>
              <div className={classes.staticIcon}>
                <AddIcon fontSize="small" aria-label="settings" />
              </div>
              <Tooltip title={showTyping ? '' : 'More options'} placement="top" classes={{ tooltip: classes.tooltip }}>
                <div className={classes.moreIcon} onClick={showTyping ? () => {} : openMoreOptionsMenu}>
                  <MoreVertIcon fontSize="small" aria-label="more options" />
                </div>
              </Tooltip>
            </div>
          }
          placement="left"
          enterDelay={200}
          enterNextDelay={200}
          TransitionComponent={Fade}
          classes={{ tooltip: classes.tooltipEmoji }}
          open={showSelector}
          onOpen={onShowSelector}
          onClose={onCloseSelector}
          interactive
        >
          <div className="noteAction">
            <AddIcon fontSize="small" aria-label="settings" />
          </div>
        </Tooltip>

        <Popover
          anchorEl={emojiPanelEl.current}
          open={showMoreOptions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <ClickAwayListener onClickAway={closeMoreOptionsMenu}>
            <MenuList>
              <MenuItem className="action-delete" onClick={handleDelete}>
                Delete
              </MenuItem>
              {isGrouped && <MenuItem onClick={handleUnGroup}>Ungroup</MenuItem>}
              <MenuItem onClick={sendAction}>
                Add to <b style={{ paddingLeft: '5px' }}>Actions</b>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Popover>

        <Popover
          anchorEl={emojiPanelEl.current}
          open={showPicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setShowPicker(false);
            }}
          >
            <Picker native autoFocus onSelect={selectFromMoreEmojiPick} />
          </ClickAwayListener>
        </Popover>
      </div>
    </>
  );
};

export default Emoji;
