import React, { useState, useEffect } from 'react';

import Button from 'components/CustomButtons/Button';

import Modal from 'components/Modal/Modal';
import votingImg from 'images/img/voting.png';

export default function OnVoting({ isFacilitator, votingStates, columns, compact }) {
  const [isVoting, setIsVoting] = useState(false);
  const closeVotingModal = () => {
    setIsVoting(false);
  };

  useEffect(() => {
    if (votingStates.status === 'vote') {
      setIsVoting(true);
    } else {
      setIsVoting(false);
    }
  }, [votingStates.status]);

  return (
    <Modal status={isVoting} maxWidth="sm" onClose={closeVotingModal} nonResponsive>
      <div className="welcomeModal">
        <h2>
          <span role="img" aria-label="">
            🗳
          </span>{' '}
          Voting is starting
        </h2>
        {!compact && (
          <p>
            A new voting session for <strong>‘{columns[votingStates.contentId]}’</strong> is starting
          </p>
        )}
        <p>
          <img className="modal-voting-img" src={votingImg} alt="" />
        </p>
        <p>
          <strong>Click</strong> on a note to add or remove votes
        </p>
        <hr />
        {isFacilitator ? (
          <div>
            <p>
              To show results once everyone has voted click <strong>End Voting</strong>
            </p>
            <p>Facilitator menu › Voting › End Voting</p>
          </div>
        ) : (
          <p>
            You have <strong>{votingStates.maxVotes}</strong> votes
          </p>
        )}
        <p style={{ margin: '0' }}>
          <Button onClick={closeVotingModal}>Start Voting</Button>
        </p>
      </div>
    </Modal>
  );
}
