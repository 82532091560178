import React, { useState, useRef, useContext, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import throttle from 'lodash/throttle';
import ReactDOM from 'react-dom';
import Reward from 'react-rewards';
import { useHistory } from 'react-router-dom';

// We don't want to use apiService to handle unsplash unauthorized errors
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from 'components/Modal/Modal';
import Button from 'components/CustomButtons/Button';

import Log from 'utils/Log';
import apiService from 'utils/apiService';
import { validateURL } from 'utils/misc';
import NotificationBar from 'modules/NotificationBar';
import AppContext from 'AppContext';

function RetroFormatBot({ showRetroBot, teamId, setFormat, setCols, setBackground, setBackgroundUrl, isActive }) {
  const history = useHistory();
  const { profile } = useContext(AppContext);
  const { picture } = profile || {};
  const [topic, setTopic] = useState('');
  const [reachLimit, setReachLimit] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const rewardEl = useRef(null);

  const config = {
    elementCount: 40,
    spread: 200,
    startVelocity: 40,
    emoji: ['🪄', '✨']
  };

  const rewardMe = useMemo(
    () => throttle(() => rewardEl.current?.rewardMe(), 1000, { leading: true, trailing: false }),
    []
  );

  const onChange = (e) => {
    const value = e.target?.value;
    setTopic(value);
  };

  const getRetroFormat = () => {
    setLoading(true);

    apiService
      .get(`/api/team/${teamId}/retro-format`, {
        params: {
          topic
        }
      })
      .then(({ data }) => {
        setLoading(false);
        const { content } = data;
        setFormat(topic);
        setCols(content);
        getImage();
        rewardMe();

        Log('Retro Bot', `${topic} - ${content.toString()}`);
      })
      .catch((e) => {
        setLoading(false);

        if (e?.response?.status === 400) {
          setError(
            "Oops! The input you provided doesn't seem quite right. Let's keep our conversation respectful and on-topic. Please try again with a different topic. 👍🤖"
          );
        } else if (e?.response?.status === 429) {
          setReachLimit(true);
        } else if (e?.response?.status === 403) {
          setError(
            'Sorry something went wrong. Please contact your team admin to check if you have the right permissions.'
          );
        } else {
          setError(
            'Uh oh! Our AI bot is taking a break right now. Please try again later when it’s feeling more sociable. 😴🤖'
          );
        }
      });
  };

  const getImage = () => {
    axios
      .get(
        `/unsplash/search/photos?&query=${topic}` +
          `&order_by=popular&per_page=20&content_filter=high&orientation=landscape`
      )
      .then(({ data }) => {
        const { results } = data;

        if (results.length > 0) {
          const randomKey = Math.floor(Math.random() * results.length);
          const { urls, links } = results[randomKey];

          const url = urls?.regular;
          const downloadLocation = links?.download_location;

          if (validateURL(url)) {
            setBackgroundUrl(url);
          }

          // trigger a download event
          if (validateURL(downloadLocation)) {
            axios.get(downloadLocation.replace('https://api.unsplash.com/', '/unsplash')).catch((e) => e);
          }
        } else {
          setBackgroundUrl();
          setBackground('retro-bot');
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        return e;
      });
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      getRetroFormat();
    }
  };

  return (
    <Slide direction="right" in={showRetroBot} timeout={{ enter: 400, exit: 400 }}>
      <div
        style={{
          color: '#fff',
          alignItems: 'center',
          background: 'rgba(22, 22, 22, 0.85)',
          borderRadius: '10px',
          position: 'fixed',
          bottom: '140px',
          left: '0',
          margin: '18px',
          padding: '32px 18px 24px',
          maxWidth: '450px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <span role="img" aria-label="" style={{ fontSize: '24px', marginRight: '12px' }}>
              🤖
            </span>
          </div>
          <div>
            <span
              style={{
                backgroundColor: 'var(--blue)',
                borderRadius: '25px',
                padding: '12px 15px',
                fontWeight: '500',
                color: '#fff',
                display: 'inline-block'
              }}
            >
              Let's make a Retro! What topic would you like?
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '12px'
          }}
        >
          {loading && <CircularProgress size={20} />}
          <TextField
            onChange={onChange}
            variant="outlined"
            placeholder="e.g. unicorns"
            inputProps={{
              className: 'retroBot-input-field',
              maxLength: 28 // inputProps apply on input, InputProps apply on input parent
            }}
            InputProps={{
              style: {
                color: '#fff',
                width: '160px',
                border: '0px none',
                height: '40px',
                padding: '4px 8px',
                fontSize: '17px',
                background: 'rgba(255, 255, 255, 0.2)',
                margin: '10px',
                borderRadius: '25px'
              }
            }}
            onKeyUp={keyPress}
            value={topic}
            disabled={Boolean(loading)}
          />

          <Avatar className="profileImg" alt="" src={picture} />
        </div>

        {error && (
          <NotificationBar message={error} type="error" autoHideDuration={15000} closeCallback={() => setError()} />
        )}

        <Modal
          status={reachLimit}
          maxWidth="md"
          onClose={() => {
            setReachLimit(false);
          }}
          nonResponsive
          PaperProps={{
            style: {
              maxWidth: '700px'
            }
          }}
        >
          <div className="welcomeModal">
            <h2>
              Unlock more AI retro formats{' '}
              <span role="img" aria-label="" style={{ fontSize: '30px' }}>
                🚀
              </span>
            </h2>
            <div>
              {isActive ? (
                <div>
                  <p>Please upgrade your plan to unlock more AI generated retro formats.</p>
                </div>
              ) : (
                <div>
                  <p>You are currently on the Free plan which has limited access to our Retro Bot.</p>
                  <p>Please upgrade your plan to unlock more AI generated retro formats.</p>
                </div>
              )}

              <Button onClick={() => history.push('/plans')}>{isActive ? 'Compare Plans' : 'Update Plan'}</Button>
            </div>
          </div>
        </Modal>

        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              zIndex: 10000,
              display: 'block'
            }}
          >
            <Reward ref={rewardEl} type="emoji" config={config}>
              <span id="confetti-container"></span>
            </Reward>
          </div>,
          document.body
        )}
      </div>
    </Slide>
  );
}
export default RetroFormatBot;
