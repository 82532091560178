import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  emoji: {
    display: 'flex',
    padding: '0 4px',
    textAlign: 'center',
    fontSize: '18px',
    cursor: 'pointer',
    color: 'rgba(255, 255, 255, 1)',
    transform: 'scale(1)',
    transition: 'transform 0.15s cubic-bezier(0.2, 0, 0.13, 2)',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  }
}));

export const GithubSelectorEmoji = ({ onSelect, shortcode }) => {
  const classes = useStyles();

  const handleClick = (e) => {
    onSelect(shortcode);

    e.stopPropagation();
  };

  return (
    <div onClick={handleClick}>
      <div className={classes.emoji}>{shortcode}</div>
    </div>
  );
};

export default GithubSelectorEmoji;
