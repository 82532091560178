import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import apiService from 'utils/apiService';
import RangeSelection from 'modules/RangeSelection';
import PulseGrids from './PulseGrids';
import PulseCard from './PulseCard';
import Log from 'utils/Log';
import { sortByAverageValue } from 'utils/misc';

const rangeGroup = [
  { label: 'Lowest', value: 1 },
  { label: 'Highest', value: -1 },
  { label: 'Latest', value: 0 }
];

const OrgPulsePanel = ({ orgId, model = {}, isCompact, getOrgPulse, deletePulseModel, editPulseModel }) => {
  const history = useHistory();
  const { id, format = {} } = model;
  const [pulsesData, setPulsesData] = useState();
  const [range, setRange] = useState(1);

  const orderedPulsesData = sortByAverageValue({ data: pulsesData, range });

  const hasData = Boolean(Array.isArray(pulsesData) && pulsesData.filter(({ modelId }) => modelId).length > 0);

  useEffect(() => {
    if (orgId && id) {
      apiService
        .get(`/api/org/${orgId}/pulse?modelId=${id}`)
        .then(({ data }) => {
          setPulsesData(data);
        })
        .catch((e) => e);
    }
  }, [orgId, id]);

  if (!pulsesData) return null;

  return (
    <div>
      {!isCompact && (
        <PulseCard
          key={`pulse-card-${id}`}
          orgId={orgId}
          data={model}
          onDelete={deletePulseModel}
          onEdit={editPulseModel}
          callback={() => {
            getOrgPulse(orgId);
          }}
          hasData={hasData}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <div className="pulseTemplate">
          <div style={{ position: 'absolute', top: '0', left: '24px', background: '#fff', zIndex: 1 }}>
            <RangeSelection onChange={setRange} range={range} rangeGroup={rangeGroup} />
          </div>

          <PulseGrids
            orgId={orgId}
            modelData={format}
            pulsesData={orderedPulsesData}
            minPulse={20}
            isCompact={isCompact}
          />

          {isCompact && (
            <div style={{ textAlign: 'center', margin: '8px 0 0 0' }}>
              <span
                className="text-button"
                onClick={() => {
                  Log('Pulse', 'Explore');
                  history.push(`/pulse`);
                }}
              >
                Explore
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgPulsePanel;
