import React from 'react';

import orgPulseOverviewImg from 'images/img/org-pulse-promo.png';
import PulsePromoGrid from 'images/img/pulse-promo-grid.png';
import PulsePromoFull from 'images/img/pulse-promo-full.png';
import PulseDemoImg from 'images/img/pulse-demo.png';

import PulseCta from './PulseCta';

const PulsePromo = ({ isOrg, isTeam, isFullWidth, callback }) => {
  const renderImage = () => {
    if (isTeam && !isFullWidth) {
      return (
        <>
          <img
            src={PulsePromoGrid}
            alt=""
            style={{ width: '100%', height: '100%', position: 'absolute', top: '0', right: '0' }}
          />
          <img src={PulseDemoImg} alt="" style={{ width: '325px', position: 'absolute', bottom: '0' }} />
        </>
      );
    }

    if (isOrg || isFullWidth) {
      return (
        <>
          <img
            src={PulsePromoFull}
            alt=""
            style={{ width: '100%', height: '100%', position: 'absolute', top: '0', right: '0' }}
          />
          <img src={PulseDemoImg} alt="" style={{ width: '325px', position: 'absolute', bottom: '0' }} />
        </>
      );
    }
    return (
      <img
        src={orgPulseOverviewImg}
        alt=""
        style={{ maxWidth: '1128', width: '100%', position: 'absolute', top: '0', right: '0' }}
      />
    );
  };
  return (
    <div className={`dashboard-pulse ${isTeam ? 'dashboard-pulse--team' : ''} ${isOrg ? 'dashboard-pulse--org' : ''}`}>
      {renderImage()}
      <div className="dashboard-pulse--content">
        {(isOrg || isTeam) && (
          <>
            <h1>
              Check your <span>Pulse</span>
            </h1>
            <p>Send pulse surveys to your teams and uncover hidden issues quickly</p>
          </>
        )}
        <PulseCta isOrg={isOrg} isTeam={isTeam} callback={isOrg ? callback : null} />
      </div>
    </div>
  );
};

export default PulsePromo;
