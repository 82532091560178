import React, { useRef, useEffect, useMemo } from 'react';
import Reward from 'react-rewards';
import throttle from 'lodash/throttle';

import Paper from '@material-ui/core/Paper';

import Button from 'components/CustomButtons/Button';
import PulseGrids from './PulseGrids';
import PulseComments from './PulseComments';
import PulseStats from './PulseStats';

export default function PulseSummary({ id, format, pulseData, onEdit, isEnded, actions }) {
  const rewardEl = useRef(null);
  const { feedbackByTopic } = pulseData || {};
  const totalFeedback = feedbackByTopic && Object.values(feedbackByTopic).flat().length;

  const config = {
    elementCount: 200,
    spread: 180,
    startVelocity: 40,
    colors: ['#97A3FF', '#209EFF', '#F26C7C']
  };

  const rewardMe = useMemo(
    () => throttle(() => rewardEl.current?.rewardMe(), 1000, { leading: true, trailing: false }),
    []
  );

  const renderInsights = () => {
    return (
      <div
        id="retro-summary-modal"
        className={`retro-summary ${totalFeedback === 0 ? 'retro-summary--2-columns' : ''}`}
      >
        <div>
          <div className="clouds" style={{ opacity: 0.5 }}>
            <div
              className="cloud cloud-2 cloud-purple"
              style={{
                left: '-90px',
                bottom: '5%'
              }}
            ></div>
          </div>

          <div className="welcomeModal retro-summary-header">
            <h2>Summary</h2>
            <hr />
            <PulseStats id={id} pulseData={pulseData} actions={actions} />
            <p>Thanks for participating!</p>
            {!isEnded && (
              <p>
                <Button simple onClick={onEdit}>
                  Edit my answers
                </Button>
              </p>
            )}
          </div>
        </div>
        <div className="retro-summary-cards">
          <div>
            <h3>✨ Pulse Results</h3>
            <Paper className="pulseResults">
              <PulseGrids modelData={format} pulsesData={[pulseData]} isBoardView />
            </Paper>
          </div>
        </div>
        {totalFeedback > 0 && (
          <div className="retro-summary-actions">
            <h3>All comments</h3>
            <PulseComments pulseData={pulseData} topics={format?.topics} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isEnded) {
      rewardMe();
    }
  }, [isEnded, rewardMe]);

  return (
    <Paper>
      <div style={{ position: 'relative' }}>
        <div className="pulseSummary">{renderInsights()}</div>

        <div
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            zIndex: 10000,
            display: 'block'
          }}
        >
          <Reward ref={rewardEl} type="confetti" config={config}>
            <span id="confetti-container"></span>
          </Reward>
        </div>
      </div>
    </Paper>
  );
}
