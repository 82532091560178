import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { getRoleDisplayName, validateEmails } from 'utils/misc';

import AppContext from 'AppContext';
import NotificationBar from 'modules/NotificationBar';
import apiService from 'utils/apiService';
import Log from 'utils/Log';

const DEFAULT_ROLE = 'admin';

export default function OrgMembers({ getOrgMembers }) {
  const { org } = useContext(AppContext);
  const { id: orgId } = org || {};

  const [inviteModal, setInviteModal] = useState(false);
  const [inviteRole, setInviteRole] = useState(DEFAULT_ROLE);
  const [emails, setEmails] = useState('');
  const [validationError, setValidationError] = useState();
  const [error, setError] = useState();

  const onInviteModal = () => {
    setInviteModal((prev) => !prev);
  };

  const onInviteRoleChange = (e) => {
    const value = e.target.value;
    if (value) {
      setInviteRole(value);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    setEmails(value);
    validationError && setValidationError();
  };

  const sendInvite = () => {
    const emailsList = emails.split(',').map((email) => email.trim());
    const isValidEmails = validateEmails(emailsList);

    if (!isValidEmails) {
      setValidationError(true);
      return;
    }

    apiService
      .post(`/api/org/${orgId}/invite`, {
        emails: [...new Set(emailsList)],
        role: inviteRole
      })
      .then(() => {
        getOrgMembers();
        setInviteModal(false);
      })
      .catch((e) => {
        setError(true);
      });

    Log('Invite', 'org');
    Sentry.captureMessage(`Invite org - ${orgId}`);
  };

  useEffect(() => {
    if (!inviteModal) {
      setEmails('');
      setValidationError();
      setInviteRole(DEFAULT_ROLE);
    }
  }, [inviteModal]);

  return (
    <div className="panelAction">
      <Button onClick={onInviteModal}>+ Invite</Button>

      <Modal status={inviteModal} maxWidth="sm" onClose={onInviteModal} nonResponsive keepMounted={false}>
        <div id="org-user-modal" className="modalContainer">
          <h2>
            Invite organization admins{' '}
            <span role="img" aria-label="">
              🕊
            </span>
          </h2>

          <p>Invite organization admins to join by email.</p>
          <TableContainer>
            <Table className="invite-by-email" aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={emails}
                      inputProps={{
                        placeholder: 'Email'
                      }}
                      onChange={onChange}
                      error={validationError}
                      helperText={validationError ? 'Please enter a valid email address' : ''}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl variant="outlined">
                      <Select
                        style={{ fontSize: '15px' }}
                        value={inviteRole}
                        renderValue={(value) => getRoleDisplayName({ role: value, isOrgRole: true })}
                        onChange={onInviteRoleChange}
                      >
                        <MenuItem value="admin">
                          <div className="invite-by-email--select">
                            <h4>{getRoleDisplayName({ role: 'admin', isOrgRole: true })}</h4>
                            <p>- Create and manage teams in the organization</p>
                          </div>
                        </MenuItem>
                        <MenuItem value="owner">
                          <div className="invite-by-email--select">
                            <h4>Owner</h4>
                            <p>- Create and manage teams in the organization</p>
                            <p>- Manage organization admins</p>
                            <p>- Manage plans &amp; billing</p>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div>
            <Button onClick={sendInvite}>Send Invite</Button>
          </div>
          {error && (
            <NotificationBar
              message="Sorry something went wrong."
              type="error"
              closeCallback={() => setError()}
              rootId="org-user-modal"
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
