import React from 'react';

import Modal from 'components/Modal/Modal';

export default function BoardDisconnected() {
  return (
    <Modal status={true} maxWidth="xs" nonResponsive>
      <p style={{ textAlign: 'center' }}>
        <span role="img" aria-label="">
          ⚠️
        </span>
        You are not currently connected.
      </p>
      <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>Please refresh the page to reconnect.</p>
    </Modal>
  );
}
