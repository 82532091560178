import React from 'react';

import { ReactComponent as CountIcon } from 'images/icons/comment-count.svg';

const CardComments = ({ comments, onShowComments }) => {
  if (!comments || comments.length === 0) return null;

  return (
    <span className="commentsCount" onClick={onShowComments}>
      <CountIcon />
      <span>{comments.length}</span>
    </span>
  );
};
export default CardComments;
