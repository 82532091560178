import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { APP_ENDPOINT } from 'config';
import AppContext from 'AppContext';
import Loader from './Loader';
import EnterpriseWelcomeView from './EnterpriseWelcomeView';

export default function HomeRedirect() {
  const { org, isAuthenticated, userMeta } = useContext(AppContext);

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }
  }, [isAuthenticated]);

  if (org) {
    const orgTeams = org.teams;
    if (orgTeams.length === 1) {
      return <Redirect to={`/dashboard/team/${orgTeams[0]}`} />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  } else {
    if (userMeta && userMeta.orgs.length === 0 && userMeta.teams.length === 0) {
      return <EnterpriseWelcomeView />;
    } else {
      return <Loader />;
    }
  }
}
