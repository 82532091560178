import React, { useRef, useEffect, useState, useMemo } from 'react';
import Reward from 'react-rewards';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';

import Modal from 'components/Modal/Modal';

import MoodBar from 'modules/MoodBar';
import useMood from 'hooks/useMood';

const MoodResults = ({ id, teamId }) => {
  const [show, setShow] = useState(true);
  const { moodScore } = useMood({ id, teamId }); // values here are not up to date

  const rewardEl = useRef(null);

  const config = {
    elementCount: 30,
    spread: 140,
    startVelocity: 30,
    emoji: moodScore > 3.5 ? ['🎉', '☀️'] : moodScore > 2 ? ['😎', '🌤'] : ['☔️', '🌧']
  };

  const rewardMe = useMemo(
    () => throttle(() => rewardEl.current?.rewardMe(), 1000, { leading: true, trailing: false }),
    []
  );

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (rewardEl.current) {
      rewardMe();
    }
    // eslint-disable-next-line
  }, [rewardEl.current, rewardMe]);

  return (
    <Modal status={show} onClose={onClose} maxWidth="sm" nonResponsive keepMounted={false}>
      <div className="welcomeModal" onClick={rewardMe} style={{ marginBottom: '-24px' }}>
        <h2>Team Mood</h2>

        <MoodBar id={id} teamId={teamId} />

        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              zIndex: 10000,
              display: show ? 'block' : 'none'
            }}
          >
            <Reward ref={rewardEl} type="emoji" config={config}>
              <span id="confetti-container"></span>
            </Reward>
          </div>,
          document.body
        )}
      </div>
    </Modal>
  );
};

export default function RetroMood({ retroStates, id, teamId }) {
  const [showMood, setShowMood] = useState(false);

  useEffect(() => {
    if (retroStates && retroStates.showMood) {
      setShowMood(true);
    } else {
      setShowMood(false);
    }
  }, [retroStates]);

  return <>{showMood && <MoodResults id={id} teamId={teamId} />}</>;
}
