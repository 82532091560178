import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { SENTRY_DSN } from 'config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';

const logDsn = SENTRY_DSN || ''; ///"https://f6fe16583eef4b96ba6b780375811cf5@o395300.ingest.sentry.io/5246923"
const IS_DEBUG = Boolean(logDsn === '');
Sentry.init({
  dsn: logDsn,
  beforeSend: (event, hint) => {
    if (IS_DEBUG) {
      console.error(hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be sent to sentry
    }
    return event;
  },
  release: 'v0.0.4'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Temp remove console
if (process.env.NODE_ENV !== 'development') {
  console.log = function () {};
}
