import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Badge from '@material-ui/core/Badge';

import Modal from 'components/Modal/Modal';
import Button from 'components/CustomButtons/Button';
import { trimRight } from 'utils/misc';
import apiService from 'utils/apiService';

import AuthorVisibility from 'modules/AuthorVisibility';
import GifsVisibility from 'modules/GifsVisibility';

import Log from 'utils/Log';

import { ReactComponent as EditIcon } from 'images/icons/edit.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/settings.svg';

import ninjaImg from 'images/img/ninja.png';
import giphyImg from 'images/img/giphy-icon.png';

import { makeStyles } from '@material-ui/core/styles';
import BackgroundPicker from 'dashboard/BackgroundPicker';
import { getTeamLink } from 'utils/misc';
import NotificationBar from 'modules/NotificationBar';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 12px'
  },
  titleTextField: {
    width: '100%'
  },
  button: {
    cursor: 'pointer'
  }
}));

const MAX_COLUMN = 5;
const Settings = ({
  boardData,
  name,
  columns,
  isAnonymous,
  theme,
  backgroundUrl,
  onSettingsUpdate,
  titleEditor,
  setTitleEditor,
  columnEditor = false,
  setColumnEditor,
  disableGifs,
  onGifsUpdate,
  setBackground,
  setBackgroundUrl
}) => {
  const history = useHistory();
  const { id, compact, teamId, modelId } = boardData;
  const [open, setOpen] = useState(false);
  const boardAnonymity = Boolean(isAnonymous !== false);
  const isHideGifs = Boolean(disableGifs === true);
  const [displayName, setDisplayName] = useState(name);
  const [displayColumns, setDisplayColumns] = useState(columns);
  const [displayAnonymity, setDisplayAnonymity] = useState(boardAnonymity);
  const [previousTheme, setPreviousTheme] = useState(theme);
  const [previousBackgroundUrl, setPreviousBackgroundUrl] = useState(backgroundUrl);
  const [hideGifs, setHideGifs] = useState(isHideGifs);

  const [nameOnCardEditor, setNameOnCardEditor] = useState(false);
  const [gifsEditor, setGifsEditor] = useState(false);
  const [backgroundSelector, setBackgroundSelector] = useState(false);
  const [error, setError] = useState(false);
  const [imageError, setImageError] = useState(false);

  const panelEl = useRef(null);
  const classes = useStyles();
  const totalColumns = Array(MAX_COLUMN).keys(); // Array(compact ? MAX_COLUMN : columns.length).keys();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const onTitleChange = (e) => {
    const value = e.target.value;
    setDisplayName(value);
    //Log('Retro Settings', `title update`);
  };

  const onColumnChange = (e, i) => {
    const value = e.target.value;
    let newColumns = [...displayColumns];

    newColumns[i] = value;
    setDisplayColumns(newColumns);
    //Log('Retro Settings', `columns update`);
  };

  const onAnonymityChange = (e) => {
    //important, the radio returns string
    const value = e.target.value === 'true' ? true : false;
    setDisplayAnonymity(value);
    Log('Retro Settings', `Author Visibility: ${value}`);
  };

  const onGifsChange = (e) => {
    //important, the radio returns string
    const value = e.target.value === 'true' ? true : false;
    setHideGifs(value);
    Log('Retro Settings', `Gifs visibility: ${value}`);
  };

  const closeModal = () => {
    if (titleEditor) {
      setTitleEditor(false);
    }

    if (columnEditor) {
      setColumnEditor(false);
    }

    if (nameOnCardEditor) {
      setNameOnCardEditor(false);
    }

    if (gifsEditor) {
      setGifsEditor(false);
    }

    if (backgroundSelector) {
      setBackgroundSelector(false);
    }
  };

  const onCancelNameChange = () => {
    closeModal();
    setDisplayName(name);
  };

  const onCancelColumnsChange = () => {
    closeModal();
    setDisplayColumns(columns);
  };

  const onCancelAnonymityChange = () => {
    closeModal();
    setDisplayAnonymity(boardAnonymity);
  };

  const onCancelGifsChange = () => {
    closeModal();
    setHideGifs(isHideGifs);
  };

  const onCancelBackgroundChange = () => {
    closeModal();
    setBackground(previousTheme);
    setBackgroundUrl(previousBackgroundUrl);
  };

  const onSaveNameSetting = () => {
    apiService
      .post(`/api/team/${teamId}/board/${id}/settings`, {
        settings: {
          name: displayName
        }
      })
      .then(() => {
        onSettingsUpdate({ name: displayName });
        closeModal();
      })
      .catch((e) => {
        if (e) setError(true);
      });
  };

  const onSaveColumnsSetting = () => {
    let configCols = trimRight(displayColumns);

    if (configCols.length === 0) {
      configCols = [''];
    }

    apiService
      .post(`/api/team/${teamId}/board/${id}/settings`, {
        settings: {
          columns: configCols
        }
      })
      .then(() => {
        onSettingsUpdate({ columns: configCols });
        closeModal();
      })
      .catch((e) => {
        if (e) setError(true);
      });
  };

  const onSaveAnonymitySetting = () => {
    apiService
      .post(`/api/team/${teamId}/board/${id}/settings`, {
        settings: {
          isAnonymous: displayAnonymity
        }
      })
      .then(() => {
        onSettingsUpdate({ isAnonymous: displayAnonymity });
        closeModal();
      })
      .catch((e) => {
        if (e) setError(true);
      });
  };

  const onSaveBackgroundSetting = () => {
    apiService
      .post(`/api/team/${teamId}/board/${id}/settings`, {
        settings: {
          theme,
          backgroundUrl
        }
      })
      .then(() => {
        onSettingsUpdate({ theme, backgroundUrl });

        setPreviousTheme(theme);
        setPreviousBackgroundUrl(backgroundUrl);

        closeModal();
      })
      .catch((e) => {
        if (e) setError(true);
      });
  };

  const onSaveGifsSetting = () => {
    onGifsUpdate({ disableGifs: hideGifs });
    closeModal();
  };

  const renderColumnsEditor = (i) => {
    const cols = displayColumns;
    return (
      <div key={`create-board-column-${i}`} className="newBoardCols">
        <TextField value={(cols && cols[i]) || ''} id={`retro-col-${i}`} onChange={(e) => onColumnChange(e, i)} />
      </div>
    );
  };

  const updateBackground = (value) => {
    setBackground(value);
    setBackgroundUrl();
  };

  useEffect(() => {
    setDisplayName(name);
  }, [name]);

  useEffect(() => {
    setDisplayColumns(columns);
  }, [columns]);

  useEffect(() => {
    setDisplayAnonymity(boardAnonymity);
  }, [boardAnonymity]);

  useEffect(() => {
    setHideGifs(isHideGifs);
  }, [isHideGifs]);

  return (
    <>
      <div className="settingsPanel" onMouseLeave={handleClose}>
        <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
          <Badge badgeContent={null} color="primary" overlap="rectangular">
            <span style={{ display: 'flex' }}>
              <SettingsIcon />
            </span>
          </Badge>
        </div>
        <div className="actionPanelList">
          <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                <Paper>
                  <div className="actionPanelBoard">
                    <h3 onMouseOver={handleOpen}>Settings</h3>

                    <div className={classes.wrapper}>
                      <p>
                        <span className={classes.button} onClick={() => setTitleEditor(true)}>
                          Title
                          <span className="text-button">
                            <EditIcon />
                          </span>
                        </span>
                      </p>
                      {setColumnEditor && (
                        <p>
                          <span className={classes.button} onClick={() => setColumnEditor(true)}>
                            Columns
                            <span className="text-button">
                              <EditIcon />
                            </span>
                          </span>
                        </p>
                      )}
                      {/* Todo: need a better way to determin if this is pulse board instead of checking modelId */}
                      {!modelId && (
                        <p>
                          <span className={classes.button} onClick={() => setNameOnCardEditor(true)}>
                            Author Visibility
                            <span className="text-button">
                              <EditIcon />
                            </span>
                          </span>
                        </p>
                      )}
                      {onGifsUpdate && (
                        <p>
                          <span className={classes.button} onClick={() => setGifsEditor(true)}>
                            GIF Sharing
                            <span className="text-button">
                              <EditIcon />
                            </span>
                          </span>
                        </p>
                      )}
                      {setBackground && (
                        <p>
                          <span className={classes.button} onClick={() => setBackgroundSelector(true)}>
                            Background
                            <span className="text-button">
                              <EditIcon />
                            </span>
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <Modal status={titleEditor} maxWidth="sm" onClose={onCancelNameChange}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3>
              <span role="img" aria-label="">
                🗞
              </span>{' '}
              Title
            </h3>
            <TextField
              className={classes.titleTextField}
              id="retroName-name"
              inputProps={{ maxLength: 92 }}
              onChange={onTitleChange}
              value={displayName}
            />
          </div>
          <div className="plan-modal-actions">
            <span className="text-button" onClick={onCancelNameChange}>
              Cancel
            </span>
            <Button onClick={onSaveNameSetting}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <Modal status={columnEditor} maxWidth="sm" onClose={onCancelColumnsChange}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3>
              <span role="img" aria-label="">
                🖌
              </span>{' '}
              Columns
            </h3>
            <div className={`retroCols field ${compact ? '' : 'retroCols-tabs'}`}>
              {[...totalColumns].map((i) => renderColumnsEditor(i))}
              <p style={{ textAlign: 'left', fontSize: '15px', alignSelf: 'flex-start' }}>
                <span role="img" aria-label="">
                  💡
                </span>
                Use brackets for (descriptions)
              </p>
            </div>
          </div>
        </div>
        <div className="plan-modal-actions">
          <span className="text-button" onClick={onCancelColumnsChange}>
            Cancel
          </span>
          <Button onClick={onSaveColumnsSetting}>Confirm</Button>
        </div>
      </Modal>
      <Modal status={nameOnCardEditor} maxWidth="sm" onClose={onCancelAnonymityChange}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ninjaImg} alt="" style={{ width: '22px', height: '22px', padding: '2px' }} />
              <span>Author Visibility</span>
            </h3>
          </div>
          <div className="teamSettings-group">
            <AuthorVisibility value={displayAnonymity} onChange={onAnonymityChange} />
          </div>
        </div>

        <p style={{ textAlign: 'left', alignSelf: 'flex-start' }}>
          Applies to this retro only. Change defaults in:
          <span
            className="text-button"
            onClick={() => {
              Log('Click', 'board manage settings');

              history.push(getTeamLink({ teamId, menu: 'settings' }));
            }}
          >
            Team Settings
          </span>
        </p>

        <div className="plan-modal-actions">
          <span className="text-button" onClick={onCancelAnonymityChange}>
            Cancel
          </span>
          <Button onClick={onSaveAnonymitySetting}>Confirm</Button>
        </div>
      </Modal>
      <Modal status={gifsEditor} maxWidth="sm" onClose={onCancelGifsChange}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={giphyImg} alt="" style={{ height: '20px', padding: '2px 5px 0', marginLeft: '-30px' }} />

              <span>GIF Sharing</span>
            </h3>
          </div>
          <div className="teamSettings-group" style={{ padding: '24px 0px 24px 18px' }}>
            <GifsVisibility value={hideGifs} onChange={onGifsChange} />
          </div>
        </div>

        <div className="plan-modal-actions">
          <span className="text-button" onClick={onCancelGifsChange}>
            Cancel
          </span>
          <Button onClick={onSaveGifsSetting}>Confirm</Button>
        </div>
      </Modal>

      <BackgroundPicker
        backgroundSelector={backgroundSelector}
        setBackgroundSelector={setBackgroundSelector}
        updateBackground={updateBackground}
        setBackgroundUrl={setBackgroundUrl}
        error={imageError}
        setError={setImageError}
        isBoardSetting={true}
        onSave={onSaveBackgroundSetting}
        onCancel={onCancelBackgroundChange}
      />

      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </>
  );
};

export default Settings;
