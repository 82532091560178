import React, { useState } from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const PulseGridsMenu = ({ pulseData, onDeletePulse }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const { id, name } = pulseData;

  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <IconButton size="small" aria-label="settings" onClick={handleToggle}>
          <MoreHorizIcon />
        </IconButton>

        <Popover
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="menu-list-grow">
              <MenuItem className="action-delete" onClick={onRemoveModal}>
                Delete
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Popover>
      </div>

      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive>
        <div className="modalContainer">
          <h2>
            Delete Pulse Survey{' '}
            <span role="img" aria-label="" style={{ fontSize: '26px' }}>
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{name}</p>
          <p>Are you sure you want to delete this Pulse Survey?</p>
          <p>
            All data associated with this will be deleted and <br /> cannot be undone.
          </p>

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button
              color="danger"
              onClick={() => {
                onDeletePulse(id);
                onRemoveModal();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PulseGridsMenu;
