import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import apiService from 'utils/apiService';

function useSlack({ teamId, isFacilitator }) {
  const [slackData, setSlackData] = useState({});

  const postToSlack = async ({ data }) => {
    const { connectUrl, configurationUrl } = slackData;

    if (!configurationUrl && connectUrl) {
      window.location.assign(connectUrl);
    }
    return await apiService.post(`/api/team/${teamId}/slack-message`, { data }).catch((e) => {
      if (e?.response?.status === 404) {
        window.location.assign(connectUrl);
      }
    });
  };

  /****  slackData ****/
  /**********************************
  Example 1: with slack integration
  {
    configurationUrl: "xxxx"
    connectUrl: "xxxx"
  }  
  Example : without slack integration
   {
    connectUrl: "xxxx"
   }
  ***********************************/

  useEffect(() => {
    let cancel = false;

    if (!isFacilitator) return;

    apiService
      .get(`/api/team/${teamId}/slack-settings`)
      .then(({ data }) => {
        if (cancel) return;

        if (data) {
          setSlackData(data);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });

    return () => {
      cancel = true;
    };
  }, [teamId, isFacilitator]);

  return { ...slackData, postToSlack };
}

export default useSlack;
