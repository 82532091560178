import React, { useState, useEffect, useRef, useContext } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { format, formatDistanceToNowStrict } from 'date-fns';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ReactComponent as DeleteIcon } from 'images/icons/bin.svg';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Overflow from 'react-overflow-indicator';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';

import TextField from '@material-ui/core/TextField';

import AppContext from 'AppContext';
import { DEFAULT_ASSIGNEE } from 'utils/constant';
import { getDisplayName } from 'utils/misc';
import Log from 'utils/Log';

const DEFAULT_ASSIGNEE_NAME = 'Unassigned';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginBottom: '0'
  },
  avatarContainer: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    color: '#676767',
    fontWeight: 400
  },
  small: {
    width: '20px',
    height: '20px',
    fontSize: '14px',
    marginRight: '4px',
    cursor: 'pointer'
  },
  paper: {
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}));

const Assignee = ({ id, assignee, editAction, members = [], teamId, setKeepMenuOpen }) => {
  const classes = useStyles();
  const { isAuthenticated } = useContext(AppContext);
  const [assignedTo, setAssignedTo] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const isTeamMember = Boolean(members.length > 0); // non member are forbidden to get team info results a members of []
  const validAssignee = Boolean(members.filter((member) => member?.id === assignee)[0]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event, reason) => {
    setAnchorEl(null);
  };

  const onSelectAssignee = (event, member) => {
    const { id: assignee } = member || {};

    if (assignee) {
      editAction({ actionId: id, assignee });
      setAssignedTo(assignee);
      Log('Click', 'assign action');
    }
  };

  const onDeselectAssignee = (e) => {
    const assignee = DEFAULT_ASSIGNEE;

    editAction({ actionId: id, assignee });
    setAssignedTo(assignee);
    handleClose();

    e.stopPropagation();
  };

  const renderSelectedMember = () => {
    const selected = assignedTo;
    const member = members?.filter((member) => member?.id === selected)[0];
    const { profile } = member || {};
    const { picture } = profile || {};
    let name = getDisplayName(profile);
    let tooltip = '';

    if (isTeamMember && selected === DEFAULT_ASSIGNEE) {
      name = DEFAULT_ASSIGNEE_NAME;
    }

    if (!isTeamMember) {
      tooltip = 'Get invited to team to see assignees';
    }

    if (!isAuthenticated) {
      tooltip = 'Sign in to see assignees';
    }

    return (
      <Tooltip placement="top" title={name || tooltip}>
        <Avatar alt={name} src={picture} className={classes.small} onClick={handleClick} />
      </Tooltip>
    );
  };

  const renderMemberOption = (member, { selected }) => {
    const { id: memberId, profile } = member || {};
    const { picture } = profile || {};
    const name = getDisplayName(profile);

    return (
      <div key={memberId} value={memberId} style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar title={name} alt={name} src={picture} className={classes.small} />
        {name}
        {selected && (
          <Tooltip placement="top" title={'Unassign'}>
            <RemoveIcon onClick={onDeselectAssignee} fontSize="small" className="removeAssigneeBtn" />
          </Tooltip>
        )}
      </div>
    );
  };

  const sortedMembers = () => {
    return [...members].sort((a, b) => {
      if (a.id === assignedTo) return -1; // Move `a` to the start if it matches `assignedTo`
      if (b.id === assignedTo) return 1; // Move `b` to the start if it matches `assignedTo`
      return 0; // Keep the original order if neither element matches `assignedTo`
    });
  };

  useEffect(() => {
    if (isAuthenticated && isTeamMember && assignee && validAssignee) {
      setAssignedTo(assignee);
    } else {
      setAssignedTo(DEFAULT_ASSIGNEE);
    }
  }, [isAuthenticated, isTeamMember, assignee, validAssignee]);

  return (
    <div>
      {renderSelectedMember()}
      {isAuthenticated && isTeamMember && (
        <Popper anchorEl={anchorEl} open={open} placement="bottom" disablePortal style={{ zIndex: 2000 }}>
          <Autocomplete
            open
            disableClearable
            clearOnBlur
            disablePortal={Boolean(typeof setKeepMenuOpen === 'function')} // fix menu overflow issue in dashboard
            classes={{
              paper: classes.paper
            }}
            onOpen={() => {
              typeof setKeepMenuOpen === 'function' && setKeepMenuOpen(true);
            }}
            onClose={(e, reason) => {
              handleClose(e, reason);
              typeof setKeepMenuOpen === 'function' && setKeepMenuOpen(false);
            }}
            style={{ width: 300 }}
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            value={assignedTo}
            onChange={onSelectAssignee}
            getOptionSelected={(option, value) => {
              return option.id === value;
            }}
            renderInput={(params) => <TextField {...params} autoFocus className="asigneeSearchBox" />}
            options={sortedMembers()}
            getOptionLabel={(option) => getDisplayName(option?.profile) || ''}
            renderOption={renderMemberOption}
            noOptionsText="No results"
          />
        </Popper>
      )}
    </div>
  );
};

const ActionView = ({ action, editAction, removeAction, members, teamId, setKeepMenuOpen }) => {
  const { id, timeStamp, updatedTime, assignees = [] } = action;
  const [text, setText] = useState(action.text);
  const inputEl = useRef(null);
  const classes = useStyles();
  const modifiedTime = updatedTime || timeStamp;

  const onChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const submitAction = (e, isSave) => {
    // If Enter key & not shift key
    if ((e.keyCode === 13 && !e.shiftKey) || isSave) {
      editAction({ actionId: action.id, text });
      inputEl.current.blur();
    }
  };

  const renderTimeInfo = () => {
    const msg = formatDistanceToNowStrict(modifiedTime, { addSuffix: true });
    return msg === '0 seconds ago' ? 'Just now' : msg;
  };

  useEffect(() => {
    setText(action.text);
  }, [action.text]);

  return (
    <>
      <div style={{ width: '100%', paddingRight: '20px' }}>
        <TextareaAutosize
          ref={inputEl}
          value={text}
          onBlur={(e) => submitAction(e, true)}
          onKeyDown={submitAction}
          onChange={onChange}
          style={{ cursor: 'text' }}
        />

        <span
          style={{
            fontSize: '14px',
            color: '#676767',
            fontWeight: '400',
            margin: '4px 0 12px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Tooltip placement="top" title={format(modifiedTime, 'PP')}>
            <span style={{ marginRight: '8px', cursor: 'default' }}>{renderTimeInfo()}</span>
          </Tooltip>
          <Assignee
            id={id}
            assignee={assignees[0]}
            editAction={editAction}
            members={members}
            teamId={teamId}
            setKeepMenuOpen={setKeepMenuOpen}
          />
        </span>
      </div>

      <ListItemSecondaryAction>
        <Tooltip title="Delete this action" placement="top" classes={{ tooltip: classes.tooltip }}>
          <span className="close">
            <DeleteIcon onClick={() => removeAction(action)} />
          </span>
        </Tooltip>
      </ListItemSecondaryAction>
    </>
  );
};

const ActionItem = ({ items, title, updateAction, removeAction, editAction, members, teamId, setKeepMenuOpen }) => {
  const isOpen = Boolean(title === 'Open');
  const [shouldAnimate] = useState(true);
  const classes = useStyles();

  const renderDefaultView = () => {
    return (
      <div style={{ display: 'flex' }}>
        {/*eslint-disable-next-line*/}
        <div style={{ display: 'flex', margin: 'auto', padding: '0 12px', textAlign: 'center' }}>
          <div>
            <p>Actions are one of the best ways to improve the performance of your team. </p>
            <p>Keep actions specific and actionable.</p>
            <p style={{ marginBottom: 0 }}>
              Assign owners to each action item and monitor progress regularly.{' '}
              <span role="img" aria-label="">
                📝
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Overflow className="column-list">
      <Overflow.Content>
        <div className={`${isOpen ? 'column-list-open' : 'column-list-done'}`}>
          {isOpen && items.length === 0 && renderDefaultView()}
          <List>
            <TransitionGroup>
              {[...items].reverse().map((item) => (
                <CSSTransition
                  key={item.id + item.title}
                  classNames={`${isOpen ? 'open-animation' : 'resolve-animation'}`}
                  timeout={{ enter: 500, exit: 500 }}
                  enter={shouldAnimate}
                  exit={shouldAnimate}
                >
                  <ListItem dense disableGutters>
                    <Tooltip
                      title={isOpen ? 'Move to Done' : 'Move to Open'}
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span
                        onClick={() => {
                          updateAction(item);
                        }}
                        className={`column-list-action ${!isOpen ? 'column-list-action-resolved' : ''}`}
                      ></span>
                    </Tooltip>

                    <ActionView
                      action={item}
                      editAction={editAction}
                      removeAction={removeAction}
                      members={members}
                      teamId={teamId}
                      setKeepMenuOpen={setKeepMenuOpen}
                    />
                  </ListItem>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </List>
        </div>
      </Overflow.Content>
      <Overflow.Indicator direction="up">
        <div className="contentScroll-top"></div>
      </Overflow.Indicator>
    </Overflow>
  );
};

export default ActionItem;
