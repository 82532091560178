import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

import noteWithoutAuthor from 'images/img/note-without-author.png';
import noteWithAuthor from 'images/img/note-with-author.png';

export default function AuthorVisibility({ value, onChange }) {
  return (
    <RadioGroup row aria-label="layout" name="layout" value={value} onChange={onChange}>
      <FormControlLabel
        value={true}
        control={<Radio color="primary" />}
        label={
          <div className="">
            <h3>Hide author</h3>
            <p>Don’t show author information on notes</p>
            <img src={noteWithoutAuthor} alt="" />
          </div>
        }
        labelPlacement="end"
      />
      <FormControlLabel
        value={false}
        control={<Radio color="primary" />}
        label={
          <div className="">
            <h3>Show author</h3>

            <p>Show author information on notes.</p>
            <Tooltip title="Only shown to users who are signed in." placement="top">
              <img src={noteWithAuthor} alt="" />
            </Tooltip>
          </div>
        }
        labelPlacement="end"
      />
    </RadioGroup>
  );
}
