import React, { useState, useRef } from 'react';
import Log from 'utils/Log';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';

import EmailRetro from 'modules/EmailRetro';

const Summary = ({ id, onRetroSummary, teamId, membersCount, planId }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const showSummary = () => {
    onRetroSummary({
      showSummary: true
    });

    handleClose();
    Log('Summary', 'show');
  };

  return (
    <div className="retroControlPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <Badge badgeContent={null} color="primary" overlap="rectangular">
          Sharing
        </Badge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-end">
                  <h3 onMouseOver={handleOpen}>Sharing</h3>
                  <p>Show a retro summary to your team</p>

                  <span className="text-button" onClick={showSummary} style={{ margin: '10px auto 24px' }}>
                    Share summary
                  </span>

                  <p style={{ borderTop: '1px solid #dcdcdc', margin: '0 -12px', padding: '24px 0 16px' }}>
                    Export your retro content
                  </p>

                  <EmailRetro id={id} teamId={teamId} membersCount={membersCount} planId={planId} isLink />
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Summary;
