import React, { useState, useEffect, useCallback } from 'react';

import { nanoid } from 'nanoid';
import ContentEditable from 'react-contenteditable';
import { Reorder } from 'framer-motion/dist/framer-motion';
import TextField from '@material-ui/core/TextField';

import Button from 'components/CustomButtons/Button';
import DragOrder from 'modules/DragOrder';
import NotificationBar from 'modules/NotificationBar';
import { ReactComponent as DeleteIcon } from 'images/icons/bin.svg';
import SurveyView from './SurveyView';
import apiService from 'utils/apiService';
import EmojiPicker from './EmojiPicker';
import Log from 'utils/Log';

const DEFAULT_META = {
  intro: {
    icon: '👋',
    title: 'Welcome',
    description:
      'As we seek to improve our ways of working, we genuinely appreciate your honest feedback in this short survey.<br><br> You will be asked to rate each question between 1-5, and feel free to add optional comments. All responses are anonymous.<br><br>Thank you for your time and valuable input!'
  },
  end: {
    icon: '👀',
    title: 'Review',
    description:
      'If you’re happy with your answers, <b>submit</b> to see your team results.<br>You can edit your answers later until the survey is closed.'
  }
};

const DEFAULT_TOPICS = [
  {
    id: 'default-topic-1',
    icon: '🙂',
    title: 'Happiness',
    description:
      'Working at the company makes me feel content and fulfilled, with a positive work environment and colleagues who support and inspire me.'
  },
  {
    id: 'default-topic-2',
    icon: '📣',
    title: 'Communication',
    description:
      'I feel that the company provides transparent and effective communication, enabling me to stay informed and feel included.'
  },
  {
    id: 'default-topic-3',
    icon: '🌍',
    title: 'Culture',
    description:
      'I feel that the company promotes a diverse, inclusive, and positive workplace culture that makes me feel supported and valued.'
  },
  {
    id: 'default-topic-4',
    icon: '🌟',
    title: 'Leadership',
    description:
      'I feel that the company has strong and effective leadership, providing clear guidance and support for me to succeed in my role.'
  },
  {
    id: 'default-topic-5',
    icon: '🎉',
    title: 'Recognition',
    description:
      'The company recognizes and appreciates employee contributions, boosting my confidence and sense of accomplishment.'
  },
  {
    id: 'default-topic-6',
    icon: '🌱',
    title: 'Growth',
    description:
      'I feel that the company encourages and supports employee growth, providing opportunities for learning and development.'
  }
];

const PulseEditor = ({ orgId, modelId, data, callback }) => {
  const getData = useCallback(() => {
    const { title = '', format = {} } = data;
    const { meta = DEFAULT_META, topics = DEFAULT_TOPICS } = format;
    return { title, meta, topics };
  }, [data]);

  const [displayTitle, setDisplayTitle] = useState(getData().title);
  const [displayMeta, setDisplayMeta] = useState(getData().meta);
  const [displayTopics, setDisplayTopics] = useState(getData().topics);
  const [focusedId, setFocusedId] = useState();
  const [titleError, setTitleError] = useState();
  const [topicError, setTopicError] = useState();
  const [error, setError] = useState();

  const orderedTopics = displayTopics.map(({ id }) => id);

  const onChangeTitle = (e) => {
    const value = e.target.value;
    setDisplayTitle(value);
    titleError && setTitleError();
  };

  const handleChange = (e, save) => {
    let value;
    if (e && e.native) {
      value = e.native;
    } else {
      value = e.target.value.replace(/&nbsp;/g, ' ');
    }

    save(value);
  };

  const handleBlur = (e) => {
    e.target.classList.remove('pulseCard-topic--editing');
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleFocus = (e, id) => {
    e.target.classList.add('pulseCard-topic--editing');

    setFocusedId(id);
  };

  const onReorder = (topicsOrder) => {
    const newDisplayTopics = topicsOrder.map((topicId) => {
      return displayTopics.filter(({ id }) => id === topicId)[0];
    });
    setDisplayTopics(newDisplayTopics);
  };

  const onChangeMeta = (e, section, id) => {
    handleChange(e, (value) => {
      const newMeta = { ...displayMeta };

      newMeta[section][id] = value;

      setDisplayMeta(newMeta);
    });
  };

  const onChangeTopic = (e, type, id) => {
    handleChange(e, (value) => {
      const newTopics = displayTopics.map((topic) => {
        if (topic.id === id) {
          topic[type] = value;
        }
        return topic;
      });

      setDisplayTopics(newTopics);
      setFocusedId(id);
    });
  };

  const addTopic = () => {
    topicError && setTopicError();
    const id = nanoid();

    setDisplayTopics([...displayTopics, { id }]);
    setFocusedId(id);
  };

  const deleteTopic = (id) => {
    const updatedTopics = displayTopics.filter((topic) => topic.id !== id);

    setDisplayTopics(updatedTopics);
    setFocusedId(updatedTopics.length > 0 ? updatedTopics[updatedTopics.length - 1].id : 'intro');
  };

  const getTopicContent = (topicId) => {
    const data = displayTopics.filter(({ id }) => id === topicId)[0];
    const { id, title, icon } = data;

    return (
      <div key={`topic-${id}`} className="pulseCard-wrapper">
        <EmojiPicker
          icon={icon}
          onEmojiSelect={(e) => {
            onChangeTopic(e, 'icon', id);
          }}
        />
        <h3>
          <ContentEditable
            html={title || ''}
            onFocus={(e) => handleFocus(e, id)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeTopic(e, 'title', id)}
            className="pulseCard-topic pulseArea-editable"
            placeholder="Question"
          />
        </h3>
        <span className="pulseEditor-actions">
          <DeleteIcon onClick={() => deleteTopic(id)}>Delete</DeleteIcon>
        </span>
      </div>
    );
  };

  const renderTopic = (topicId, index) => {
    const content = getTopicContent(topicId, index);

    return <DragOrder key={`Order-of-${topicId}`} value={topicId} content={content} />;
  };

  const remoteSave = ({ isNew }) => {
    const newData = {
      meta: displayMeta,
      topics: displayTopics
    };

    if (!displayTitle) {
      setTitleError('Please give your survey a name');
      return;
    }

    if (displayTopics.length === 0) {
      setTopicError('Please add a question');
      return;
    }

    if (isNew) {
      apiService
        .put(`/api/org/${orgId}/pulse-model`, {
          title: displayTitle,
          format: newData
        })
        .then(() => {
          callback && callback();
        })
        .catch((e) => {
          setError(e);
        });

      Log('Pulse', 'Create');
    } else {
      apiService
        .post(`/api/org/${orgId}/pulse-model/${modelId}`, {
          title: displayTitle,
          format: newData
        })
        .then(() => {
          callback && callback();
        })
        .catch((e) => {
          setError(e);
        });

      Log('Pulse', 'Update');
    }
  };

  useEffect(() => {
    setDisplayTitle(getData().title);
    setDisplayMeta(getData().meta);
    setDisplayTopics(getData().topics);
  }, [getData]);

  return (
    <div className="boardMain boardMain-pulse ">
      <div className="PulseEditorWrapper">
        <div className="pulsePreview">
          <SurveyView
            meta={displayMeta}
            topics={displayTopics}
            focusedId={focusedId}
            onChangeMeta={onChangeMeta}
            onChangeTopic={onChangeTopic}
          />
        </div>

        <div className="pulseEditorArea">
          <div className="pulseCard">
            <div className="pulseCard-section">
              <label className="label">Title</label>
              <TextField
                id="pulseModel-name"
                value={displayTitle}
                inputProps={{ maxLength: 92 }}
                onChange={onChangeTitle}
                style={{ width: '100%', margin: '4px 0' }}
                error={Boolean(titleError)}
                helperText={titleError}
              />
            </div>

            <div className="pulseCard-section">
              <label className="label">Introduction</label>
              <div className="pulseCard-wrapper">
                <EmojiPicker
                  icon={displayMeta?.intro?.icon}
                  onEmojiSelect={(e) => {
                    onChangeMeta(e, 'intro', 'icon');
                  }}
                />
                <h3>
                  <ContentEditable
                    html={displayMeta?.intro?.title || ''}
                    onFocus={(e) => handleFocus(e, 'intro')}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      onChangeMeta(e, 'intro', 'title');
                    }}
                    className="pulseCard-topic pulseArea-editable"
                    placeholder="Welcome"
                  />
                </h3>
              </div>
            </div>

            <div className="pulseCard-section">
              <label className="label">Questions</label>
              <Reorder.Group axis="y" onReorder={onReorder} values={orderedTopics}>
                {orderedTopics.map(renderTopic)}
              </Reorder.Group>

              <div style={{ margin: '4px 0 0 0' }}>
                <span className="text-button" onClick={addTopic}>
                  + Add question
                </span>
                {topicError && (
                  <div style={{ fontSize: '12px', color: '#f44336', fontWeight: '600', margin: '12px 0 0 ' }}>
                    {topicError}
                  </div>
                )}
              </div>
            </div>

            <div className="pulseCard-section">
              <label className="label">Conclusion</label>
              <div className="pulseCard-wrapper">
                <EmojiPicker
                  icon={displayMeta?.end?.icon}
                  onEmojiSelect={(e) => {
                    onChangeMeta(e, 'end', 'icon');
                  }}
                />
                <h3>
                  <ContentEditable
                    html={displayMeta?.end?.title || ''}
                    onFocus={(e) => handleFocus(e, 'end')}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      onChangeMeta(e, 'end', 'title');
                    }}
                    className="pulseCard-topic pulseArea-editable"
                    placeholder="Review"
                  />
                </h3>
              </div>
            </div>

            <div className="pulseCard-section" style={{ textAlign: 'right', margin: '24px 0 0' }}>
              {modelId ? (
                <Button disabled={false} onClick={remoteSave}>
                  Update
                </Button>
              ) : (
                <Button disabled={false} onClick={() => remoteSave({ isNew: true })}>
                  Create
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
};

export default PulseEditor;

// const renderDimensions = () => {
//   return (
//     <div>
//       <div className="pulseModel--dimensions--scale">
//         <span className="pulseModel--dimensions--label">
//           <ContentEditable html={'🐰'} />
//         </span>
//         <span className="pulseModel--dimensions--label">🧖</span>
//         <span className="pulseModel--dimensions--label">🦄</span>
//       </div>
//       <div className="pulseModel--dimensions--scale">
//         <span className="pulseModel--dimensions--label">
//           <ContentEditable html={'1'} />
//         </span>
//         <span className="pulseModel--dimensions--label">2</span>
//         <span className="pulseModel--dimensions--label">3</span>
//       </div>
//     </div>
//   );
// };
