import React from 'react';

import { useHistory } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import Log from 'utils/Log';
import { Paper } from '@material-ui/core';

export default function ArchivedMsg({ name, teamId, isAuthenticated }) {
  const history = useHistory();

  const handleClick = () => {
    Log('Retro Settings', `Show free plan change - ${teamId} - view plans`);
    history.push('/plans');
  };

  return (
    <Paper>
      <div className="welcomeModal" style={{ padding: '8px 52px 36px', textAlign: 'center' }}>
        <h2>
          Archived Board{' '}
          <span role="img" aria-label="Hi">
            🔒
          </span>
        </h2>
        <h3 style={{ fontSize: '20px', fontWeight: '600' }}>{name}</h3>
        <p style={{ margin: '32px 0 12px 0', lineHeight: '150%' }}>
          Boards older than 14 days are automatically archived. <br /> To continue you will need to purchase a plan.
        </p>
        <p style={{ margin: '18px 0 -8px' }}>Save time and enjoy the full retro experience by upgrading today:</p>
        <ul className="welcomeModal--list">
          <li>
            <span role="img" aria-label="">
              📈
            </span>{' '}
            Create unlimited retros
          </li>
          <li>
            <span role="img" aria-label="">
              💌
            </span>{' '}
            Export retros via email
          </li>
          <li>
            <span role="img" aria-label="">
              😎
            </span>{' '}
            Setup private retros
          </li>
          <li>
            <span role="img" aria-label="">
              👥
            </span>{' '}
            Create multiple teams
          </li>
          <li>
            <span role="img" aria-label="">
              📊
            </span>{' '}
            Run pulse surveys
          </li>
          <li>
            <span role="img" aria-label="">
              🔐
            </span>{' '}
            Control access with Single Sign-On
          </li>
        </ul>

        <div style={{ margin: '12px 0 0 0' }}>
          {isAuthenticated ? (
            <Button onClick={handleClick}>View plans</Button>
          ) : (
            <Button href={'https://www.teleretro.com/pricing'}>View plans</Button>
          )}
        </div>
      </div>
    </Paper>
  );
}
