import React, { useState, useMemo } from 'react';
import Truncate from 'react-truncate';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import debounce from 'lodash/debounce';

import { ReactComponent as ExternalIcon } from 'images/icons/arrow.svg';
import apiService from 'utils/apiService';
import Log from 'utils/Log';

const useStyles = makeStyles({
  listbox: {
    margin: '8px 0',
    padding: 0
  },
  headerRoot: {
    background: '#fff'
  }
});

export default function Search({ teamId }) {
  const classes = useStyles();
  const [term, setTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState();

  const clearResults = () => {
    setResults([]);
    setLoading(false);
  };

  const onChange = (e, value, reason) => {
    if (value === '') {
      //This is important as search is debounced, also the debounce time need to be slightly longer than search debounce
      debouncedClear();
    }
  };

  const debouncedClear = useMemo(
    () =>
      debounce((value) => {
        clearResults();
      }, 510),
    []
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        apiService
          .get(`/api/search/team/${teamId}`, {
            params: {
              q: value
            }
          })
          .then(({ data }) => {
            if (data.length > 0) {
              setResults(data);
            } else {
              setResults(['']);
            }

            setLoading(false);
          })
          .catch((e) => e);
      }, 500),
    [teamId]
  );

  const onOpenSearchResult = () => {
    Log('Search', `open - ${term}`);
  };

  const renderResults = (card) => {
    const { id, boardId, text /* boardName, emojis, displayVotes */ } = card;

    if (!id) {
      return <div>No results</div>;
    }

    return (
      <div className="cardOverview" key={`card-${id}`}>
        <p className="cardContent">
          <a onClick={onOpenSearchResult} href={`/board/${boardId}`} target="_blank" rel="noopener noreferrer">
            {text}
            <span>
              <ExternalIcon />
            </span>
          </a>
        </p>
      </div>
    );
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setTerm(value);
    setLoading(true);

    if (value) {
      debouncedSearch(value);
    } else {
      clearResults();
    }
  };

  // const getHelperText = () => {
  //   if (!term || !Array.isArray(results)) return null;
  //   if (loading) return 'Searching...';

  //   if (results.length === 0) return 'No results';

  //   const notes = results.length === 1 ? 'result' : 'results';
  //   return `${results.length} ${notes}`;
  // };

  const renderGroup = (params, a, b, c) => {
    return [
      <ListSubheader key={params.key} component="div" classes={{ root: classes.headerRoot }}>
        <Tooltip title={params.group} placement="top" arrow enterDelay={1000}>
          <Truncate width={310}>{params.group}</Truncate>
        </Tooltip>
      </ListSubheader>,
      params.children
    ];
  };

  return (
    <div className="teamDashboard-search ">
      <div className="retroSearch">
        <Autocomplete
          freeSolo
          disablePortal
          fullWidth
          id="retros-search-box"
          getOptionLabel={() => term}
          renderOption={renderResults}
          options={results}
          groupBy={(option) => option.boardName}
          getOptionDisabled={(option) => typeof option === 'string'}
          renderGroup={renderGroup}
          loading={loading}
          loadingText="Searching..."
          onInputChange={onChange}
          classes={{ listbox: classes.listbox }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={term}
              variant="outlined"
              onChange={onSearch}
              placeholder="Search notes"
              FormHelperTextProps={{
                className: 'search-field-helper'
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
