import React, { useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';

import { DEFAULT_ASSIGNEE } from 'utils/constant';

import ActionItem from './ActionItem';
import './List.css';

/**
 * @description Main App component.
 * @constructor
 * @param {Object} props - The props that were defined by the caller of this component.
 */

const ActionsList = ({ notes, onSave, user, boardId, teamId, members, setKeepMenuOpen }) => {
  const id = 'action';
  const [items, setItems] = useState(notes);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showHelperText, setShowHelperText] = useState(false);
  const addEl = useRef(null);

  /*
    Can extent columns to includes all items { title: 'All', items, icon: <ListIcon /> }
  */
  const columns = [
    {
      title: 'Open',
      items: items.filter((item) => !item.status)
    },
    { title: 'Done', items: items.filter((item) => item.status === 1) }
  ];

  const getNewAction = (text) => {
    const uid = nanoid();
    const date = new Date();

    return {
      id: uid,
      text,
      status: 0,
      timeStamp: date.getTime(),
      createdBy: user?.id,
      addedIn: boardId
    };
  };

  /**
   * @description Add task to the To Do list.
   */
  const addAction = () => {
    const { value = '' } = addEl.current;

    if (value === '') return;

    const actions = [...items];
    const newAction = getNewAction(value);
    actions.push(newAction);

    setItems(actions);

    onSave({ boardId: id, notes: actions, newAction });
    addEl.current.value = '';
    setShowHelperText(false);
  };

  /**
   * @description Handle the enter key pressed under the add task input.
   * @param {Object} e - Key press event
   */
  const keyPress = (e) => {
    // If Enter key

    if (addEl.current && addEl.current.value) {
      setShowHelperText(true);
    } else {
      setShowHelperText(false);
    }
    if (e.keyCode === 13) {
      addAction();
    }
  };

  /**
   * @description Update task toggling between To Do/Done status.
   * @param {Object} task - The task to be updated
   */
  const updateAction = (task) => {
    const date = new Date();
    const filteredItems = items.filter((item) => item.id !== task.id);

    task.status = task.status ? 0 : 1;

    if (task.status === 1) {
      task.resovledIn = boardId;
    }

    task.updatedTime = date.getTime();
    task.updatedBy = user?.id;

    filteredItems.push(task);

    //setItems(filteredItems);
    onSave({ boardId: id, notes: filteredItems });
  };

  /**
   * @description Remove task.
   * @param {Object} task - The task to be removed.
   */
  const removeAction = (task) => {
    const filteredItems = items.filter((item) => item.id !== task.id);
    //setItems(filteredItems);
    onSave({ boardId: id, notes: filteredItems });
  };

  const editAction = ({ actionId, text, assignee }) => {
    const editedActions = items.map((action) => {
      if (action.id === actionId) {
        const date = new Date();
        if (text !== undefined) {
          action.text = text;
        }

        if (assignee) {
          if (assignee === DEFAULT_ASSIGNEE) {
            delete action.assignees;
          } else {
            action.assignees = [assignee];
          }
        }

        action.editedTime = date.getTime();
        action.updatedBy = user?.id;
      }
      return action;
    });
    onSave({ boardId: id, notes: editedActions });
  };

  const handleTabChange = (value) => {
    setActiveIndex(value);
  };

  useEffect(() => {
    if (Array.isArray(notes)) {
      setItems(notes);
    }
  }, [notes]);

  return (
    <div className="actions-container">
      <div className="actions-container-input" style={{ display: 'flex', alignItems: 'center', margin: '12px auto 0' }}>
        <TextField
          inputRef={addEl}
          variant="outlined"
          className="new-action-field"
          onKeyUp={keyPress}
          placeholder="Add an action"
          helperText={showHelperText ? 'Press enter to save' : ''}
          FormHelperTextProps={{
            className: 'new-action-field-helper'
          }}
        />
      </div>
      <Tabs onChange={(e, value) => handleTabChange(value)} value={activeIndex} centered>
        {columns.map((column, index) => (
          <Tab
            key={index}
            value={index}
            disableRipple={true}
            label={
              <div>
                {column.title} <span>{column.items.length}</span>
              </div>
            }
          />
        ))}
      </Tabs>

      <div className="action-lists">
        <SwipeableViews index={activeIndex} onChangeIndex={handleTabChange} style={{ width: '100%' }}>
          {columns.map((column, index) => {
            return (
              <div key={index}>
                <ActionItem
                  title={column.title}
                  items={column.items}
                  updateAction={updateAction}
                  removeAction={removeAction}
                  editAction={editAction}
                  members={members}
                  teamId={teamId}
                  setKeepMenuOpen={setKeepMenuOpen}
                />
              </div>
            );
          })}
        </SwipeableViews>
      </div>
    </div>
  );
};

export default ActionsList;
