import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import add from 'date-fns/add';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';
import { ReactComponent as SlackIcon } from 'images/icons/slack.svg';

import Tooltip from '@material-ui/core/Tooltip';

import ActionsList from 'dashboard/actions/ActionsList.js';
import Helper from 'dashboard/Helper';

import Log from 'utils/Log';

import AppContext from 'AppContext';
import apiService from 'utils/apiService';
import useSlack from 'hooks/useSlack';
import { APP_ENDPOINT } from 'config';

function Add({ onSave, actionNotes, user, boardId, teamId, isFacilitator }) {
  const openActions = actionNotes.filter((item) => !item.status);
  const { isAuthenticated } = useContext(AppContext);
  const { postToSlack } = useSlack({ teamId, isFacilitator });
  const [count, setCount] = useState(openActions.length);
  const [countBadge, setCountBadge] = useState(null);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState();
  const [keepMenuOpen, setKeepMenuOpen] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const panelEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (keepMenuOpen) {
      return;
    }

    setOpen(false);
  };

  const getActions = () => {
    const actions = openActions.map((note) => note.text);
    return actions.reverse().join('\n');
  };

  const hideActionsPanel = useCallback(() => {
    const actionPanel = document.querySelector('#actionDropdownPanel');

    if (actionPanel) {
      actionPanel.classList.add('actionPanelList-actions-hide');
    } else {
      Sentry.captureMessage('#actionDropdownPanel error');
    }
  }, []);

  const onCopy = () => {
    Log('Copy', 'actions');
  };

  const onSlack = async () => {
    if (openActions.length === 0) return;

    const elements = openActions.reverse().map(({ text }) => {
      return {
        type: 'rich_text_section',
        elements: [
          {
            type: 'text',
            text
          }
        ]
      };
    });

    const data = {
      blocks: [
        {
          type: 'rich_text',
          elements: [
            {
              type: 'rich_text_section',
              elements: [
                {
                  type: 'text',
                  text: 'Open Actions\n\n',
                  style: {
                    bold: true
                  }
                }
              ]
            },
            {
              type: 'rich_text_list',
              style: 'ordered',
              indent: 0,
              border: 0,
              elements
            }
          ]
        },
        {
          type: 'actions',
          elements: [
            {
              type: 'button',
              text: {
                type: 'plain_text',
                text: 'View in Dashboard'
              },
              url: `${APP_ENDPOINT}/dashboard/team/${teamId}`,
              action_id: 'view_team_dashboard',
              value: 'View Team Dashboard'
            }
          ]
        }
      ]
    };

    await postToSlack({ data });
  };

  useEffect(() => {
    const openActions = actionNotes.filter((item) => !item.status);
    setCount(openActions.length);
  }, [actionNotes]);

  useEffect(() => {
    const openActions = actionNotes.filter((item) => !item.status);
    const newCount = openActions.length;

    //Check the last added action is a fresh one
    if (!open && newCount - count === 1) {
      const lastAction = openActions[newCount - 1];
      const currentTime = new Date();
      const freshTime = add(new Date(lastAction.timeStamp), {
        minutes: 1
      });

      if (freshTime > currentTime) {
        setShowNotification(true);
      }
    }
  }, [actionNotes, count, open]);

  useEffect(() => {
    hideActionsPanel();
  }, [hideActionsPanel]);

  useEffect(() => {
    if (isAuthenticated && teamId) {
      apiService
        .get(`/api/team/${teamId}`)
        .then(({ data }) => {
          const { members } = data;
          setMembers(members);
        })
        .catch((e) => e);
    }
  }, [teamId, isAuthenticated]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountBadge(count);
    }, 300);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <div className="add-actions" onMouseLeave={handleClose}>
      <div ref={panelEl} className="actionPanel" onMouseOver={handleOpen}>
        <Badge badgeContent={countBadge} color="primary" overlap="rectangular">
          Actions
        </Badge>
      </div>

      {/* Important: bug in safari and firefox
        1. keepMounted to keep action textarea state
        2. disablePortal to keep correct open/close trigger
        3. actionPanelList-actions-show/hide to make sure dom is hide properly or it overlapping other content (drop, textarea) and notes can't be edited.        
      */}
      <div id="actionDropdownPanel" className={`actionPanelList ${open ? 'actionPanelList-actions-show' : ''}`}>
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal keepMounted>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }} onExited={hideActionsPanel}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-actions">
                  <h3 onMouseOver={handleOpen}>Actions</h3>
                  <div className="actionCopyBtn">
                    <Tooltip title="Copy actions to clipboard">
                      <CopyToClipboard text={getActions()} onCopy={onCopy}>
                        <IconButton>
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </CopyToClipboard>
                    </Tooltip>
                    {isFacilitator && (
                      <Tooltip title="Send actions to Slack">
                        <IconButton onClick={onSlack}>
                          <SlackIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>

                  <ActionsList
                    onSave={onSave}
                    notes={actionNotes}
                    user={user}
                    boardId={boardId}
                    teamId={teamId}
                    members={members}
                    setKeepMenuOpen={setKeepMenuOpen}
                  />
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {showNotification && (
        <Helper closeCallback={setShowNotification} message="📝 New Action added" autoHideDuration={3000} />
      )}
    </div>
  );
}

// Don't try to memo this component, the update actions won't work
export default Add;
