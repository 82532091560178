import React, { useState, useRef, useMemo, useContext } from 'react';
import ReactDOM from 'react-dom';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import throttle from 'lodash/throttle';

import Reward from 'react-rewards';
import AppContext from 'AppContext';

export default function WelcomeCustomer() {
  const { paymentMeta } = useContext(AppContext);

  const [welcomeModal, setWelcomeModal] = useState(true);
  const rewardEl = useRef(null);
  const currentLevel = paymentMeta && paymentMeta.plan?.nickname;
  const isBusiness = currentLevel?.includes('level2');
  const isEnterprise = currentLevel?.includes('level3');

  const config = {
    elementCount: 180,
    spread: 180,
    startVelocity: 40,
    colors: ['#97A3FF', '#209EFF', '#F26C7C']
  };

  const closeWelcomeModal = () => {
    setWelcomeModal(false);
    rewardMe();
  };

  const rewardMe = useMemo(
    () => throttle(() => rewardEl?.current?.rewardMe(), 3000, { leading: true, trailing: false }),
    [rewardEl]
  );

  return (
    <Modal
      status={welcomeModal}
      maxWidth="sm"
      onClose={closeWelcomeModal}
      nonResponsive
      PaperProps={{
        style: {
          maxWidth: '646px'
        }
      }}
    >
      <div className="welcomeModal" style={{ position: 'relative' }}>
        <div className="clouds" style={{ opacity: 0.5 }}>
          <div
            className="cloud cloud-small cloud-pink"
            style={{
              right: '-66px',
              top: '118px'
            }}
          ></div>
        </div>
        <div className="clouds" style={{ opacity: 0.5 }}>
          <div
            className="cloud cloud-1 cloud-pink"
            style={{
              left: '-70px',
              bottom: '-8px'
            }}
          ></div>
        </div>
        <h2>
          <span role="img" aria-label="Hi">
            🚀
          </span>{' '}
          Welcome to your new plan!
        </h2>
        <p style={{ margin: '32px 0 12px 0' }}>Here are some suggestions to make the most of your new plan:</p>
        <ul className="welcomeModal--list">
          <li>
            <span role="img" aria-label="">
              📈
            </span>{' '}
            Enjoy unlimited retros
          </li>
          <li>
            <span role="img" aria-label="">
              💌
            </span>{' '}
            Export retros via email
          </li>
          <li>
            <span role="img" aria-label="">
              😎
            </span>{' '}
            Setup private retros
          </li>
          <li>
            <span role="img" aria-label="">
              🤖
            </span>{' '}
            Custom generated retro formats
          </li>
          {(isBusiness || isEnterprise) && (
            <li>
              <span role="img" aria-label="">
                👥
              </span>{' '}
              Create multiple teams
            </li>
          )}
          {(isBusiness || isEnterprise) && (
            <li>
              <span role="img" aria-label="">
                📊
              </span>{' '}
              Run pulse surveys
            </li>
          )}
          {isEnterprise && (
            <li>
              <span role="img" aria-label="">
                🔐
              </span>{' '}
              Control access with Single Sign-On
            </li>
          )}
        </ul>

        <p>
          Need help or have an idea?
          <a href="mailto:hello@teleretro.com" className="text-button" style={{ marginRight: '6px' }}>
            Drop us a line
          </a>
          any time. 🙂
        </p>

        <div style={{ margin: '12px 0 0 0' }}>
          <Button onClick={closeWelcomeModal}>Let's go</Button>
        </div>
        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              zIndex: 10000,
              display: 'block'
            }}
          >
            <Reward ref={rewardEl} type="confetti" config={config}>
              <span id="confetti-container"></span>
            </Reward>
          </div>,
          document.body
        )}
      </div>
    </Modal>
  );
}
