import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import Log from 'utils/Log';
import apiService from 'utils/apiService';

import NotificationBar from './NotificationBar';
import Button from 'components/CustomButtons/Button';

const EmailRetro = ({ id, teamId, membersCount, planId, rootId, isLink }) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const noPlan = Boolean(!planId);

  const onEmail = (e, sendToTeam) => {
    error && setError();

    if (sendToTeam) {
      setLoading('team');
    } else {
      setLoading('me');
    }

    apiService
      .post(`/api/team/${teamId}/board/${id}/send-email`, {
        sendToTeam
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((e) => {
        setLoading();
        if (e) setError(true);
      });

    Log('Click', `email summary${sendToTeam ? ' team' : ''}`);
    e.stopPropagation();
  };

  useEffect(() => {
    setLoading();
  }, []);

  return (
    <div className="emailRetro">
      {loading !== false && (
        <Tooltip
          placement="top"
          title={noPlan ? 'Upgrade your plan to unlock this feature' : 'Send this retro to your inbox'}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              simple={!isLink}
              link={isLink}
              onClick={onEmail}
              disabled={loading === 'me' || noPlan}
              loading={loading === 'me'}
            >
              Email to me
            </Button>
          </div>
        </Tooltip>
      )}
      {loading !== false && (
        <Tooltip
          placement="top"
          title={
            noPlan
              ? 'Upgrade your plan to unlock this feature'
              : `Send this retro to all${membersCount ? ` ${membersCount}` : ''} ${
                  membersCount > 1 ? 'members' : 'member'
                }`
          }
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              simple={!isLink}
              link={isLink}
              onClick={(e) => onEmail(e, true)}
              disabled={loading === 'team' || noPlan}
              loading={loading === 'team'}
            >
              Email to team
            </Button>
          </div>
        </Tooltip>
      )}
      {loading === false && (
        <div className="emailRetro-text" style={{ textAlign: 'center' }}>
          This retro has been sent
          <span style={{ display: 'block', fontSize: '28px' }} role="img" aria-label="">
            💌
          </span>
        </div>
      )}

      {error && (
        <NotificationBar
          message="Sorry something went wrong"
          type="error"
          closeCallback={() => setError()}
          rootId={rootId}
        />
      )}
    </div>
  );
};

export default EmailRetro;
