import React from 'react';

export default function NoMatch() {
  return (
    <div className="staticPage-container">
      <div className="staticPage-content">
        <h3>Sorry we can’t find this page</h3>
      </div>
    </div>
  );
}
