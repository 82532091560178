import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

import AppContext from 'AppContext';
import { APP_ENDPOINT } from 'config';

import { checkPermission, getRole } from 'roles/checkPermission';
import apiService from 'utils/apiService';

import OrgInvite from './OrgInvite';
import OrgMember from './OrgMember';
import InvitedMembers from './InvitedMembers';

import OrgLinks from './OrgLinks';
import { sortRole } from 'utils/misc';

export default function OrgMembers() {
  const history = useHistory();
  const { org, isAuthenticated, userMeta } = useContext(AppContext);
  const { id: orgId, name } = org || {};
  const [membersInfo, setMembersInfo] = useState([]);
  const { members = [], invitations = [] } = membersInfo;
  const { role } = getRole({ orgId, userMeta });
  const canEditMember = checkPermission(role, 'org:edit');

  const getOrgMembers = useCallback(() => {
    if (orgId) {
      apiService
        .get(`/api/org/${orgId}/members`)
        .then(({ data }) => {
          setMembersInfo(data);
        })
        .catch((e) => {
          Sentry.captureException(e);
          if (e?.response?.status === 403) {
            history.push('/dashboard');
          }
        });
    }
  }, [orgId, history]);

  const membersView = () => {
    const primaryOwner = members[0];

    return members
      .sort(sortRole)
      .map((member, index) => (
        <OrgMember
          key={`org-${orgId}-member-${index}`}
          orgId={orgId}
          member={member}
          canEditMember={canEditMember}
          getOrgMembers={getOrgMembers}
          isPrimaryOwner={Boolean(member?.id === primaryOwner?.id)}
        />
      ));
  };

  const invitationsView = () => {
    return invitations.map((invite, index) => (
      <InvitedMembers
        key={`org-${orgId}-invited-member-${index}`}
        orgId={orgId}
        invite={invite}
        canEditMember={canEditMember}
        removeInvite={() => {}}
        getOrgMembers={getOrgMembers}
      />
    ));
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getOrgMembers();
  }, [getOrgMembers]);

  return (
    <div className="team-container">
      <div className="clouds" style={{ opacity: 0.5 }}>
        <div
          className="cloud cloud-small cloud-pink"
          style={{
            right: '1%',
            top: '150px'
          }}
        ></div>

        <div
          className="cloud cloud-small cloud-pink"
          style={{
            left: '-8px',
            top: '380px'
          }}
        ></div>
      </div>
      <div className="team-container-header">
        <h2>
          {name ? (
            name
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton width={'180px'} height={44} />
            </div>
          )}
        </h2>
        <p>Manage your organization’s admins</p>
        <hr />
        <OrgLinks current={'/admins'} />
      </div>
      <Paper className="teamMembers">
        <TableContainer>
          {canEditMember && <OrgInvite getOrgMembers={getOrgMembers} />}

          <Table className="teamMembers-table" aria-label="Org Members Table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{membersView()}</TableBody>
            <TableBody>{invitationsView()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
