import React from 'react';
import Truncate from 'react-truncate';

import { getMoodColour } from 'utils/misc';

const PulseComments = ({ topics, pulseData }) => {
  const renderFeedback = ({ id, title, icon }) => {
    const { pulseId, feedbackByTopic = {} } = pulseData;
    const feedback = feedbackByTopic[id];

    if (!feedback) return null;

    const feedbackPerTopic = (data, index) => {
      const { feedback, value } = data;
      return (
        <div className="surveyComment" key={`${pulseId}-${id}-feedback-${index}`}>
          <p>{feedback?.trim()}</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ lineHeight: '16px', marginRight: '4px' }}>{icon}</span>{' '}
            <Truncate width={160}>{title}</Truncate>
            <div className="surveyValue" style={{ backgroundColor: getMoodColour(value) }}>
              {value}
            </div>
          </div>
        </div>
      );
    };

    return <div key={`${pulseId}-${id}-feedback`}>{feedback.map(feedbackPerTopic)}</div>;
  };

  if (!pulseData) return null;

  return <div className="pulseComments">{topics.map(renderFeedback)}</div>;
};

export default PulseComments;
