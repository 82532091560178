import React, { useState, useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from 'AppContext';
import apiService from 'utils/apiService';
import NotificationBar from 'modules/NotificationBar';

import { getRoleDisplayName, getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '30px',
    height: '30px',
    fontSize: '0.8rem',
    marginLeft: '80%'
  },
  role: {
    minWidth: '100px',
    display: 'inline-block'
  }
}));

export default function OrgMember({ orgId, member, canEditMember, getOrgMembers, isPrimaryOwner }) {
  const classes = useStyles();
  const { profile = {} } = useContext(AppContext);
  const { id, role, profile: memberProfile } = member;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [roleModal, setRoleModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const [newRole, setNewRole] = useState(role);
  const [error, setError] = useState();

  const { email, picture } = memberProfile;
  const displayName = getDisplayName(memberProfile);
  const detailedName = displayName === email ? displayName : `${displayName} (${email})`;

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    apiService
      .delete(`/api/org/${orgId}/member/${id}`)
      .then(() => {
        getOrgMembers();
        onRemoveModal();
      })
      .catch((e) => {
        setError(true);
      });
  };

  const onPermissionUpdate = (e) => {
    const value = e.target.value;
    if (value) {
      setNewRole(value);
    }
  };

  const cancelPermissionUpdate = () => {
    setNewRole(role);
    onRoleModal();
  };

  const submitPermissionUpdate = () => {
    apiService
      .post(`/api/org/${orgId}/user-meta`, {
        id,
        role: newRole
      })
      .then(() => {
        getOrgMembers();
        onRoleModal();
      })
      .catch(() => {
        setError(true);
      });
  };

  const onRoleModal = () => {
    setRoleModal((prev) => !prev);
    handleClose();
  };
  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Avatar alt={displayName} src={picture} className={classes.small} />
        </TableCell>
        <TableCell>{displayName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          <span className={classes.role}>{getRoleDisplayName({ role, isOrgRole: true })}</span>{' '}
          {canEditMember && (
            <>
              <IconButton aria-label="settings" onClick={handleToggle} disabled={id === profile.sub || isPrimaryOwner}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow">
                    <MenuItem onClick={onRoleModal}>Change Role</MenuItem>
                    <MenuItem onClick={onRemoveModal}>Remove</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Popover>
            </>
          )}
        </TableCell>
      </TableRow>

      <Modal status={roleModal} maxWidth="sm" onClose={cancelPermissionUpdate} nonResponsive keepMounted={false}>
        <div id="org-user-modal" className="modalContainer">
          <h2>
            Change Role{' '}
            <span role="img" aria-label="">
              👷‍♀️
            </span>
          </h2>
          <p className="modalContainer-header">{detailedName}</p>
          <div className="modalContainer-form">
            <RadioGroup row aria-label="layout" name="layout" value={newRole} onChange={onPermissionUpdate}>
              <FormControlLabel
                value="admin"
                control={<Radio color="primary" />}
                label={
                  <div className="">
                    <h3>{getRoleDisplayName({ role: 'admin', isOrgRole: true })}</h3>
                    <p>- Create and manage teams in the organization</p>
                  </div>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="owner"
                control={<Radio color="primary" />}
                label={
                  <div className="">
                    <h3>Owner</h3>
                    <p>- Create and manage teams in the organization</p>
                    <p>- Manage organization admins</p>
                    <p>- Manage plans &amp; billing</p>
                  </div>
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          <div className="modalContainer-actions">
            <span className="text-button" onClick={cancelPermissionUpdate}>
              Cancel
            </span>

            <Button onClick={submitPermissionUpdate}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive keepMounted={false}>
        <div id="org-user-modal" className="modalContainer">
          <h2>
            Remove User{' '}
            <span role="img" aria-label="">
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{detailedName}</p>
          <p>Are you sure you want to remove this person from your organization?</p>
          <p>They will no longer be able to access your organization’s content.</p>

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button onClick={onDelete}>Confirm</Button>
          </div>
        </div>
      </Modal>
      {error && (
        <NotificationBar
          message="Sorry something went wrong."
          type="error"
          closeCallback={() => setError()}
          rootId="org-user-modal"
        />
      )}
    </>
  );
}
