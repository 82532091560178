import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactDOM from 'react-dom';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Log from 'utils/Log';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: '48px',
    right: '12px',
    top: 'auto',
    alignItems: 'end'
  },
  message: {
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    },
    fontSize: '17px'
  },
  button: {
    margin: '0 -4px 0 4px'
  }
}));

const SLIDE_TIMEOUT = 500;
const ONBOARD_COOKIE_NAME = 'capterraReviewPromo'; //onborad msg received

function TransitionLeft(props) {
  return <Slide {...props} direction="left" timeout={SLIDE_TIMEOUT} mountOnEnter unmountOnExit />;
}

const message = 'Share Your Thoughts & Earn $15';

export default function ReviewPromoMsg() {
  const [cookies, setCookie] = useCookies([ONBOARD_COOKIE_NAME]);
  const [open, setOpen] = useState(!cookies[ONBOARD_COOKIE_NAME]);
  const [transition] = useState(() => TransitionLeft);
  const container = document.getElementById('root');

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (!cookies[ONBOARD_COOKIE_NAME]) {
      setCookie(ONBOARD_COOKIE_NAME, true, { path: '/', sameSite: 'lax', maxAge: 60 * 60 * 24 * 120 }); //120 days
    }
  };

  const onGift = () => {
    if (open) {
      handleClose();
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (open) {
      Log('ShowReviewPromo');
    }
  }, [open]);

  if (!container) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <Snackbar
          classes={{ root: classes.root }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          TransitionComponent={transition}
        >
          <Alert classes={{ message: classes.message }} icon={false} severity={'success'} variant="filled">
            <a
              href="https://reviews.capterra.com/new/214521/1a32b9c8-0aa2-4701-9979-e6df0303f6a4"
              target="_blank"
              rel="noopener noreferrer"
            >
              {message}
            </a>
            <IconButton
              className={classes.button}
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </Alert>
        </Snackbar>,
        container
      )}
      <div
        onClick={onGift}
        style={{ position: 'fixed', bottom: '12px', right: '24px', fontSize: '20px', cursor: 'pointer' }}
      >
        🎁
      </div>
    </>
  );
}
