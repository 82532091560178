import React, { useState, useEffect, useContext } from 'react';

import apiService from 'utils/apiService';

import SurveyView from './SurveyView';
import AppContext from 'AppContext';
import NotificationBar from 'modules/NotificationBar';
import PulseSummary from './PulseSummary';

const BoardSurvey = ({ surveyData, retroStates, actions }) => {
  const { profile } = useContext(AppContext);
  const { id: pulseId, format } = surveyData;

  const { meta, topics } = format;

  const [pulseData, setPulseData] = useState(surveyData.pulseData);
  const submittedData = pulseData?.submittedData;
  const [submitted, setSubmitted] = useState(Boolean(submittedData));
  const [error, setError] = useState();

  const { pulse } = retroStates || {};
  const isEnded = Boolean(pulse === false);

  const onSubmit = (data) => {
    error && setError();
    apiService
      .post(`/api/pulse-survey/${pulseId}`, {
        uid: profile.sub,
        data
      })
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        if (e) {
          setError(e);
        }
      });
  };

  const onEdit = () => {
    setSubmitted(false);
  };

  const renderSurveys = () => {
    if (!pulseData) return null;

    return (
      <PulseSummary
        id={pulseId}
        format={format}
        pulseData={pulseData}
        onEdit={onEdit}
        isEnded={isEnded}
        actions={actions}
      />
    );
  };

  useEffect(() => {
    if (submitted) {
      apiService
        .get(`/api/pulse-survey/${pulseId}`)
        .then(({ data }) => {
          const { pulseData } = data;
          setPulseData(pulseData);
        })
        .catch((e) => {
          if (e) {
            setError(e);
          }
        });
    }
  }, [submitted, pulseId]);

  return (
    <div className="surveyWrapper">
      {submitted || isEnded ? (
        <>{renderSurveys()}</>
      ) : (
        <SurveyView id={pulseId} meta={meta} topics={topics} onSubmit={onSubmit} submittedData={submittedData} />
      )}

      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
};

export default BoardSurvey;
