import React, { useState, useRef } from 'react';
import Log from 'utils/Log';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import StyledBadge from './StyledBadge';

const MaskComments = ({ id, onMaskComments, maskComments }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleClick = ({ maskComments }) => {
    onMaskComments({
      maskComments
    });

    setOpen(false);

    Log('Brainstorming', maskComments);
  };

  return (
    <div className="brainstormingPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={Boolean(!maskComments)} overlap="rectangular">
          Brainstorming
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Brainstorming</h3>
                  <p>Only allow participants to see their own notes</p>
                  <div>
                    {maskComments ? (
                      <span
                        className="text-button"
                        onClick={() =>
                          handleClick({
                            maskComments: false
                          })
                        }
                      >
                        End Brainstorming
                      </span>
                    ) : (
                      <span
                        className="text-button"
                        onClick={() =>
                          handleClick({
                            maskComments: true
                          })
                        }
                      >
                        Start Brainstorming
                      </span>
                    )}
                  </div>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default MaskComments;
