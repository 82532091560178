import React from 'react';
import isEqual from 'lodash/isEqual';

function ColoumnHeading({ text }) {
  const leftBracketPosition = text && text.indexOf('(');
  const rightBracketPosition = text && text.lastIndexOf(')');

  if (text && text.includes('(') && text.includes(')') && rightBracketPosition > leftBracketPosition) {
    const heading = text.substring(0, leftBracketPosition);

    const subHeading = text.substring(leftBracketPosition + 1, rightBracketPosition);

    return (
      <>
        {heading}
        <span>{subHeading}</span>
      </>
    );
  } else {
    return text;
  }
}

export default React.memo(ColoumnHeading, (prev, next) => {
  return isEqual(prev.text, next.text);
});
