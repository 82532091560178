import React from 'react';
// material-ui components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// @material-ui/icons
import Close from '@material-ui/icons/Close';

import makeStyles from '@material-ui/core/styles/makeStyles';
import modalStyle from 'assets/jss/material-kit-react/modalStyle.js';
import { useTheme } from '@material-ui/core/styles';

const makeComponentStyles = makeStyles(() => ({
  ...modalStyle
}));

export default function Modal({
  status,
  children,
  onClose,
  maxWidth,
  nonResponsive,
  PaperProps,
  keepMounted = true,
  disableEscapeKeyDown
}) {
  const classes = makeComponentStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')) && !nonResponsive;
  return (
    <Dialog
      open={status}
      keepMounted={keepMounted}
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
      fullScreen={fullScreen}
      fullWidth={!fullScreen}
      maxWidth={maxWidth || 'md'}
      PaperProps={PaperProps}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {onClose && (
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => onClose()}
        >
          <Close className={classes.modalClose} />
        </IconButton>
      )}
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
