import React from 'react';

export const MoodSelectorEmoji = ({ onSelect, shortcode, value, selectedValue, hover, active }) => {
  const handleClick = (e) => {
    onSelect(value);
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e);
    }
  };

  return (
    <div>
      <div
        className={selectedValue === value ? 'moodSelectorEmoji-selected' : ''}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <div className="moodSelectorEmoji-element" tabIndex="0">
          {shortcode}
        </div>
      </div>
      <div style={{ fontSize: '25px' }}>{value}</div>
    </div>
  );
};

export default MoodSelectorEmoji;
