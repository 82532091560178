//import * as Sentry from '@sentry/browser';

const Log = (type, meta) => {
  try {
    window.plausible(type, { props: { meta } });
    //Sentry.captureMessage(`${type}: ${meta}`);
  } catch (e) {}
};

export default Log;
