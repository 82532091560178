import React from 'react';
import { nanoid } from 'nanoid';

import Modal from 'components/Modal/Modal';
import 'dashboard/NewBoard.css';
import PulseEditor from './PulseEditor';

const PulseModel = ({ orgId, modelId, pulseModels, closeModal, callback }) => {
  const id = modelId || nanoid();
  const data = modelId && Array.isArray(pulseModels) ? pulseModels.filter(({ id }) => id === modelId)[0] : { id };

  return (
    <Modal
      status={true}
      maxWidth="lg"
      onClose={closeModal}
      PaperProps={{
        className: 'pulseEditor-modal'
      }}
      disableEscapeKeyDown
    >
      <PulseEditor key={id} orgId={orgId} data={data} modelId={modelId} callback={callback} />
    </Modal>
  );
};

export default PulseModel;
