import React, { useState, useEffect } from 'react';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    top: '0',
    zIndex: '20'
  },
  message: {
    fontSize: '16px',
    padding: '7px 0'
  }
}));

function TransitionLeft(props) {
  return <Slide {...props} direction="down" timeout={300} mountOnEnter unmountOnExit />;
}

const VotingMenu = ({ votingStates = {}, progress, remainingVotes }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(Boolean(votingStates.status === 'vote'));
  const [transition, setTransition] = useState();
  const completedCount = progress || '0';

  const renderVotingView = () => (
    <>
      <span role="img" aria-label="">
        🗳
      </span>{' '}
      <span className="votingProgressBar">
        <span className="votings-numbers">{completedCount}</span> {completedCount === 1 ? 'person' : 'people'} finished
        voting {' | '}
      </span>{' '}
      Your remaining votes: <span className="votings-numbers">{remainingVotes}</span>
    </>
  );

  useEffect(() => {
    setOpen(Boolean(votingStates.status === 'vote'));
  }, [votingStates]);

  useEffect(() => {
    setTransition(() => TransitionLeft);
  }, []);

  return (
    <Snackbar
      classes={{ root: classes.root }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      TransitionComponent={transition}
    >
      <Alert classes={{ message: classes.message }} icon={false} severity="info" variant="filled">
        <span>{renderVotingView()}</span>
      </Alert>
    </Snackbar>
  );
};

export default VotingMenu;
