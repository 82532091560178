import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { makeStyles } from '@material-ui/core/styles';

import apiService from 'utils/apiService';
import { getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '22px'
  }
}));

const cachedProfiles = {};

const UserNames = ({ userIds }) => {
  const classes = useStyles();
  const lastIndex = userIds.length - 1;
  const [userProfiles, setUserProfiles] = useState({});
  const [ready, setReady] = useState(false);

  const renderName = (userId, index) => {
    if (!userProfiles[userId]) return null;

    const displayName = getDisplayName(userProfiles[userId]);

    const renderBreak = () => {
      return index === lastIndex ? '' : ', ';
    };

    if (displayName === undefined) return null;

    return (
      <span key={`user-emoji-name-${index}`}>
        {displayName}
        {renderBreak()}
      </span>
    );
  };

  useEffect(() => {
    userIds.forEach((userId) => {
      if (cachedProfiles[userId]) {
        setUserProfiles({ ...cachedProfiles });
        return;
      }

      apiService
        .get(`/api/${userId}/profile`)
        .then(({ data }) => {
          cachedProfiles[userId] = data;
          setUserProfiles({ ...cachedProfiles });
        })
        .catch((e) => e);
    });
  }, [userIds]);

  useEffect(() => {
    const waitsIds = userIds.filter((userId) => !userProfiles[userId]);

    if (waitsIds.length > 0) {
      setReady(false);
    } else {
      setReady(true);
    }
  }, [userProfiles, userIds]);

  return <div className={classes.root}>{ready ? userIds.map(renderName) : <span>Loading...</span>}</div>;
};

export default React.memo(UserNames, (prev, next) => {
  return isEqual(prev.userIds, next.userIds);
});
