import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

import AppContext from 'AppContext';
import { getArrayByKey } from 'utils/misc';

import Button from 'components/CustomButtons/Button';
import NotificationBar from 'modules/NotificationBar';
import useSsoConnection from 'hooks/useSsoConnection';

export default function InvitePage() {
  const history = useHistory();
  const { teamId, inviteId } = useParams();
  const { isAuthenticated, userMeta } = useContext(AppContext);
  const [teamName, setTeamName] = useState();
  const { loginUrl } = useSsoConnection({ teamId, isDeepLink: true });

  const [isValid, setIsValid] = useState();
  const [error, setError] = useState();
  const [hint, setHint] = useState();
  const teams = getArrayByKey(userMeta?.teams, 'id');

  const onJoin = () => {
    axios
      .post(`/api/team/${teamId}/join`, {
        inviteId
      })
      .then(() => {
        // Important: update or joined team won't show, userMeta is not updated seems ok
        //switchOrg({orgId});
        //history.replace('/dashboard');

        window.location.pathname = '/';
      })
      .catch((e) => {
        if (e?.response?.status === 405) {
          setHint('You organization has SSO enabled. Please sign out and sign in with SSO.');
        }
        setError(true);
        Sentry.captureException(e);
      });
  };

  const renderNotValid = () => {
    return (
      <div className="welcomeModal">
        <h2>
          Oops{' '}
          <span role="img" aria-label="">
            😔
          </span>
        </h2>
        <p>This invite link is no longer valid, please contact your team to get a new invite link. </p>
      </div>
    );
  };

  const renderInviteView = () => {
    return (
      <div className="welcomeModal">
        <h2>
          You’re invited!{' '}
          <span role="img" aria-label="">
            🎉
          </span>
        </h2>
        <p>
          Congratulations! You’ve been invited to join: <strong>{teamName}</strong>
        </p>
        {isAuthenticated ? (
          <div>
            {/* <p>
              Click the <strong>Join</strong> button below to be part of the team.
            </p> */}
            {hint ? (
              <p>
                <b>{hint}</b>
              </p>
            ) : null}
            <Button onClick={onJoin}>Join team</Button>
          </div>
        ) : (
          <div>
            <p>Please sign in to accept this invitation.</p>
            <Button href={loginUrl}>Sign in</Button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(`/api/team/${teamId}/invitation`, {
        params: {
          inviteId
        }
      })
      .then(({ data }) => {
        const { name } = data;
        setTeamName(name);
        setIsValid(true);
      })
      .catch((e) => {
        setIsValid(false);
        Sentry.captureException(e);
        return e;
      });
  }, [teamId, inviteId, setIsValid]);

  useEffect(() => {
    if (teams.includes(teamId)) {
      history.replace('/');
    }
  }, [teamId, teams, history]);

  return (
    <div className="staticPage-container">
      <div className="staticPage-content">
        {isValid === false && renderNotValid()}
        {isValid && renderInviteView()}
      </div>
      {error && <NotificationBar message="Sorry something went wrong." type="error" closeCallback={() => setError()} />}
    </div>
  );
}
