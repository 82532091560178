import React, { useState } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { ReactComponent as EmojiIcon } from 'images/icons/emoji.svg';

const EmojiPicker = ({ onEmojiSelect = () => {}, onEmojiPicker = () => {}, closeEmojiPicker = () => {}, icon }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const addEmoji = (e) => {
    // Note: Selected emoji is e.native
    onEmojiSelect(e);
    handleClose(e);
  };

  const handleClose = (e) => {
    setShowPicker(false);
    closeEmojiPicker();
  };

  const handleSelect = (e) => {
    setAnchorEl(e.target);
    setShowPicker(true);
    onEmojiPicker(e);
  };

  return (
    <>
      <span className="pulseEditor--emojiPicker" onClick={handleSelect}>
        {icon || <EmojiIcon />}
      </span>
      <Popover
        anchorEl={anchorEl}
        open={showPicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Picker native onSelect={addEmoji} />
        </ClickAwayListener>
      </Popover>
    </>
  );
};
export default EmojiPicker;
