import React, { useContext, useState, useEffect } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AppContext from 'AppContext';
import Emoji from 'emoji';
import UserAvatar from './UserAvatar';

import { ReactComponent as RemoveIcon } from 'images/icons/remove.svg';
import { ReactComponent as EmojiIcon } from 'images/icons/emoji.svg';
import CardComments from './CardComments';
import Comments from './Comments';

const CardSettings = ({
  showTyping,
  userId,
  note,
  setEmojiReaction,
  setComments,
  deleteNote,
  displayVote,
  onAddVote,
  onRemoveVote,
  onSendAction,
  isAnonymous,
  isVoting,
  maskComment,
  onEmojiSelect,
  onEmojiPicker,
  closeEmojiPicker,
  unGroup
}) => {
  const { isAuthenticated } = useContext(AppContext);
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const showProfile = isAuthenticated && Boolean(isAnonymous === false);
  const [showComments, setShowComments] = useState(false);

  const addEmoji = (e) => {
    let emoji = e.native;
    onEmojiSelect(anchorEl, emoji);
    handleClose(e);
  };

  const handleClose = (e) => {
    setShowPicker(false);
    closeEmojiPicker();
  };

  const handleSelect = (e) => {
    setAnchorEl(e.target);
    setShowPicker(true);
    onEmojiPicker(e);
  };

  const handlePress = (e) => {
    //Important: this prevent the loss of focus within input
    e.preventDefault();
  };

  useEffect(() => {
    if (isVoting) {
      setShowComments(false);
    }
  }, [isVoting]);

  return (
    <>
      {!showTyping && !maskComment && (
        <Emoji
          userId={userId}
          note={note}
          setEmojiReaction={setEmojiReaction}
          deleteNote={deleteNote}
          showProfile={showProfile}
          onSendAction={onSendAction}
          unGroup={unGroup}
          showTyping={showTyping}
          showComments={showComments}
          setShowComments={setShowComments}
        />
      )}

      <span className="insertEmoji" onClick={handleSelect} onMouseDown={handlePress}>
        <EmojiIcon />
      </span>
      <Popover
        anchorEl={anchorEl}
        open={showPicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Picker native onSelect={addEmoji} />
        </ClickAwayListener>
      </Popover>

      <span className="addVote" onClick={(e) => onAddVote(e, note)}></span>

      {displayVote > 0 && (
        <span className="removeVote" onClick={(e) => onRemoveVote(e, note)}>
          <RemoveIcon />
        </span>
      )}
      {displayVote > 0 && <span className="voteCount">{displayVote}</span>}
      {showProfile && <UserAvatar userIds={[...(note.groupedBy || [note.createdBy])]} />}
      {!maskComment && <CardComments comments={note.comments} onShowComments={() => setShowComments(true)} />}

      <Comments
        note={note}
        user={userId}
        showComments={showComments}
        hideComments={() => setShowComments(false)}
        setComments={setComments}
        showProfile={showProfile}
        showTyping={showTyping}
      />
    </>
  );
};
export default CardSettings;
