import React from 'react';
import { ReactComponent as Logo } from 'images/icons/teleretro.svg';

export default function Footer() {
  return (
    <footer>
      <section>
        <div className="section-content-wrapper">
          <div className="footer-about-teleretro">
            <Logo id="teleretro-logo" width="167" height="45" />
            <p>Agile retros you'll love</p>
            <p className="copyright">© TeleRetro | London, UK</p>
          </div>
          <nav>
            <ul>
              <li>
                <ul>
                  <li className="footer-list-header">About</li>
                  <li>
                    <a href="https://www.teleretro.com/collecting-feedback">Collecting Feedback</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/retro-formats">Retro Formats</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/pulse">Pulse Surveys</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/icebreakers">Icebreakers</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/integrations">Integrations</a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="footer-list-header">Get started</li>
                  <li>
                    <a href="/">Dashboard</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/faqs">FAQs</a>
                  </li>
                  <li>
                    <a href="/plans">Plans</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/resources">Resources</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/okta-integration">Okta SSO</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/azure-ad-sso">Azure AD SSO</a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="footer-list-header">Follow us</li>
                  <li>
                    <a href="https://www.teleretro.com/updates">Product Updates</a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/tele_retro">Twitter</a>
                  </li>
                  <li>
                    <a href="mailto:hello@teleretro.com">Contact Us</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/about-us">About us</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/security">Security</a>
                  </li>
                  <li>
                    <a href="https://teleretro.statuspage.io/">Status</a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="footer-list-header">Legal</li>
                  <li>
                    <a href="https://www.teleretro.com/terms">Terms</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/privacy">Privacy</a>
                  </li>
                  <li>
                    <a href="https://www.teleretro.com/data-processing">Data Processing</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </footer>
  );
}
