import React, { useState, useContext, useEffect, useCallback } from 'react';

import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import AppContext from 'AppContext';
import OrgLinks from 'modules/OrgLinks';
import NotificationBar from 'modules/NotificationBar';
import Log from 'utils/Log';
import apiService from 'utils/apiService';
import PulseModel from './PulseModel';
import OrgPulsePanel from './OrgPulsePanel';
import PulseCta from './PulseCta';
import PulsePromo from './PulsePromo';

export default function OrgPulse() {
  const { org } = useContext(AppContext);
  const { name, enabledModels } = org || {};
  const orgId = org?.id;

  const [modelEditor, setModelEditor] = useState(false);
  const [modelId, setModelId] = useState();
  const [pulseModels, setPulseModels] = useState();
  const [error, setError] = useState();

  const helpText =
    enabledModels?.length > 0
      ? '✨ Your Pulse Survey is enabled, all teams can see and run it'
      : '💡 Your Pulse Survey needs to be enabled for teams to see it';

  const closeModal = (e, reason) => {
    if (reason === 'backdropClick') return;
    setModelEditor(false);
  };

  const deletePulseModel = (id) => {
    apiService
      .delete(`/api/org/${orgId}/pulse-model/${id}`)
      .then(() => {
        getOrgPulse(orgId);
      })
      .catch((e) => {
        setError(e);
      });

    Log('Pulse', 'Delete Pulse Model');
  };

  const editPulseModel = (id) => {
    setModelEditor(true);
    setModelId(id);
    Log('Pulse', 'Open editor - edit');
  };

  const createPoll = () => {
    setModelEditor(true);
    setModelId();
    Log('Pulse', 'Open editor - create');
  };

  const getOrgPulse = useCallback((orgId) => {
    apiService
      .get(`/api/org/${orgId}/pulse-models`)
      .then(({ data }) => {
        setPulseModels(data);
      })
      .catch((e) => {
        setPulseModels([]);
        setError(e);
      });
  }, []);

  useEffect(() => {
    if (orgId) {
      getOrgPulse(orgId);
    }
  }, [orgId, getOrgPulse]);

  return (
    <div className="team-container">
      <div className="clouds" style={{ opacity: 0.5 }}>
        <div
          className="cloud cloud-small"
          style={{
            right: '1%',
            top: '150px'
          }}
        ></div>

        <div
          className="cloud cloud-small"
          style={{
            left: '-8px',
            top: '380px'
          }}
        ></div>
      </div>
      <div className="team-container-header">
        <h2>
          {name ? (
            name
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton width={'180px'} height={44} />
            </div>
          )}
        </h2>
        <p>Manage your organization’s Pulse</p>
        <hr />
        <OrgLinks current={'/pulse'} />
      </div>

      {Array.isArray(pulseModels) && pulseModels.length > 0 ? (
        <div className="orgPulseInfo">
          <div style={{ position: 'absolute', right: 0, bottom: '12px' }}>
            <PulseCta callback={createPoll} isSimple />
          </div>
          <p className="info-help-text info-help-text--dark">{helpText}</p>
        </div>
      ) : (
        <>{Array.isArray(pulseModels) && <PulsePromo isOrg callback={createPoll} />}</>
      )}
      {Array.isArray(pulseModels) &&
        pulseModels.map((data) => {
          const { id } = data;
          return (
            <Paper key={`${id}-org-pulse`} className="pulseDashboard">
              <OrgPulsePanel
                key={`pulsePanel-${id}`}
                orgId={orgId}
                model={data}
                getOrgPulse={getOrgPulse}
                deletePulseModel={deletePulseModel}
                editPulseModel={editPulseModel}
              />
            </Paper>
          );
        })}

      {modelEditor && (
        <PulseModel
          orgId={orgId}
          modelId={modelId}
          pulseModels={pulseModels}
          closeModal={closeModal}
          callback={() => {
            getOrgPulse(orgId);
            closeModal();
          }}
        />
      )}

      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </div>
  );
}
