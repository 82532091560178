import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    top: '48px',
    right: '12px',
    zIndex: '10'
  },
  message: {
    padding: '8px 12px',
    fontSize: '17px'
  }
}));

const SLIDE_TIMEOUT = 500;

function TransitionLeft(props) {
  return <Slide {...props} direction="left" timeout={SLIDE_TIMEOUT} mountOnEnter unmountOnExit />;
}

const Helper = ({ message, autoHideDuration, closeCallback }) => {
  const [open, setOpen] = useState(false);
  const [transition] = useState(() => TransitionLeft);
  const boardContainer = document.getElementById('boardMainContent');

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (closeCallback) {
      setTimeout(closeCallback, SLIDE_TIMEOUT);
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  if (!boardContainer) return null;

  return ReactDOM.createPortal(
    <Snackbar
      classes={{ root: classes.root }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      TransitionComponent={transition}
      autoHideDuration={autoHideDuration || 3000} //default to 3 seconds, timer hide in 12s
      onClose={handleClose}
    >
      <Alert classes={{ message: classes.message }} icon={false} severity="info" variant="filled">
        <span>{message}</span>
      </Alert>
    </Snackbar>,
    boardContainer
  );
};

export default React.memo(Helper);
