import React, { useState, useEffect, useRef } from 'react';
import { Timeline } from 'react-svg-timeline';
import { format, getTime, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import Tooltip from '@material-ui/core/Tooltip';

import apiService from 'utils/apiService';
import { getMoodEmoji, getMoodColour } from 'utils/misc';
import { ReactComponent as ExternalIcon } from 'images/icons/external.svg';

import RangeSelection from './RangeSelection';

// const lanes = [
//   {
//     laneId: 'team-1',
//     label: 'Team 1'
//   }
// ];
// const events = [
//   {
//     eventId: 'event-1',
//     laneId: 'team-1',
//     startTimeMillis: getTime(new Date()),
//     tooltip: 'event-1 team-1'
//   }
// ];

const LANE_HEIGHT = 50;

const rangeGroup = [
  { label: '1y', value: 11 },
  { label: '6m', value: 5 },
  { label: '3m', value: 2 }
];

export default function OrgTimeline({ teamsData }) {
  const containerRef = useRef();

  const [eventsData, setEventsData] = useState();
  const [range, setRange] = useState(2); // 3 months
  const from = getTime(subMonths(startOfMonth(new Date()), range));
  const to = getTime(endOfMonth(new Date()));

  const dateFormat = (ms) => new Date(ms).toLocaleString();
  const customRange = [from, to];
  const lanes = Array.isArray(teamsData) ? teamsData.map(({ data }) => ({ laneId: data.id, label: data.name })) : [];
  const events = Array.isArray(eventsData)
    ? eventsData.reduce((prev, current) => {
        const { data } = current;
        const currentEvents = data.map(({ id, teamId, name, timestamp }, index) => ({
          eventId: id,
          laneId: teamId,
          startTimeMillis: timestamp,
          name
        }));

        return [...prev, ...currentEvents];
      }, [])
    : [];

  const getTooltip = (e, retroInsight = {}) => {
    const { eventId, name, startTimeMillis } = e;
    const { cardsCount, emojiCount, peopleCount, votesCount, moodData } = retroInsight;
    const { moodScore } = moodData || {};

    return (
      <div className="graphContainer-item--tooltip" style={{ minWidth: '300px' }}>
        <h3>{name}</h3>
        <div className="graphContainer-item--content">
          <span>{startTimeMillis && format(startTimeMillis, 'PPP')}</span>

          <div className="teamDashboard-overview" style={{ margin: '24px 12px' }}>
            {moodScore && moodScore > 0 ? (
              <span>
                <span className="teamDashboard-overview-icon" role="img" aria-label="">
                  {getMoodEmoji(moodScore)}
                </span>
                <span>Mood</span>
                {moodScore}
              </span>
            ) : null}

            <span>
              <span className="teamDashboard-overview-icon" role="img" aria-label="">
                👤
              </span>
              <span>People</span>
              {peopleCount}
            </span>

            <span>
              <span className="teamDashboard-overview-icon" role="img" aria-label="">
                📝
              </span>
              <span>Notes</span>
              {cardsCount}
            </span>

            <span>
              <span className="teamDashboard-overview-icon" role="img" aria-label="">
                🗳
              </span>
              <span>Votes</span>
              {votesCount}
            </span>

            <span>
              <span className="teamDashboard-overview-icon" role="img" aria-label="">
                👍
              </span>
              <span>Emoji</span>
              {emojiCount}
            </span>
          </div>

          <a href={`/board/${eventId}`} target="_blank" rel="noopener noreferrer">
            Open this Retro <ExternalIcon />
          </a>
        </div>
      </div>
    );
  };

  const eventComponent = (e, role, timeScale, y) => {
    const size = 6;
    const startX = timeScale(e.startTimeMillis);
    const eventLaneId = e.laneId;

    const verticalHeight =
      (lanes.findIndex(({ laneId }) => laneId === eventLaneId) + 1) * LANE_HEIGHT - LANE_HEIGHT / 2;

    const iconTranslate = `translate(${startX - size / 2}, ${verticalHeight - size / 2})`;

    const retroInsight = eventsData
      ?.filter(({ teamId }) => teamId === e.laneId)[0]
      .data.filter(({ id }) => id === e.eventId)[0];

    const {
      moodData: { moodScore }
    } = retroInsight;

    if (role === 'background') {
      return null;
      // return (
      //   <line
      //     strokeDasharray="5, 5"
      //     x1="0"
      //     y1={verticalHeight}
      //     x2="100%"
      //     y2={verticalHeight}
      //     style={{ strokeWidth: '2px', stroke: '#9e9e9e' }}
      //   ></line>
      // );
    }

    return (
      <g transform={`${iconTranslate}`}>
        <foreignObject x="-5" y="-5" width="20" height="20">
          <div xmlns="http://www.w3.org/1999/xhtml">
            <Tooltip
              title={getTooltip(e, retroInsight)}
              placement="top"
              enterTouchDelay={20}
              leaveTouchDelay={5000}
              disableFocusListener
              interactive
            >
              <span className=" graphContainer-item--interactive">
                <span
                  className="graphContainer-item--dot"
                  style={{ backgroundColor: getMoodColour(moodScore) || '#12243E' }}
                ></span>
              </span>
            </Tooltip>
          </div>
        </foreignObject>
      </g>
    );
  };

  useEffect(() => {
    if (!Array.isArray(teamsData)) return;

    Promise.all(
      teamsData.map(({ data }) =>
        apiService
          .get(`/api/team/${data.id}/history`, {
            params: {
              from
            }
          })
          .then((d) => ({ ...d, teamId: data.id }))
          .catch((e) => e)
      )
    ).then((data) => {
      const validData = data.filter(({ status }) => status === 200);
      setEventsData(validData);
    });
  }, [teamsData, from]);

  return (
    <>
      <div style={{ position: 'absolute', top: '0', left: '24px', background: '#fff', zIndex: 1 }}>
        <RangeSelection onChange={setRange} range={range} rangeGroup={rangeGroup} />
      </div>
      <div ref={containerRef} className="teamDashboard-timeline">
        {containerRef.current && (
          <Timeline
            width={containerRef.current?.clientWidth}
            height={LANE_HEIGHT * lanes.length}
            customRange={customRange}
            events={events}
            lanes={lanes}
            dateFormat={dateFormat}
            zoomLevels={[]}
            layers={['grid', 'marks']} //'axes',, 'grid'
            eventComponent={eventComponent}
          />
        )}
      </div>
    </>
  );
}
