import React from 'react';
import animationData from 'assets/jss/lottie/typing.json';
import Lottie from 'react-lottie';

function Typing() {
  return (
    <div className="typing">
      <div className="typing-container">
        <Lottie
          options={{
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          width={40}
        />
      </div>
    </div>
  );
}
export default Typing;
