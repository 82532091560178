import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import giphyImg from 'images/img/promo-giphy.png';

export default function GifsVisibility({ value, onChange }) {
  return (
    <RadioGroup row aria-label="layout" name="layout" value={value} onChange={onChange}>
      <FormControlLabel
        value={false}
        control={<Radio color="primary" />}
        label={
          <div className="">
            <h3>Enable GIF sharing</h3>

            <p>Allows participants to find and share GIFs on the retro board</p>

            <p style={{ margin: '0 0 24px 28px' }}>
              <img src={giphyImg} alt="" />
            </p>
          </div>
        }
        labelPlacement="end"
      />

      <FormControlLabel
        value={true}
        control={<Radio color="primary" />}
        label={
          <div className="">
            <h3>Disable GIF sharing</h3>
            <p>Prevent participants from finding and sharing GIFs</p>
          </div>
        }
        labelPlacement="end"
      />
    </RadioGroup>
  );
}
