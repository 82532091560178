const dimensions = {
  s: '400x300',
  l: '1600x900'
};

// Christmas backgrounds
// 'B40ztSGQTZY',
// 'cUeHltmejuY',
// 'uYmf3FX2zHM',
// 'Uz9w7XZnEkA',
// '0CQfTLOVTPU',
// 'CvFARq2qu8Y',
// 'KbaOHcM-N5Q',
// '7VOyZ0-iO0o',
// 'YcB04jlUsQY',

export const images = [
  'zsAPsRjzXRI',
  'xu6pELUdcsY',
  'UNSs8TXxQ1c',
  'DKix6Un55mw',
  'gooBgyq17i0',

  '2XpixHtHmVc',
  'h7bQ8VEZtws',
  'fwWj5Y1IgQg',
  'kFHz9Xh3PPU',
  'FJQAjFZwz0w',
  'IGtutkXikuc',
  '8I6OcgjAOGg',
  'B3hNQbPSg0I',
  'GLf7bAwCdYg',
  'SH_IjrKwG8c',
  'LeG68PrXA6Y',
  'WDSN62Qdxuk',
  'dLFmwIOGNYo',
  'gSQ7RFySDyM',
  'gdE-5Oui1Y0',
  'Zqr9EFyYmAY',
  'kgz9vsP5JCU',
  'rK2HPIseisA',
  'ssj9fcKnkFQ',
  'M1ObxvsWVhY',
  'RN6ts8IZ4_0',
  '2M_sDJ_agvs',
  'F1_SdW1W93Y',
  'bTtybBQ7HGw',
  'MethwOyZsZk',
  'TWoL-QCZubY',
  'ZcLoPiGA92c',
  'rS26chimPaA',
  'NnTQBkBkU9g',
  'wMzx2nBdeng',
  'KiRlN3jjVNU',
  'wDLnCaHHrKU',
  'RwHv7LgeC7s',
  'TFyi0QOx08c',
  'Lq1rOaigDoY',
  'qE1jxYXiwOA',
  '5WPEDMgmQiw',
  'qO-PIF84Vxg',
  'Q1p7bh3SHj8'
];

export const getImageUrl = (imageId, size) => {
  if (imageId.includes('bg-')) {
    return `https://static.teleretro.com/img/${imageId}-${size}.jpeg`;
  } else {
    return `https://www.teleretro.com/img/backgrounds/${imageId}:${dimensions[size]}.jpeg`;
  }
};

export const getBackgroundImage = ({ background, backgroundUrl, size }) => {
  if (backgroundUrl) {
    return `url("${backgroundUrl}")`;
  }

  if (background) {
    return `url("${getImageUrl(background, size)}")`;
  }

  return 'none';
};
