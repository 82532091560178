import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';
import { ReactComponent as SlackIcon } from 'images/icons/slack.svg';

import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as LinkIcon } from 'images/icons/link.svg';
import IconButton from '@material-ui/core/IconButton';

import Log from 'utils/Log';
import { getTeamLink } from 'utils/misc';
import useSlack from 'hooks/useSlack';

export default function Title({ name, setTitleEditor, isFacilitator, planId, isPrivate, teamId }) {
  const history = useHistory();
  const { postToSlack } = useSlack({ teamId, isFacilitator });
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);
  const url = window.location.href;
  const noPlan = Boolean(!planId);

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCopy = () => {
    Log('Copy', 'board url');
  };

  const onSlack = async () => {
    const data = {
      blocks: [
        {
          type: 'context',
          elements: [
            {
              type: 'plain_text',
              text: url
            }
          ]
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*${name}*`
          },
          accessory: {
            type: 'button',
            text: {
              type: 'plain_text',
              text: 'Join'
            },
            value: 'Join Retro',
            url,
            action_id: 'join_retro'
          }
        }
      ]
    };

    await postToSlack({ data });
  };

  return (
    <>
      {isFacilitator ? (
        <>
          <h1 className="boardTitle" onClick={() => setTitleEditor(true)} style={{ cursor: 'pointer' }}>
            {name}
          </h1>
          <div className="boardTitlePanel" onMouseOver={handleOpen} onMouseLeave={handleClose}>
            <div ref={panelEl} className="actionPanel">
              <Badge badgeContent={null} color="primary" overlap="rectangular">
                <LinkIcon />
              </Badge>
            </div>

            <div className="actionPanelList">
              <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                    <Paper>
                      <div className="actionPanelBoard actionPanelBoard-shareLink">
                        <h3>Share this retro</h3>

                        <p style={{ margin: '1em 0 0 0' }}>
                          <input
                            value={url}
                            readOnly
                            style={{ width: '316px', fontSize: '12px', background: '#f8f8f8', fontFamily: 'Courier' }}
                          />
                        </p>
                        <div>
                          <Tooltip title="Copy link" placement="top">
                            <CopyToClipboard text={url} onCopy={onCopy}>
                              <IconButton>
                                <CopyIcon fontSize="small" />
                              </IconButton>
                            </CopyToClipboard>
                          </Tooltip>

                          <Tooltip placement="top" title="Notify team on Slack">
                            <IconButton onClick={onSlack}>
                              <SlackIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>

                        {isPrivate ? (
                          <>
                            <p>
                              <strong style={{ paddingRight: '4px' }}>Private</strong> only invited team members can
                              access
                            </p>
                          </>
                        ) : (
                          <p>
                            <strong style={{ paddingRight: '4px' }}>Public</strong> anyone with link can access
                          </p>
                        )}

                        {noPlan && !isPrivate ? (
                          <span
                            className="text-button "
                            onClick={() => {
                              Log('Click', 'share upgrade');
                              history.push('/plans');
                            }}
                          >
                            Upgrade to enable private retros{' '}
                            <span role="img" aria-label="">
                              🚀
                            </span>
                          </span>
                        ) : (
                          <span
                            className="text-button "
                            onClick={() => {
                              Log('Click', 'share manage settings');

                              history.push(getTeamLink({ teamId, menu: 'settings' }));
                            }}
                          >
                            Manage settings
                          </span>
                        )}
                      </div>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </>
      ) : (
        <h1 className="boardTitle">{name}</h1>
      )}
    </>
  );
}
