import { nanoid } from 'nanoid';

import { groupSign, defaultSize } from 'utils/constant';
import { flatArray } from 'utils/misc';

const unGroupItems = (groupedNote) => {
  const textArray = groupedNote.text.split(groupSign);
  const authorArray = groupedNote.groupedBy;
  const canPreserveAuthor = Boolean(textArray.length === authorArray.length);
  const singleAuthor = Boolean(authorArray.length === 1);

  const newGroup = textArray.map((text, index) => {
    const originalNote = Boolean(index === 0);
    const uid = nanoid();

    const newNote = originalNote
      ? {
          ...groupedNote,
          text,
          lastEditedBy: 'ungroup', //import for update note height
          groupedBy: canPreserveAuthor || singleAuthor ? [] : authorArray,
          grid: { ...groupedNote.grid, h: defaultSize.h }
        }
      : {
          ...groupedNote,
          text,
          id: uid,
          emojis: [],
          groupedBy: [],
          comments: [],
          createdBy: canPreserveAuthor ? authorArray[index] : singleAuthor ? authorArray[0] : 'ungroup',
          lastEditedBy: 'ungroup', //import for update note height
          grid: { ...groupedNote.grid, i: uid, h: defaultSize.h, y: groupedNote.grid.y + index }
        };

    if (newNote.groupedBy.length === 0) {
      delete newNote.groupedBy;
    }

    return newNote;
  });

  return newGroup;
};

const sortItems = ({ columns, currentNotes, sortBy, votes }) => {
  const sortedNotes = columns.map((col, index) => {
    const sortedColumnNotes = currentNotes
      .filter((note) => note.grid.x === index)
      .sort((a, b) => {
        return a.grid.y - b.grid.y;
      })
      .sort((a, b) => {
        if (sortBy === 'votes') {
          return parseInt(votes[b.id] || 0) - parseInt(votes[a.id] || 0);
        }

        if (sortBy === 'emoji') {
          return (b.emojis?.length || 0) - (a.emojis?.length || 0);
        }

        if (sortBy === 'comments') {
          return (b.comments?.length || 0) - (a.comments?.length || 0);
        }

        return 0;
      });

    sortedColumnNotes.forEach((note, index) => {
      note.grid.y = index === 0 ? 0 : sortedColumnNotes[index - 1].grid.y + sortedColumnNotes[index - 1].grid.h;
    });

    return sortedColumnNotes;
  });

  if (typeof sortedNotes.flat === 'function') {
    return sortedNotes.flat();
  } else {
    return flatArray(sortedNotes);
  }
};

const getExistingVotesCount = (userExistingVotes, notes) => {
  return Object.entries(userExistingVotes).reduce(
    (total, [id, count]) => (notes.filter((note) => note.id === id)[0] ? total + parseInt(count || 0) : total),
    0
  );
};

export { unGroupItems, sortItems, getExistingVotesCount };
