import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import NoMatch from './NoMatch';
import Page from './Page';
import TeamView from './TeamView';
import OrgView from './OrgView';

export default function Home() {
  return (
    <>
      <Switch>
        <Redirect from="/dashboard/team/:teamId/dashboard" to="/dashboard/team/:teamId" />

        <Route exact path="/dashboard">
          <div className="clouds" style={{ opacity: 0.5 }}>
            <div
              className="cloud cloud-small cloud-purple"
              style={{
                right: '1%',
                top: '150px'
              }}
            ></div>

            <div
              className="cloud cloud-small cloud-purple"
              style={{
                left: '-8px',
                top: '380px'
              }}
            ></div>
          </div>
          <OrgView />
        </Route>

        <Route exact path="/dashboard/team/:teamId/:menu?">
          <div className="clouds" style={{ opacity: 0.5 }}>
            <div
              className="cloud cloud-small cloud-blue"
              style={{
                right: '1%',
                top: '150px'
              }}
            ></div>

            <div
              className="cloud cloud-small cloud-blue"
              style={{
                left: '-8px',
                top: '380px'
              }}
            ></div>
          </div>
          <TeamView />
        </Route>

        <Route path="*">
          <Page>
            <NoMatch />
          </Page>
        </Route>
      </Switch>
    </>
  );
}
