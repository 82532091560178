import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export default function ProgressLoader() {
  return (
    <div
      className="progressLoader"
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1
      }}
    >
      <CircularProgress />
    </div>
  );
}
