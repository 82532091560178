import React from 'react';

export default function RangeSelection({ range, rangeGroup, onChange }) {
  const handleClick = (e) => {
    const newValue = parseInt(e.target.closest('button').value, 10);

    if (Number.isInteger(newValue)) {
      onChange(newValue);
    }
  };
  const renderButtons = (item, index) => {
    const { label, value } = item;

    return (
      <button
        key={`button-range-${label}`}
        value={value}
        onClick={handleClick}
        className={`${value === range ? 'graphGroups-group-selected' : ''}`}
      >
        {label}
      </button>
    );
  };
  return <div className="graphGroups">{rangeGroup.map(renderButtons)}</div>;
}
