import React, { useRef, useState } from 'react';

import Log from 'utils/Log';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';

import StyledBadge from './StyledBadge';

const Survey = ({ id, onSurvey, retroStates, surveyData }) => {
  const [open, setOpen] = useState(false);
  const panelEl = useRef(null);
  const { pulse } = retroStates || {};
  const { pulseData } = surveyData || {};
  const { people } = pulseData || {};
  const total = people?.length;
  const isEnded = Boolean(pulse === false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const endPoll = () => {
    onSurvey({
      pulse: false
    });

    handleClose();
    Log('Pulse', 'close');
  };

  const startPoll = () => {
    onSurvey({
      pulse: true
    });

    handleClose();
    Log('Pulse', 'open');
  };

  const renderEndPoll = () => {
    return (
      <>
        <p>Pulse Survey currently in progress</p>

        {total ? (
          <p>
            {total} {`${total > 1 ? 'people' : 'person'} completed the survey`}
          </p>
        ) : (
          <p>Ask your team to participate by sharing this page</p>
        )}

        {open && (
          <Tooltip placement="top" title={'End pulse survey and view results'}>
            <span className="text-button" onClick={endPoll}>
              End Pulse Survey
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  const renderStartPoll = () => {
    return (
      <>
        {pulse === undefined ? (
          <>
            <p>Ask people to complete the pulse check</p>
            <span className="text-button" onClick={startPoll}>
              Start Pulse
            </span>
          </>
        ) : (
          <>
            <p>Pulse Survey has ended</p>
            {open && (
              <Tooltip placement="top" title={'Allow users to update their answers'}>
                <span className="text-button" onClick={startPoll}>
                  Reopen Pulse Survey
                </span>
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="retroControlPanel" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={isEnded} overlap="rectangular">
          Pulse
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Pulse</h3>

                  {isEnded ? renderStartPoll() : renderEndPoll()}
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Survey;
