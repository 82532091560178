import React, { useState, useRef, useEffect } from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import add from 'date-fns/add';

import Log from 'utils/Log';

import StyledBadge from './StyledBadge';

const DEFAULT_TIME = 5;

const Timer = ({ timerStates, onTimer, isOnTimer, setIsOnTimer }) => {
  const { startTime, duration } = timerStates;
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(duration || DEFAULT_TIME);
  const [error, setError] = useState(false);
  const panelEl = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setTimer(value);

    if (Number.isInteger(Number(value)) && value > 0 && value < 100) {
      error && setError(false);
    } else {
      setError(true);
    }
  };

  const toggleTimer = (e, stop) => {
    if (error) return;

    const timerConfig = stop
      ? {
          duration: null,
          startTime: null
        }
      : {
          duration: timer,
          startTime: new Date()
        };

    setOpen(false);
    onTimer({ timerConfig });

    Log('Timer', `${stop ? 'Stop' : 'Start'} ${timer}`);
  };

  useEffect(() => {
    if (startTime && duration) {
      const currentTime = new Date();
      const expiryTime = add(new Date(startTime), {
        minutes: duration
      });
      setIsOnTimer(Boolean(currentTime < expiryTime));
    } else {
      setIsOnTimer(false);
    }
  }, [startTime, duration, setIsOnTimer]);

  return (
    <div onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={Boolean(!isOnTimer)} overlap="rectangular">
          Timer
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard">
                  <h3 onMouseOver={handleOpen}>Timer</h3>
                  {isOnTimer ? (
                    <div>
                      <span className="text-button" onClick={(e) => toggleTimer(e, true)}>
                        End Timer
                      </span>
                    </div>
                  ) : (
                    <div>
                      <TextField
                        id="timer"
                        type="number"
                        label="Minutes"
                        inputProps={{ min: 1, max: 120 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={timer}
                        onChange={handleChange}
                        error={error}
                        helperText={error ? 'Please enter a valid number' : ''}
                      />

                      <span className="text-button" onClick={toggleTimer}>
                        Start Timer
                      </span>
                    </div>
                  )}
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Timer;
