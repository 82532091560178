import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import Log from 'utils/Log';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';

import { isSpotify, isTouchScreen } from 'utils/misc';
import StyledBadge from './StyledBadge';
import { ReactComponent as BroadcastIcon } from 'images/icons/broadcast.svg';
import { ReactComponent as ExternalIcon } from 'images/icons/arrow.svg';

import IceBreakerBotImg from 'images/img/promo-ice-breaker-bot.png';

const demos = [
  { name: 'YouTube,', url: 'https://youtu.be/dQw4w9WgXcQ' },
  { name: 'SoundCloud,', url: 'https://soundcloud.com/jazzhopcafe/morning-coffee-lofi-jazzhop-chill-mix-1' },
  { name: 'Facebook,', url: 'https://www.facebook.com/trynottolaughpets/videos/1928114857439222/' },
  { name: 'Vimeo, ', url: 'https://vimeo.com/channels/top/22439234' },
  { name: 'Spotify, ', url: 'https://open.spotify.com/track/3RCj5fG55qjtmnEML1gpnA' },
  { name: 'Mixcloud', url: 'https://www.mixcloud.com/Katy_Torres/cloud-garden/' }
  // { name: 'Twitch', url: 'https://www.twitch.tv/videos/928237865' },
  //{ name: 'DailyMotion', url: 'https://www.dailymotion.com/video/x70rzfb' }
];

const IceBreaker = ({ mediaStates, onBroadcast, teamId }) => {
  const { source } = mediaStates;
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const panelEl = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [volume, setVolume] = React.useState(50);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleChange = (event) => {
    if (error) {
      setError(false);
    }

    const value = event.target.value;
    setUrl(value);
  };

  const handleVolume = (event, newValue) => {
    setVolume(newValue);
    onBroadcast({ mediaConfig: { ...mediaStates, volume: newValue } });
  };

  const onVolumeCommitted = () => {
    Log('Media', `${volume} - ${source}`);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      togglePlayMedia(e);
    }
  };

  const handleTabChange = (value) => {
    setActiveIndex(value);
  };

  const togglePlayMedia = (e, stop) => {
    Log('Media', `${stop ? 'Stop' : 'Play'}: ${url}`);

    if (!isSpotify(url) && !ReactPlayer.canPlay(url)) {
      setError(true);
      return;
    }

    if (error) return;

    if (e) {
      setOpen(false);
    }

    const mediaConfig = stop
      ? {
          source: null
        }
      : {
          source: url,
          volume
        };

    onBroadcast({ mediaConfig });
  };

  const renderDemos = () => {
    return demos.map((demo, i) => (
      <span
        key={`demo-example-${i}`}
        className="text-button"
        onClick={() => {
          if (error) {
            setError(false);
          }
          setUrl(demo.url);
        }}
      >
        {demo.name}{' '}
      </span>
    ));
  };

  const renderVolume = () => {
    if (!url || isSpotify(url) || url.includes('mixcloud.com') || isTouchScreen()) return null;

    return (
      <div style={{ margin: '24px 10px 24px 0' }}>
        <Grid container spacing={0}>
          <Grid item>
            <div style={{ margin: '2px' }}>
              <VolumeDown />
            </div>
          </Grid>
          <Grid item xs>
            <Slider value={volume} onChange={handleVolume} onChangeCommitted={onVolumeCommitted} />
          </Grid>
          <Grid item>
            <div style={{ margin: '2px' }}>
              <VolumeUp />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    setUrl(source || '');
  }, [source]);

  return (
    <div className="iceBreaker" onMouseLeave={handleClose}>
      <div ref={panelEl} onMouseOver={handleOpen} className="actionPanel">
        <StyledBadge color="secondary" variant="dot" invisible={Boolean(!source)} overlap="rectangular">
          Icebreaker
        </StyledBadge>
      </div>
      <div className="actionPanelList">
        <Popper anchorEl={panelEl.current} open={open} placement="bottom" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <div className="actionPanelBoard actionPanelBoard-iceBreaker">
                  <h3 onMouseOver={handleOpen} style={{ marginBottom: '12px' }}>
                    Icebreaker
                  </h3>

                  <Tabs onChange={(e, value) => handleTabChange(value)} value={activeIndex} centered>
                    <Tab key="icebreaker-music" value={0} disableRipple={true} label={<div>Music</div>} />

                    <Tab key="icebreaker-questions" value={1} disableRipple={true} label={<div>Questions</div>} />
                  </Tabs>
                  <SwipeableViews index={activeIndex} onChangeIndex={handleTabChange} style={{ width: '100%' }}>
                    <div key={0}>
                      <p>
                        Set the mood with{' '}
                        <span role="img" aria-label="music">
                          🎵
                        </span>
                        or{'  '}
                        <span role="img" aria-label="video">
                          🎬
                        </span>
                      </p>
                      <TextField
                        id="share-media"
                        type="text"
                        label="Media Link"
                        inputProps={{ min: 1 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={url}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        disabled={Boolean(source)}
                        error={error}
                        helperText={
                          error ? "Oops, that link doesn't seem to work. Try links below to see what works" : ''
                        }
                      />

                      {source ? (
                        <div>
                          <p className="iceBreaker-playing">
                            On Air <BroadcastIcon />
                          </p>
                          {renderVolume()}
                          <span className="text-button" onClick={(e) => togglePlayMedia(e, true)}>
                            Stop Broadcast
                          </span>
                        </div>
                      ) : (
                        <div>
                          <p className="iceBreaker-hint">
                            Try: {renderDemos()}& more{' '}
                            <span role="img" aria-label="">
                              🍿
                            </span>
                          </p>
                          {renderVolume()}
                          <div>
                            <span className="text-button" onClick={togglePlayMedia}>
                              Start Broadcast
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div key={1}>
                      <div>
                        <p style={{ textAlign: 'center' }}>
                          Ask Icebreaker Bot{' '}
                          <span role="img" aria-label="">
                            🤖
                          </span>{' '}
                        </p>
                        <a
                          className="text-button"
                          href={`/team/${teamId}/icebreaker-questions`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={IceBreakerBotImg} alt="" width="100%" style={{ margin: '18px 0' }} />
                        </a>
                        <a
                          className="text-button"
                          href={`/team/${teamId}/icebreaker-questions`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Get questions
                          <span>
                            <ExternalIcon style={{ marginBottom: '-1px' }} />
                          </span>
                        </a>
                      </div>
                    </div>
                  </SwipeableViews>
                </div>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default IceBreaker;
