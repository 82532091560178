import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import AppContext from 'AppContext';
import { APP_ENDPOINT } from 'config';

import { checkPermission, getRole } from 'roles/checkPermission';
import apiService from 'utils/apiService';

import OrgLinks from './OrgLinks';
import NotificationBar from './NotificationBar';

export default function OrgSettings() {
  const history = useHistory();
  const { org, updateOrg, isAuthenticated, userMeta } = useContext(AppContext);
  const { id: orgId, name } = org || {};

  const [displayName, setDisplayName] = useState(name || '');

  const [isUpdated, setIsUpdated] = useState();

  const [error, setError] = useState();

  const { role } = getRole({ orgId, userMeta });

  const canEditMember = checkPermission(role, 'org:edit');

  const noChange = Boolean(displayName === name);

  const onNameChange = (e) => {
    const value = e.target.value;
    setDisplayName(value);
  };

  const onSubmit = () => {
    setIsUpdated(false);
    if (!noChange) {
      apiService
        .post(`/api/org/${orgId}/settings`, {
          name: displayName
        })
        .then(() => {
          updateOrg({
            orgId,
            name: displayName
          });
          setIsUpdated(true);
        })
        .catch((e) => {
          setIsUpdated(false);
          setError(true);
          Sentry.captureException(e);
        });
    }
  };

  const handleDownload = async () => {
    try {
      const response = await apiService.get(`/api/org/${orgId}/metrics`, {
        responseType: 'blob' // Important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `teleretro-${format(Date.now(), 'yyyy-MM-dd')}.json`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (e) {
      setError(true);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (name) {
      setDisplayName(name);
    }
  }, [name]);

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userMeta && !canEditMember) {
      history.push('/dashboard');
    }
  }, [history, userMeta, canEditMember]);

  return (
    <div className="team-container">
      <div className="clouds" style={{ opacity: 0.5 }}>
        <div
          className="cloud cloud-small cloud-pink"
          style={{
            right: '1%',
            top: '150px'
          }}
        ></div>

        <div
          className="cloud cloud-small cloud-pink"
          style={{
            left: '-8px',
            top: '380px'
          }}
        ></div>
      </div>
      <div className="team-container-header">
        <h2>
          {name ? (
            name
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton width={'180px'} height={44} />
            </div>
          )}
        </h2>
        <p>Manage your organization’s settings</p>
        <hr />
        <OrgLinks current={'/settings'} />
      </div>
      <Paper className="orgSettings">
        <div className="teamSettings-group orgSettings-name">
          <label style={{ marginTop: '4px' }}>Organization Name</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField value={displayName} inputProps={{ maxLength: 100 }} onChange={onNameChange} />

            <Button onClick={onSubmit} disabled={noChange || Boolean(displayName === '')}>
              {isUpdated && noChange ? 'Saved!' : 'Save'}
            </Button>
          </div>
        </div>

        <div className="teamSettings-group">
          <label style={{ marginTop: '4px' }}>
            Export Data{' '}
            <Tooltip
              title={
                <div style={{ fontSize: '16px' }}>
                  <p style={{ margin: '8px' }}>
                    Download a snapshot of your organization's key metrics including individual team action items and
                    retrospective insights.
                  </p>
                </div>
              }
              placement="top"
            >
              <InfoOutlinedIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </label>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="text-button text-button-secondary" onClick={handleDownload}>
              Download
            </span>
          </div>
        </div>

        {error && (
          <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />
        )}
      </Paper>
    </div>
  );
}
