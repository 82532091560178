import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';

import Button from 'components/CustomButtons/Button';
import apiService from 'utils/apiService';
import PulseGrids from './PulseGrids';
import Log from 'utils/Log';

const PulsePanel = ({ teamId, modelData, canCreateBoard }) => {
  const history = useHistory();

  const { id, format, enabled } = modelData;
  const [pulsesData, setPulsesData] = useState();

  const getPulsesData = useCallback(() => {
    if (teamId && id) {
      apiService
        .get(`/api/team/${teamId}/pulse?modelId=${id}`)
        .then(({ data }) => {
          setPulsesData(data);
        })
        .catch((e) => e);
    }
  }, [teamId, id]);

  useEffect(() => {
    getPulsesData();
  }, [getPulsesData]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
        <div className="pulseTemplate">
          <PulseGrids
            teamId={teamId}
            modelData={format}
            pulsesData={pulsesData}
            getPulsesData={getPulsesData}
            minPulse={5}
          />

          {canCreateBoard ? (
            <>
              {enabled && (
                <Button
                  onClick={() => {
                    Log('Pulse', 'Run from team panel');
                    history.push(`/team/${teamId}/pulse-survey/create?modelId=${id}`);
                  }}
                >
                  Start Pulse Survey
                </Button>
              )}
            </>
          ) : (
            <Tooltip placement="top" title="To start Pulse Survey, ask your team admin to make you a Facilitator">
              <Button disabled style={{ pointerEvents: 'auto' }}>
                Start Pulse Survey
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};

export default PulsePanel;
