import React, { useContext, useState, useRef, useEffect } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AppContext from 'AppContext';

import { ReactComponent as DropdownIcon } from 'images/icons/dropdown-white.svg';

export default function OrgMenu() {
  const history = useHistory();
  const { teamId } = useParams();
  const location = useLocation();

  const { org, orgs, isAuthenticated, switchOrg } = useContext(AppContext);
  const { name, id: orgId } = org || {};
  const [open, setOpen] = useState(false);
  const [orgsOpen, setOrgsOpen] = useState(false);

  const panelEl = useRef(null);
  // retro board, create retro page, pulse, invite page don't render org menu
  const noOrgMenu =
    location.pathname.includes('/board') ||
    location.pathname.includes('/invite') ||
    location.pathname.includes('/pulse-survey');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    orgsOpen && setOrgsOpen(false);
  };

  const handleOrgsOpen = () => {
    setOrgsOpen(!orgsOpen);
  };

  const onOrgSwitch = (id) => {
    if (org?.id !== id) {
      switchOrg({ orgId: id });

      if (location.pathname.includes('/dashboard') || location.pathname.includes('/icebreaker-questions')) {
        history.push('/dashboard');
      }
    }
  };

  const renderOrgMenu = () => {
    const renderSwitchMenu = () => {
      return (
        <>
          <span className="text-button" onClick={handleOrgsOpen} style={{ display: 'flex', justifyContent: 'center' }}>
            Switch Organization{orgsOpen ? <ExpandLess /> : <ExpandMore />}
          </span>

          <Collapse in={orgsOpen}>
            <List className="menuGroups" component="div" disablePadding style={{ margin: '0 0 8px 0' }}>
              {orgs.map(({ id, name }) => {
                return (
                  <button
                    key={`orgMenu-${id}`}
                    onClick={() => onOrgSwitch(id)}
                    className={`${Boolean(id === orgId) ? 'menuGroups-group-selected' : ''}`}
                  >
                    {name}
                  </button>
                );
              })}
            </List>
          </Collapse>
        </>
      );
    };
    return (
      <>
        <span
          style={{
            display: 'flex',
            margin: '12px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {name}
        </span>

        {orgs.length > 1 && renderSwitchMenu()}
      </>
    );
  };

  useEffect(() => {
    if (noOrgMenu) return;

    if (org && orgs.length > 0 && teamId) {
      if (org.teams.includes(teamId)) return;

      const validOrg = orgs.filter(({ teams }) => teams.includes(teamId))[0];

      if (validOrg) {
        const { id } = validOrg;
        switchOrg({ orgId: id });
      } else {
        //Handle invalid teamId
        history.push('/dashboard');
      }
    }
  }, [org, orgs, teamId, switchOrg, history, noOrgMenu]);

  if (!isAuthenticated || noOrgMenu) return null;

  return (
    <>
      <div className="orgMenu" onMouseOver={handleOpen} onMouseLeave={handleClose}>
        <h1 ref={panelEl} className="boardTitle">
          {name}
          <span>
            <DropdownIcon />
          </span>
        </h1>

        <div className="actionPanelList">
          {/** Important: this popper has no disablePortal, as it causing overflow in safari **/}
          <Popper style={{ zIndex: 1 }} anchorEl={panelEl.current} open={open} placement="bottom" transition>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                <Paper>
                  <div className="actionPanelBoard actionPanelBoard-orgMenu">
                    <h3>Organization</h3>
                    {renderOrgMenu()}
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </>
  );
}
