import React, { useContext, useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import AppContext from 'AppContext';
import { APP_ENDPOINT } from 'config';
import { getRole, checkPermission } from 'roles/checkPermission';
import Page from './Page';
import OrgTeam from './OrgTeam';
import apiService from 'utils/apiService';
import CreateTeam from './CreateTeam';
import OrgLinks from './OrgLinks';
import PulsePromo from 'pulse/PulsePromo';
import OrgPulsePanel from 'pulse/OrgPulsePanel';

export default function OrgView() {
  const { isAuthenticated, org, userMeta } = useContext(AppContext);
  const { teams, name, enabledModels } = org || {};
  const [teamsData, setTeamsData] = useState();
  const [teamEditorOpen, setTeamEditorOpen] = useState(false);

  const { id: orgId } = org || {};
  const { role } = getRole({ orgId, userMeta });
  const canEditOrgTeam = checkPermission(role, 'org-team:edit', true);
  const model = enabledModels && enabledModels[0];

  const onCreateTeam = () => {
    setTeamEditorOpen(true);
  };

  const renderOrgTeam = ({ data }, index) => {
    const { id } = data;
    return <OrgTeam key={`team-${id}`} teamId={id} teamData={data} index={index} teamsData={teamsData} />;
  };

  const renderSkeleton = (item, index) => {
    if (index === 1) {
      return (
        <td key={`org-td-loading-${index}`}>
          <div className="teamDashboard-timeline">
            <Skeleton width={'80%'} height={50} />
          </div>
        </td>
      );
    }

    return (
      <td key={`org-td-loading-${index}`}>
        <Skeleton width={'80%'} height={50} />
      </td>
    );
  };

  const renderPulsePanel = () => {
    if (!canEditOrgTeam) return null;

    return (
      <>
        {model ? (
          <Paper className="pulseDashboard">
            <h2 style={{ textAlign: 'center', margin: 0, padding: '24px 0 0 0' }}>{model.title}</h2>
            <OrgPulsePanel orgId={orgId} model={model} isCompact />
          </Paper>
        ) : (
          <PulsePromo />
        )}
      </>
    );
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login`);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!Array.isArray(teams)) return;

    Promise.all(teams.map((teamId) => apiService.get(`/api/team/${teamId}`).catch((e) => e))).then((data) => {
      const validData = data.filter(({ status }) => status === 200);
      setTeamsData(validData);
    });
  }, [teams]);

  return (
    <Page>
      <div className="teamsContainer">
        <div className="team-container-header">
          <h2>
            {name ? (
              name
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton width={'180px'} height={44} />
              </div>
            )}
          </h2>
          <p>View your organization’s insights over time</p>
          <hr />
          <OrgLinks current={'/dashboard'} />
        </div>

        {renderPulsePanel()}

        <div className="teamDashboard">
          <Paper>
            <div className="teamDashboard-grid teamDashboard-grid--org">
              <div className="createTeamButton--org">
                {canEditOrgTeam && (
                  <span className="text-button" onClick={onCreateTeam}>
                    + Create a new team
                  </span>
                )}
              </div>

              <h2>Teams</h2>

              <table>
                <thead>
                  <tr>
                    <th>Team</th>
                    <th>Retros</th>
                    <th>Team Admins</th>
                    <th>Facilitators</th>
                    <th>Members</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teamsData) ? (
                    teamsData.map(renderOrgTeam)
                  ) : (
                    <tr>{Array.from(Array(6).keys()).map(renderSkeleton)}</tr>
                  )}
                </tbody>
              </table>
            </div>
          </Paper>
        </div>
      </div>
      <CreateTeam open={teamEditorOpen} onClose={() => setTeamEditorOpen(false)} />
    </Page>
  );
}
