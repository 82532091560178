import React from 'react';
import { images, getImageUrl } from '../images/background';

export default function BackgroundSelector({ updateBackground }) {
  return (
    <div style={{ height: '380px', overflow: 'auto' }}>
      <div className="image-grid">
        {images.map((id) => (
          <div key={`theme-tile-${id}`} className="image-grid--col">
            <div className="image-grid--image-wrapper">
              <img src={getImageUrl(id, 's')} alt={`theme ${id}`} onClick={() => updateBackground(id)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
