import { useState, useEffect } from 'react';

import apiService from 'utils/apiService';
import { APP_ENDPOINT } from 'config';

function useSsoConnection({ orgId, teamId, boardId, isDeepLink }) {
  const [loginUrl, setLoginUrl] = useState();
  const defaultLoginUrl = `${APP_ENDPOINT}/login${isDeepLink ? `?returnTo=${window.location.href}` : ''}`;

  useEffect(() => {
    apiService
      .get(`/api/sso-connection`, {
        params: {
          orgId,
          teamId,
          boardId
        }
      })
      .then(({ data }) => {
        if (data) {
          const connection = data;

          setLoginUrl(
            `${APP_ENDPOINT}/sso-login?connection=${connection}${isDeepLink ? `&returnTo=${window.location.href}` : ''}`
          );
        } else {
          setLoginUrl(defaultLoginUrl);
        }
      })
      .catch((e) => {
        setLoginUrl(defaultLoginUrl);
        return e;
      });
  }, [orgId, teamId, boardId, defaultLoginUrl, isDeepLink]);

  return { loginUrl };
}

export default useSsoConnection;
