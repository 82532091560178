import React, { useState, useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from 'AppContext';

import apiService from 'utils/apiService';

import NotificationBar from './NotificationBar';

import { getRoleDisplayName, getDisplayName } from 'utils/misc';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '30px',
    height: '30px',
    fontSize: '0.8rem',
    marginLeft: '80%'
  },
  role: {
    minWidth: '100px',
    display: 'inline-block',
    textAlign: 'left'
  }
}));
export default function Member({ teamId, member, canEditMember, removeMember }) {
  const classes = useStyles();
  const { profile = {} } = useContext(AppContext);
  const { id, role, isOrgRole, profile: memberProfile } = member;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [roleModal, setRoleModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const [newRole, setNewRole] = useState(role);
  const [updatedRole, setUpdatedRole] = useState(role);
  const [error, setError] = useState();
  const displayRole = getRoleDisplayName({ role: updatedRole, isOrgRole });
  const isMyRole = Boolean(profile.sub === id);
  const { email, picture } = memberProfile;
  const displayName = getDisplayName(memberProfile);
  const detailedName = displayName === email ? displayName : `${displayName} (${email})`;

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    apiService
      .delete(`/api/team/${teamId}/member/${id}`)
      .then(() => {
        onRemoveModal();
        removeMember(id);
        if (isMyRole) {
          window.location.pathname = '/';
        }
      })
      .catch((e) => {
        setError(true);
        onRemoveModal();
      });
  };

  const onPermissionUpdate = (e) => {
    const value = e.target.value;
    if (value) {
      setNewRole(value);
    }
  };

  const cancelPermissionUpdate = () => {
    setNewRole(updatedRole);
    onRoleModal();
  };

  const submitPermissionUpdate = () => {
    apiService
      .post(`/api/team/${teamId}/user-meta`, {
        id,
        role: newRole
      })
      .then(() => {
        setUpdatedRole(newRole);
        onRoleModal();
      })
      .catch((e) => {
        setError(true);
      });
  };

  const onRoleModal = () => {
    setRoleModal((prev) => !prev);
    handleClose();
  };
  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <Avatar alt={displayName} src={picture} className={classes.small} />
        </TableCell>
        <TableCell>{displayName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          <span className={classes.role}>{displayRole}</span>{' '}
          {canEditMember && (
            <IconButton aria-label="settings" onClick={handleToggle} disabled={isOrgRole && !isMyRole}>
              <MoreVertIcon />
            </IconButton>
          )}
          <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                {!isOrgRole && <MenuItem onClick={onRoleModal}>Change Role</MenuItem>}
                <MenuItem onClick={onRemoveModal}>{isMyRole ? 'Leave' : 'Remove'}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popover>
        </TableCell>
      </TableRow>

      <Modal status={roleModal} maxWidth="sm" onClose={cancelPermissionUpdate} nonResponsive>
        <div className="modalContainer">
          <h2>
            Change Role{' '}
            <span role="img" aria-label="">
              👷‍♀️
            </span>
          </h2>
          <p className="modalContainer-header">{detailedName}</p>
          <div className="modalContainer-form">
            <RadioGroup row aria-label="layout" name="layout" value={newRole} onChange={onPermissionUpdate}>
              <FormControlLabel
                value="member"
                control={<Radio color="primary" />}
                label={
                  <div className="">
                    <h3>Member</h3>

                    <p>- View team retros and pulse surveys</p>
                  </div>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="facilitator"
                control={<Radio color="primary" />}
                label={
                  <div className="">
                    <h3>Facilitator</h3>

                    <p>- View team retros and pulse surveys</p>
                    <p>- Create and facilitate team retros and pulse surveys</p>
                  </div>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="admin"
                control={<Radio color="primary" />}
                label={
                  <div style={{ minWidth: '300px' }}>
                    <h3>{getRoleDisplayName({ role: 'admin', isOrgRole: false })}</h3>

                    <p>- View team retros and pulse surveys</p>
                    <p>- Create and facilitate team retros and pulse surveys</p>
                    <p>- Manage team members and settings</p>
                  </div>
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </div>
          <div className="modalContainer-actions">
            <span className="text-button" onClick={cancelPermissionUpdate}>
              Cancel
            </span>

            <Button onClick={submitPermissionUpdate}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <Modal status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive>
        <div className="modalContainer">
          <h2>
            {isMyRole ? 'Leave' : 'Remove User'}{' '}
            <span role="img" aria-label="">
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{detailedName}</p>
          {isMyRole ? (
            <p>Are you sure you want to leave this team?</p>
          ) : (
            <>
              <p>Are you sure you want to remove this person from your team?</p>
              <p>They will no longer be able to access your team’s content.</p>
            </>
          )}

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button onClick={onDelete}>Confirm</Button>
          </div>
        </div>
      </Modal>
      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </>
  );
}
