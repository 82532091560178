import { useState, useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import axios from 'axios';

const defaultFormats = [
  {
    name: 'Agile',
    content: [
      '😅 How do you feel',
      '🙂 What went well',
      "🙁 What didn't go well",
      '💪 What needs to change',
      '🌟 Shout outs'
    ]
  },
  {
    name: 'New ideas',
    content: [
      '😄 Well (Things that went well)',
      '😬 Not so well (Things that went wrong)',
      '💡 New ideas (Things that we should try)'
    ],
    theme: 'WDSN62Qdxuk'
  },
  {
    name: 'Easy As Pie',
    content: [
      '🥧 Humble Pie (Admit things that aren’t quite working)',
      '🥧 Easy As Pie (Things that worked that the team want more of)',
      '🥧 Pie in the Sky (Present an idea that may be ‘impossible’)',
      '🥰 Cutie Pie (Show your appreciation for someone or something)'
    ],
    theme: 'wMzx2nBdeng'
  },
  {
    name: 'Beer Tasting',
    content: [
      '🍺 Refreshing Brew (What aspects of our work felt smooth and satisfying?)',
      '🍺 Bitter Sips (What challenges left a bitter taste in our mouths?)',
      '🍻 Cheers to That! (Which achievements and milestones deserve a toast?)',
      '🍺 New Flavors to Try (What new ideas should we explore to enhance our work?)',
      '🍻 Brewmaster’s Award (Raise a glass to a team member who truly stood out!)'
    ],
    theme: 'EoC_IuYmtug'
  },
  {
    name: 'Super Mario',
    content: [
      '🍄 Power ups (What could make us better as a team)',
      '🐢 Bowser (What are the risks or issues that we are facing)',
      '🏰 Goals (What things we trying to achieve)',
      '⭐️ Star Power (Shout out who has been an absolute star)'
    ],
    theme: 'super-mario'
  },
  {
    name: 'Superhero',
    content: [
      '🦸 Super powers (What are our super powers?)',
      '😬 Weakness (What are our weakness?)',
      '👿 Evil Nemesis (What stands in our way?)',
      '💪 Sidekick (Who do we need?)'
    ],
    theme: 'kgz9vsP5JCU'
  },
  {
    name: 'Spring',
    content: [
      '🌷 Blooming(What successes have we achieved that make us proud?)',
      '🐛 Biting Bugs (What has caused disruption and reduced morale?)',
      '🌱 Fresh Start (What are some new ideas that we should nurture?)',
      '🐝 Busy Bees (Who has been working hard and deserves recognition?)'
    ],
    theme: 'zsAPsRjzXRI'
  },
  {
    name: 'Summer Breeze',
    content: [
      '😎 Sunny Symbols (Pick a project event, depict with emojis, guess as a group!)',
      '⛵ Smooth Sailing (What sunny wins and triumphs did we sail through?)',
      '🍦 Melting Moments (Which challenges made us feel like melting ice cream?)',
      '☀️ Bright Ideas (What refreshing, creative solutions are we thinking of?)',
      '🏄 Cool Surfers (Who effortlessly rode the wave of challenges with style?)'
    ],
    theme: 'Ia2Kjtrx8y4'
  },
  {
    name: 'Autumn',
    content: [
      '🍃 Crisp Air (What did we find refreshing that blew us away?)',
      '🌧️ Rainy Days (What was challenging that totally drenched us?)',
      '🍂 Falling Leaves (What have we had enough of that we should let go?)',
      '🌰 Harvest (Celebrate our successes and who made them happen!)'
    ],
    theme: 'rII0mAgBHpY'
  },
  {
    name: 'Chess',
    content: [
      '🏆 Checkmate (What went well and led to success?)',
      '⚔️ Stalemate (What are the risks or issues we are facing?)',
      '🏰 Endgame (What could make us better as a team?)',
      '👸 Queen’s Gambit (Who has been an absolute star?)'
    ],
    theme: '7VGzV09YnvA'
  },
  {
    name: 'KALM',
    content: [
      '👍 Keep (Things the team is doing well)',
      '🙌 Add (Things you would like to add)',
      '⛔️ Less (Things you’d rather do less)',
      '❤️ More (Things to do more of)'
    ],
    theme: 'gSQ7RFySDyM'
  },
  {
    name: 'FLAT',
    content: [
      '🔮 Future (What is our future direction?)',
      '🤔 Lessons (What have we learned?)',
      '🏆 Accomplishments (What shall we celebrate?)',
      '🌟 Thanks (Who do you want to thank?)'
    ],
    theme: 'UNSs8TXxQ1c'
  },
  {
    name: 'Halloween',
    content: [
      '🧟‍♀️ Zombies (What kept coming back and wouldn’t die?)',
      '💀 Graveyard (What isn’t working and needs to be buried?)',
      '🎃 Pumpkins (What bright ideas should we carve out and try?)',
      '🍬 Treats (What or who were our sweetest highlights?)'
    ],
    theme: 'gbc9f4779a'
  },
  {
    name: 'Diwali',
    content: [
      '🪔 Light (What shining moments brought us success?)',
      '🌌 Shadows (What problems were lurking in the shadows?)',
      '🌱 New Beginnings (What changes could bring us a brighter future?)',
      '🌟 Shining Stars (Who shone brightly and deserves our gratitude?)'
    ],
    theme: 'qhQabxhtizY'
  },
  {
    name: 'Thanksgiving',
    content: [
      '🍇 Cranberry Sauce (What was sour that could be improved?)',
      '🦃 Thanksgiving Dinner (What went well that we’re thankful for?)',
      '🥧 Pumpkin Pie (What’s been sweet and satisfying?)',
      '🙏 Giving Thanks (Who deserves thanks for their hard work?)'
    ],
    theme: 'thanksgiving'
  },
  {
    name: 'Santa’s Workshop',
    content: [
      '🎄 Perfect Gifts (What successes have we had to show under the tree?)',
      '🛷 Sleigh Repair (What improvements can help us move faster and smarter?)',
      '🦌 Rudolph’s Nose (What new ideas do we have to light our way forwards?)',
      '⛄ Jolly Elves (Celebrate and honor those who helped us along the way!)'
    ],
    theme: 'santa-workshop'
  },
  {
    name: 'Cat Lovers',
    content: [
      '🐈 Cat Reactions (Name a project event and how a cat would react to it?)',
      '😻 Purr-fection (What achievements made us purr with pride?)',
      '🙀 Hairballs (Which challenges caused us to cough up hairballs?)',
      '🐾 Paws & Reflect (What are some fresh ideas for how we could improve?)',
      '🏆 Cool Cats (Who were the coolest cats and deserve our applause?)'
    ],
    theme: 'iYpKYMYusBU'
  },
  {
    name: 'Easter Egg Hunt',
    content: [
      '🥚 Easter Egg Hunt (Using Brainstorm mode, share hidden project highlights)',
      '🐇 Hopping Forward (What progress and achievements did we make?)',
      '🌷 Blooming Ideas (What new ideas and suggestions can we explore?)',
      '🧺 Basket of Challenges (What challenges did we encounter in our work?)',
      '🍫 Sweet Successes (What team & individual wins can we celebrate?)'
    ],
    theme: 'm3e9PuH83u0'
  },
  {
    name: 'April Fool’s',
    content: [
      '🎭 Truths & Lies (Share two truths and a lie, then let others guess the lie)',
      '🎈 Funny Moments (What moments of laughter brought our team closer?)',
      '🌟 Surprise Wins (What successes, big or small, can we celebrate?)',
      '🎭 Comedy of Errors (Which challenges did we face, and how did humor help us?)',
      '🏆 Positivity Star (Who uplifted the team with their positivity?)'
    ],
    theme: 'wOHH-NUTvVc'
  },
  {
    name: 'FIFA World Cup',
    content: [
      '⚽️ Top Goals (What were our best moments that we are proud of?)',
      '🛑 Red Cards (What went horribly wrong and should be dropped?)',
      '⏰ Extra Time (What do we need more of to achieve success?)',
      '🏆 The Cup (Who led us to victory and deserves to hold the cup?)'
    ],
    theme: 'fifa-world-cup'
  },
  {
    name: 'Moon',
    content: [
      '🌑 Dark Side of the Moon (What problems are we facing or ignoring?)',
      '🌘 Moonrise (What have we done well and has been successful?)',
      '🌔 Moon Shot (Present an ambitious idea that we should try)',
      '🌕 Full Moon (Give a shout out to who has been a star!)'
    ],
    theme: '6C1LwWx40hs'
  },
  {
    name: 'Sailboat',
    content: [
      '💨 Wind (Things helping the team)',
      '⚓️ Anchor (Things slowing the team down)',
      '🧗‍♀️ Rocks (The risks or potential pitfalls)',
      '🏝 Island (The ultimate goal or vision)'
    ],
    theme: 'RN6ts8IZ4_0'
  },
  {
    name: 'Four Ls',
    content: [
      '❤️ Liked (Things you really enjoyed)',
      '🎓 Learned (Things you have learned)',
      '😢 Lacked (Things the team missed)',
      '🙏 Longed for (Something you wished for)'
    ],
    theme: 'dLFmwIOGNYo'
  },
  {
    name: 'Mad Sad Glad',
    content: ['😱 Mad', '😭 Sad', '😄 Glad', '🌟 Shout outs'],
    theme: 'LeG68PrXA6Y'
  },
  {
    name: 'Wish & Wonder',
    content: ['👍 I Like', '🙏 I Wish', '💫 I Wonder', '🌟 Shout outs'],
    theme: 'phIFdC6lA4E'
  },
  {
    name: 'DAKI',
    content: ['⛔️ Drop', '🙌 Add', '👍 Keep', '🚀 Improve'],
    theme: 'IGtutkXikuc'
  },
  {
    name: 'Lean Coffee',
    content: ['💡 Brainstorm', '☕️ To Discuss', '💬 Discussing', '✅ Discussed'],
    theme: 'qE1jxYXiwOA'
  },

  {
    name: 'Start Stop Continue',
    content: ['🚀 Start', '⛔️ Stop', '👍 Continue', '🌟 Shout outs'],
    theme: 'NnTQBkBkU9g'
  },
  {
    name: 'Starfish',
    content: ['👍 Keep Doing', '⚠️ Less Of', '✅ More Of', '🚫 Stop Doing', '🚀 Start Doing'],
    theme: '2M_sDJ_agvs'
  },
  {
    name: 'WWW',
    content: [
      '👍 Worked well (Things that worked really well)',
      '🤔 Kinda worked (Things that need tweaking)',
      '👎 Didn’t work (Things that did not work)'
    ],
    theme: 'Q1p7bh3SHj8'
  },
  {
    name: 'Christmas',
    content: [
      '🤥 Naughty list (What has been bad that should go on the Naughty list?)',
      '😄 Nice list (What has been good and should go on the Nice list?)',
      '🎁 All I want for Christmas (What do you really want to help the team & project?)',
      '🎅🏻 Santa’s greetings (Share some praise for your fellow colleagues!)'
    ],
    theme: 'B40ztSGQTZY'
  },
  {
    name: 'New Year',
    content: [
      '🎉 Highlights & Reflection (What were last year’s defining moments?)',
      '🎆 Let Go & Move On (Which challenges should we leave in the past?)',
      '🎇 Resolution Revolution (What are our goals for the upcoming year?)',
      '🌠 Sparks of Innovation (What new ideas will ignite our progress?)'
    ],
    theme: 'bg-new-year'
  }
];

function useFormats() {
  const [formats, setFormats] = useState(defaultFormats);

  useEffect(() => {
    axios
      .get('/static/js/formats.json')
      .then(({ data }) => {
        const { formats: dynamicFormats } = data;
        if (Array.isArray(dynamicFormats)) {
          setFormats(dynamicFormats);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, []);

  return { formats };
}

export default useFormats;
