import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format as dateFormat } from 'date-fns';

import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { APP_ENDPOINT } from 'config';
import AppContext from 'AppContext';
import apiService from 'utils/apiService';
import { getRole, checkPermission } from 'roles/checkPermission';
import HomeMenu from 'modules/HomeMenu';
import NotificationBar from 'modules/NotificationBar';
import useQuery from 'hooks/useQuery';
import SurveyView from './SurveyView';

import 'dashboard/NewBoard.css';
import Log from 'utils/Log';

const useStyles = makeStyles((theme) => ({
  titleTextField: {
    width: '100%'
  }
}));

const NewPulse = () => {
  const history = useHistory();
  const query = useQuery();

  const { teamId: selectedTeamId } = useParams();
  const modelId = query.get('modelId');
  const { profile, isAuthenticated, org = { teams: [] }, userMeta } = useContext(AppContext);
  const teamId = selectedTeamId || org.teams[0];
  const [data, setData] = useState();
  const { format: modelFormat } = data || {};
  const { meta, topics = [] } = modelFormat || {};

  const [name, setName] = useState('');
  const [maxBoards, setMaxBoards] = useState(false);
  const [titleEditor, setTitleEditor] = useState(false);
  const [serviceError, setServiceError] = useState(false);

  const { id: orgId } = org || {};
  const { role } = getRole({ orgId, teamId, userMeta });
  const canEditBilling = checkPermission(role, 'billing:edit');
  const classes = useStyles();

  const onCreatePulse = () => {
    apiService
      .put(`/api/team/${teamId}/pulse`, {
        modelId,
        teamId,
        name,
        timestamp: Date.now(),
        createdBy: profile && profile.sub
      })
      .then(({ data }) => {
        const id = data;
        history.push(`/pulse-survey/${id}`);
      })
      .catch((e) => e);

    Log('Pulse', 'Run');
  };

  const onChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const onClose = (e) => {
    history.replace(`/dashboard/team/${teamId}`);
  };

  useEffect(() => {
    if (teamId) {
      apiService
        .get(`/api/team/${teamId}`)
        .then(({ data }) => {
          const { active, surveys } = data;

          if (!active && surveys.length > 0) {
            //Show pulse survey upgrade model
            setMaxBoards(true);
          }
        })
        .catch((e) => {
          setServiceError(e);
        });
    }
  }, [teamId]);

  useEffect(() => {
    if (modelId && teamId) {
      apiService
        .get(`/api/team/${teamId}/pulse-model/${modelId}`)
        .then(({ data }) => {
          const { title } = data;

          setData(data);
          setName(`${title} ${dateFormat(new Date(), 'PP')}`);
        })
        .catch((e) => {
          setServiceError(e);
        });
    }
  }, [modelId, teamId]);

  useEffect(() => {
    if (isAuthenticated === false) {
      window.location.replace(`${APP_ENDPOINT}/login?returnTo=${window.location.href}`);
    }
  }, [isAuthenticated]);

  return (
    <div className="newBoard">
      <div className="boardMain boardMain-pulse">
        <div className="boardMenu">
          <HomeMenu teamId={teamId} />
          <h1 className="boardTitle boardTitle-facilitator" id="boardName" onClick={() => setTitleEditor(true)}>
            {name}
          </h1>
        </div>

        <div className="PulseEditorWrapper">
          <div className="pulsePreview">
            <SurveyView meta={meta} topics={topics} />
          </div>

          <div className="pulseEditorArea">
            <div className="retroSettings">
              <h2>Start Pulse Survey</h2>

              <div className="retroSettings-field">
                <label className="label">Title</label>
                <TextField
                  id="pulseName-name"
                  value={name}
                  inputProps={{ maxLength: 92 }}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
              </div>

              <p style={{ lineHeight: 1.5 }}>
                Once started you will be able to invite your team to participate in the survey.
              </p>
              <div style={{ textAlign: 'center' }}>
                <Button disabled={Boolean(maxBoards)} onClick={onCreatePulse}>
                  Start Pulse Survey
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {serviceError && (
        <NotificationBar
          message="Sorry something went wrong"
          type="error"
          closeCallback={() => setServiceError(false)}
        />
      )}

      <Modal status={titleEditor} maxWidth="sm" onClose={() => setTitleEditor(false)}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3>
              <span role="img" aria-label="">
                🗞
              </span>{' '}
              Title
            </h3>
            <TextField
              id="retroName-name"
              className={classes.titleTextField}
              value={name}
              inputProps={{ maxLength: 92 }}
              onChange={onChange}
            />
          </div>
        </div>
      </Modal>

      <Modal status={maxBoards} maxWidth="sm" onClose={onClose} nonResponsive>
        <div className="welcomeModal">
          <h2>
            Unlock Pulse Surveys{' '}
            <span role="img" aria-label="" style={{ fontSize: '30px' }}>
              🔐
            </span>
          </h2>
          <p>You can create 1 Pulse Survey on your Free plan.</p>
          {canEditBilling ? (
            <div>
              <p>To get unlimited Pulse Surveys please select a plan.</p>

              <Button onClick={() => history.push('/plans')}>Compare Plans</Button>
            </div>
          ) : (
            <p>Please contact your team admin to upgrade your plan.</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default NewPulse;
