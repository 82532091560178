import { useState, useEffect } from 'react';

import apiService from 'utils/apiService';

function useMood({ id, teamId }) {
  const [moodData, setMoodData] = useState({});
  const { moodScore, moodValues = {} } = moodData;

  const total = Object.values(moodValues).reduce((sum, value) => sum + value, 0);

  useEffect(() => {
    let cancel = false;

    apiService
      .get(`/api/team/${teamId}/board/${id}/polls`, {
        params: {
          type: 'mood'
        }
      })
      .then(({ data }) => {
        if (cancel) return;

        setMoodData(data);
      })
      .catch((e) => {
        return e;
      });

    return () => {
      cancel = true;
    };
  }, [id, teamId]);

  return { moodScore, moodValues, total };
}

export default useMood;
