import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from 'components/Modal/Modal';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';

import ImageSearch from 'modules/ImageSearch';

function BackgroundPicker({
  backgroundSelector,
  setBackgroundSelector,
  setBackgroundUrl,
  updateBackground,
  error,
  setError,
  isBoardSetting,
  onCancel,
  onSave
}) {
  const [backgroundTabValue, setBackgroundTabValue] = useState(0);

  const onCustomBackgroundUrlKeyUp = (e) => {
    error && setError(false);

    if (e.keyCode === 13) {
      loadCustomBackgroundUrl();
    }
  };

  const handleBackgroundTabChange = (event, newValue) => {
    setBackgroundTabValue(newValue);
  };

  const loadCustomBackgroundUrl = () => {
    const backgroundUrlInput = document.querySelector('#custom-background-url');

    const imageLoader = new Image();
    const src = backgroundUrlInput.value;
    imageLoader.src = src;

    imageLoader.onload = () => {
      setBackgroundUrl(src);
      setError(false);
    };

    imageLoader.onerror = () => {
      setError(true);
    };
  };

  const onClose = () => {
    setBackgroundSelector(false);
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  return (
    <Modal status={backgroundSelector} maxWidth="sm" onClose={onClose} nonResponsive>
      <div className="field" style={{ margin: '-16px 0 -24px' }}>
        <h3>
          <span role="img" aria-label="">
             🎨
          </span>{' '}
          Background
        </h3>
        <div className="customTabs customTabs-secondary">
          <Tabs value={backgroundTabValue} onChange={handleBackgroundTabChange} centered>
            <Tab disableRipple label="Image" />
            <Tab disableRipple label="Link" />
          </Tabs>
        </div>
        <div hidden={backgroundTabValue !== 0}>
          <ImageSearch updateBackground={updateBackground} setBackgroundUrl={setBackgroundUrl} />
        </div>
        <div hidden={backgroundTabValue !== 1}>
          <div className="customBgField">
            <TextField
              id="custom-background-url"
              placeholder="Paste an image link"
              variant="outlined"
              error={error}
              onKeyUp={onCustomBackgroundUrlKeyUp}
              helperText={error ? 'Sorry, there seems to be a problem with that image' : ''}
            />
            <p>
              <Button onClick={loadCustomBackgroundUrl}>Load Image</Button>
            </p>
          </div>
        </div>

        {isBoardSetting && onSave && onCancel && (
          <div className="plan-modal-actions">
            <span className="text-button" onClick={onCancel}>
              Cancel
            </span>
            <Button onClick={onSave}>Confirm</Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default BackgroundPicker;
