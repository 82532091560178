import React, { useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { formatDistanceToNowStrict } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as DeleteIcon } from 'images/icons/bin.svg';

import 'emoji-mart/css/emoji-mart.css';

import Modal from 'components/Modal/Modal';
import UserAvatar from './UserAvatar';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import Typing from './Typing';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    paddingLeft: '12px !important',
    '&:hover': {
      background: '#f2f2f2'
    }
  },
  avatarRoot: {
    minWidth: '30px',
    margin: '5px 4px 0 0'
  },
  actionRoot: {
    top: '22px !important'
  },
  image: {
    width: '24px',
    height: '24px',
    border: 'none'
  }
}));

const CommentView = ({ item, showProfile, showTyping, onDeleteComment, onSaveComment }) => {
  const classes = useStyles();
  const { id, comment, by, timestamp, updatedTime } = item;
  const [text, setText] = useState(comment);
  const inputEl = useRef(null);
  const modifiedTime = updatedTime || timestamp;

  const onChange = (e) => {
    const value = e.target.value;
    setText(value);
  };

  const updateComment = (e, isSave) => {
    const comment = inputEl.current && inputEl.current.value;

    if ((comment && e.keyCode === 13) || isSave) {
      //add comments
      if (comment !== item.comment) {
        onSaveComment({ id, comment });
      }
      inputEl.current.blur();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const renderTimeInfo = () => {
    const msg = formatDistanceToNowStrict(modifiedTime, { addSuffix: true });
    return msg === '0 seconds ago' ? 'Just now' : msg;
  };

  useEffect(() => {
    setText(comment);
  }, [comment]);

  return (
    <ListItem dense disableGutters alignItems="flex-start" classes={{ root: classes.root }}>
      <>
        <ListItemAvatar classes={{ root: classes.avatarRoot }}>
          {showProfile ? <UserAvatar userIds={[by]} isComment /> : <Avatar className={classes.image} />}
        </ListItemAvatar>

        <ListItemText
          primary={
            <TextareaAutosize
              ref={inputEl}
              value={text}
              onBlur={(e) => updateComment(e, true)}
              onKeyDown={updateComment}
              onChange={onChange}
              disabled={showTyping}
              style={{ width: 'calc(100% - 26px)' }}
            />
          }
          // secondary={
          //   modifiedTime ? (
          //     <Tooltip placement="top" title={format(modifiedTime, 'PP')}>
          //       <span style={{ marginRight: '8px', cursor: 'default' }}>{renderTimeInfo()}</span>
          //     </Tooltip>
          //   ) : null
          // }
        />

        {!showTyping && (
          <ListItemSecondaryAction classes={{ root: classes.actionRoot }}>
            <Tooltip title="Delete this comment" placement="top">
              <span className="close">
                <DeleteIcon onClick={() => onDeleteComment(id)} />
              </span>
            </Tooltip>
          </ListItemSecondaryAction>
        )}
      </>
    </ListItem>
  );
};

const Comments = ({ note, user, showComments, hideComments, setComments, showProfile, showTyping }) => {
  const { text } = note;
  const [displayComments, setDisplayComments] = useState(note.comments || []);
  const [showHelperText, setShowHelperText] = useState(false);
  const commentEl = useRef(null);

  const addComment = (comment) => {
    const id = nanoid();
    const newComments = [{ id, comment, by: user, timestamp: Date.now() }, ...displayComments];

    setComments(note, newComments);
    setDisplayComments(newComments);
  };

  const onSaveComment = ({ id, comment }) => {
    const updatedComments = displayComments.map((item) => {
      if (item.id === id) {
        item.comment = comment;
        item.updatedTime = Date.now();
        item.updatedBy = user;
      }

      return item;
    });

    setComments(note, updatedComments);
  };

  const onDeleteComment = (id) => {
    const newComments = displayComments.filter((item) => item.id !== id);
    setComments(note, newComments);
  };

  const checkHelper = (e) => {
    const comment = commentEl.current && commentEl.current.value;
    if (comment) {
      setShowHelperText(true);
    } else {
      setShowHelperText(false);
    }
  };

  const submitComment = (e) => {
    const comment = commentEl.current && commentEl.current.value;
    if (comment && e.keyCode === 13 && !e.shiftKey) {
      //add comments
      addComment(comment);

      commentEl.current.value = '';
      setShowHelperText(false);
      e.preventDefault();
      e.target.blur();
    }
  };

  const onDrag = (e) => {
    // Fix stop it progagation and adding react-draggable-transparent-section to body causing text not selectable in the modal
    e.stopPropagation();
  };

  useEffect(() => {
    if (note.comments) {
      setDisplayComments(note.comments);
    }
  }, [note.comments]);

  return (
    <Modal status={showComments} maxWidth="sm" onClose={hideComments}>
      <div onMouseDown={onDrag}>
        <div
          style={{
            boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.09)',
            border: '2px solid transparent',
            borderRadius: '10px',
            margin: '24px 48px',
            position: 'relative'
          }}
        >
          <p
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              padding: '18px',
              margin: 0,
              fontSize: '24px',
              fontWeight: 400,
              height: '100%',
              textAlign: 'center',
              cursor: 'default'
            }}
          >
            {showTyping ? <Typing /> : text}
          </p>
          <div style={{ position: 'absolute', bottom: '-20px', left: '-20px' }}>
            {showProfile && <UserAvatar userIds={[...(note.groupedBy || [note.createdBy])]} isComment isLarge />}
          </div>
        </div>

        <List>
          {displayComments
            .sort((a, b) => {
              return a.timestamp - b.timestamp;
            })
            .map((item) => (
              <CommentView
                key={item.id}
                item={item}
                showProfile={showProfile}
                showTyping={showTyping}
                onDeleteComment={onDeleteComment}
                onSaveComment={onSaveComment}
              />
            ))}
        </List>
        <div style={{ display: 'flex', alignItems: 'center', margin: '12px auto 0' }}>
          <TextField
            inputRef={commentEl}
            variant="outlined"
            multiline
            minRows={1}
            onKeyUp={checkHelper}
            onKeyDown={submitComment}
            placeholder="Share your thoughts"
            helperText={showHelperText ? 'Press enter to submit' : ''}
            FormHelperTextProps={{
              className: 'new-comment-field-helper'
            }}
            InputProps={{ style: { lineHeight: '1.4' } }}
            style={{ width: '100%' }}
            disabled={showTyping}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Comments;
