import React from 'react';

import Button from 'components/CustomButtons/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Modal from 'components/Modal/Modal';

export default function OnVotingConfirm({ showVotingOptions, onVote, onClose }) {
  return (
    <Modal status={showVotingOptions} onClose={onClose} maxWidth="sm" nonResponsive>
      <div className="welcomeModal">
        <h2>
          <span role="img" aria-label="">
            🗳
          </span>{' '}
          Start voting session
        </h2>

        <p>You have a previous voting session.</p>
        <div className="votingModal-options">
          <Tooltip
            placement="top"
            title={
              <span style={{ width: '185px', display: 'block' }}>
                Clear all previous votes and start a new voting session.
              </span>
            }
          >
            <Button
              simple
              onClick={() => {
                onVote({ resetVotes: true });
              }}
            >
              Start new voting session
            </Button>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <span style={{ width: '270px', display: 'block' }}>
                Keep existing votes and resuming voting. People can modify their previous votes.
              </span>
            }
          >
            <Button
              onClick={() => {
                onVote();
              }}
            >
              Resume voting session
            </Button>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
}
