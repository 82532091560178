import React, { useState, useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Switch from '@material-ui/core/Switch';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import NotificationBar from 'modules/NotificationBar';

import AppContext from 'AppContext';
import apiService from 'utils/apiService';
import Log from 'utils/Log';

const PulseCard = ({ orgId, data, onDelete, onEdit, hasData, callback }) => {
  const { updateOrg } = useContext(AppContext);
  const { id, title, enabled } = data;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [error, setError] = useState();

  const onRemoveModal = () => {
    setRemoveModal((prev) => !prev);
    handleClose();
  };

  const onToggleModal = () => {
    setToggleModal((prev) => !prev);
    handleClose();
  };

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleModel = () => {
    apiService
      .post(`/api/org/${orgId}/pulse-model/${id}/toggle`, { enabled: !enabled })
      .then(({ data }) => {
        callback && callback();
        onToggleModal();
        updateOrg({ orgId, enabledModels: data });
      })
      .catch((e) => {
        setError(e);
      });

    Log('Pulse', 'Toggle');
  };

  return (
    <>
      <div className="pulseSection">
        <h2>
          {title} <Switch checked={enabled} onChange={onToggleModal} color="primary" name={`${id}-switch-control`} />
        </h2>

        <div className="pulseSection-actions">
          <IconButton aria-label="settings" onClick={handleToggle}>
            <MoreVertIcon />
          </IconButton>

          <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="pulse-menu-actions-list">
                <MenuItem onClick={onToggleModal}>{enabled ? 'Disable' : 'Enable'}</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onEdit(id);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem className="action-delete" onClick={onRemoveModal}>
                  Delete
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popover>
        </div>
      </div>

      <Modal key={`${id}-removeModal`} status={removeModal} maxWidth="sm" onClose={onRemoveModal} nonResponsive>
        <div className="modalContainer">
          <h2>
            Delete Pulse Survey{' '}
            <span role="img" aria-label="" style={{ fontSize: '26px' }}>
              ❌
            </span>
          </h2>

          <p className="modalContainer-header">{title}</p>
          <p>Are you sure you want to delete this Pulse Survey?</p>

          {hasData ? (
            <>
              <p style={{ color: '#f26c7c' }}>⚠️ This Pulse Survey has been used by your teams.</p>
              <p style={{ color: '#f26c7c' }}> All associated data will be deleted and cannot be undone.</p>
            </>
          ) : (
            <p>
              All data associated with this will be deleted and <br /> cannot be undone.
            </p>
          )}

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRemoveModal}>
              Cancel
            </span>

            <Button color="danger" onClick={() => onDelete(id)}>
              Delete Pulse Survey
            </Button>
          </div>
        </div>
      </Modal>

      <Modal key={`${id}-toggleModal`} status={toggleModal} maxWidth="sm" onClose={onToggleModal} nonResponsive>
        <div className="modalContainer">
          <h2>{`${enabled ? 'Disable' : 'Enable'} Pulse Survey`}</h2>

          <p className="modalContainer-header">{title}</p>

          {enabled ? (
            <>
              <p>Disabling will mean teams won’t be able to run the survey.</p>
              <p style={{ marginBottom: '2rem' }}>Historical survey results will still be available.</p>
            </>
          ) : (
            <>
              <p>Enabling this survey will make it visible to all teams,</p>
              <p style={{ marginBottom: '2rem' }}>and will disable any active survey.</p>
            </>
          )}

          <p>Would you like to proceed?</p>

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onToggleModal}>
              Cancel
            </span>

            <Button onClick={toggleModel}>{`${enabled ? 'Disable' : 'Enable'} Pulse Survey`}</Button>
          </div>
        </div>
      </Modal>

      {error && <NotificationBar message="Sorry something went wrong" type="error" closeCallback={() => setError()} />}
    </>
  );
};

export default PulseCard;
